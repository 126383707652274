/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jsquizhome.css";
import { Container, Pagination, Row } from "react-bootstrap";
import Myaccountheaderjsd from "../../Components/MyAccountHeaderJSD/myaccountheaderjsd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const Jsquizhome = () => {
  const location = useLocation();
  const [quizHeader, setQuizHeader] = useState([]);
  const [rewardPointTotal, setrewardPointTotal] = useState([]);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const [page, setPage] = useState(1);
  const [pagesize, Setpagesize] = useState(10);
  const [totalPages, SettotalPages] = useState(0);
  const pageno = location.state?.data;
  const navigate = useNavigate();
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  
  useEffect(() => {
    setPage(pageno !== undefined ? pageno : 1);
  }, [pageno]);

  useEffect(() => {
    if(window.location.pathname == "/j_myaccount/j_quizhome"){
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => window.history.forward();
    }
  }, [window.location.pathname == "/j_myaccount/j_quizhome"])
  
  useEffect(() => {
    getQuizHeader();
    getRewardPointTotal();
  }, [page]);


  function generateString(length) {
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

const data = generateString(200);
  
  /******************** API CALL START HERE **************************/

  const getQuizHeader = () => {
    const body = {
      isactive: "Y",
      userid: login?.userid,
      pageable: {
        pageno: page - 1,
        pagesize: pagesize,
      },
    };
    axiosInstance
      .post(`${Constants.GetQuizHeader}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          Setpagesize(response.data.pageable.pagesize);
          SettotalPages(response.data.pageable.totalPages);
          setQuizHeader(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const getRewardPointTotal = () => {
    const body = {
      isactive: "Y",
      userid: login?.userid,
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetRewardPointCollectionTotal}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setrewardPointTotal(response.data.data);
        } else {
          swal(`${response.data.massage}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  /******************** API CALL END HERE **************************/
  // HANDLE NEXT PAGE
  const handleNext = () => {
    const newLocation = { ...location, state: null };
    navigate(newLocation);
    if (page === totalPages) return page;
    setPage(page + 1);
  };

  // /  HANDLE PREVIOUS /
  const handlePrevious = () => {
    const newLocation = { ...location, state: null };
    navigate(newLocation);
    if (page === 1) return page;
    setPage(page - 1);
  };

  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/j_myaccount"
                              className="linkNone textLightGray w600"
                            >
                              My Account
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              My Quiz
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12 mt-4">
                    <Myaccountheaderjsd />
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6">
                    <div className="col-12">
                      <div className="currentPointContainer mt-4 w-100 bgWhite p-3">
                        <h4 className=" mb-0 font18">
                          You have earned
                          {rewardPointTotal.length === 0 ? (
                            <span className="textPrime2 w600 me-1 ms-1">0</span>
                          ) : (
                            rewardPointTotal?.map((item, index) => (
                              <span
                                className="textPrime2 w600 me-1 ms-1"
                                key={index}
                              >
                                {item?.erningpoint}
                              </span>
                            ))
                          )}
                          reward points
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <Row>
                      {quizHeader?.map((item, key) => (
                        <div className="col-md-6" key={key}>
                          <div className="singleQuizContainer w-100 p-3 bgWhite mt-4">
                            <div>
                              <h4 className="font20">{item?.name}</h4>
                              <p className="mb-0 font14 textLightGray w600">
                                {item?.quizdetail_count} Question
                              </p>
                            </div>
                            <Link
                             to={`/j_myaccount/j_quiz/${window.btoa(item?.quizid+" "+data)}`}
                              state={{
                                data: page,
                                quizId:window.btoa(item?.quizid+" "+data)
                              }}
                            >
                              <button
                                className={
                                  item?.quizdetail_count === 0
                                    ? "px-3 py-2 bgPrimeLight textWhite quizbutton borderNone"
                                    : item?.data_count === 1
                                    ? "px-3 py-2 bgPrimeLight textWhite quizbutton borderNone"
                                    : "px-3 py-2 bgPrime2 quizbutton textWhite borderNone"
                                }
                                disabled={
                                  item?.quizdetail_count === 0 ||
                                  item.data_count === 1
                                }
                              >
                                {item.data_count === 1 ? "Complete" : "Play"}
                              </button>
                            </Link>
                          </div>
                        </div>
                      ))}
                      {quizHeader?.length > 0 && (
                        <Row>
                          <div className="mt-4 ms-auto d-flex justify-content-end paginationBox">
                            <Pagination>
                              {totalPages < 10 ? (
                                <>
                                  <Pagination.Prev
                                    onClick={handlePrevious}
                                    disabled={page === 1}
                                  />
                                  {Array(totalPages)
                                    .fill(null)
                                    .map((_, index) => (
                                      <Pagination.Item
                                        key={index}
                                        active={
                                          page === index + 1 ? true : false
                                        }
                                        onClick={() => {
                                          setPage(index + 1);
                                          const newLocation = {
                                            ...location,
                                            state: null,
                                          };
                                          navigate(newLocation);
                                        }}
                                      >
                                        {index + 1}
                                      </Pagination.Item>
                                    ))}
                                  <Pagination.Next
                                    onClick={handleNext}
                                    disabled={page === totalPages}
                                  />
                                </>
                              ) : (
                                <>
                                  <Pagination.First
                                    onClick={() => setPage(1)}
                                  />
                                  <Pagination.Prev
                                    onClick={handlePrevious}
                                    disabled={page === 1}
                                  />
                                  <Pagination.Item
                                    onClick={() => setPage(1)}
                                    active={page === 1}
                                  >
                                    {1}
                                  </Pagination.Item>
                                  {page > 2 && <Pagination.Ellipsis />}
                                  {Array.from(
                                    { length: totalPages - 2 },
                                    (_, index) => {
                                      const pageNumber = index + 2;
                                      if (
                                        pageNumber > 1 &&
                                        pageNumber < totalPages &&
                                        Math.abs(pageNumber - page) > 2
                                      ) {
                                        return null;
                                      }
                                      return (
                                        <Pagination.Item
                                          key={index}
                                          active={page === pageNumber}
                                          onClick={() => {
                                            setPage(pageNumber);
                                            const newLocation = {
                                              ...location,
                                              state: null,
                                            };
                                            navigate(newLocation);
                                          }}
                                        >
                                          {pageNumber}
                                        </Pagination.Item>
                                      );
                                    }
                                  )}

                                  {page < totalPages - 3 && (
                                    <Pagination.Ellipsis />
                                  )}

                                  <Pagination.Item
                                    onClick={() => setPage(totalPages)}
                                    active={page === totalPages}
                                  >
                                    {totalPages}
                                  </Pagination.Item>
                                  <Pagination.Next
                                    onClick={handleNext}
                                    disabled={page === totalPages}
                                  />
                                  <Pagination.Last
                                    onClick={() => setPage(totalPages)}
                                  />
                                </>
                              )}
                            </Pagination>
                          </div>
                        </Row>
                      )}
                    </Row>
                  </div>
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Jsquizhome;
