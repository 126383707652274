/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const Legal = () => {
  const [termsData, setTermsData] = useState([]);
  const [refundData, setRefundData] = useState([]);
  const [privacyData, setPrivacyData] = useState([]);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    termsConditions();
    privacyPolicy();
    refundPolicy();
  }, []);

  /******************** API CALL START HERE **************************/
  const termsConditions = () => {
    const body = {
      pagecode: "terms_conditions",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetStaticPages}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setTermsData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })

  };

  const privacyPolicy = () => {
    const body = {
      pagecode: "privacy_policy",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetStaticPages}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPrivacyData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });

  };

  const refundPolicy = () => {
    const body = {
      pagecode: "refund_policy",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetStaticPages}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRefundData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
 
  };
  /******************** API CALL END HERE **************************/

  return (
    <Container>
      <Row className="mt100 legalContainer">
        <div className="col-lg-4">
          <h2 className="textGray w700">{termsData?.[0]?.pagetitle}</h2>
        </div>
        <div className="col-lg-8">
          <p
            className="textJustify textLightGray w500"
            dangerouslySetInnerHTML={{
              __html: termsData?.[0]?.content?.slice(0, 675),
            }}
          ></p>
          <Link
            to="/terms"
            className="float-end bgNone borderNone outlineNone textPrime underLine w500"
          >
            Read More
          </Link>
        </div>
        <hr className="my-4 textLightGray" />
        <div className="col-lg-4">
          <h2 className="textGray w700">{privacyData?.[0]?.pagetitle}</h2>
        </div>
        <div className="col-lg-8">
          <p
            className="textJustify textLightGray w500"
            dangerouslySetInnerHTML={{
              __html: privacyData?.[0]?.content?.slice(0, 675),
            }}
          ></p>
          <Link
            className="float-end bgNone borderNone outlineNone textPrime underLine w500"
            to="/privacy"
          >
            Read More
          </Link>
        </div>
        <hr className="my-4 textLightGray" />
        <div className="col-lg-4">
          <h2 className="textGray w700">{refundData?.[0]?.pagetitle}</h2>
        </div>
        <div className="col-lg-8">
          <p
            className="textJustify textLightGray w500"
            dangerouslySetInnerHTML={{
              __html: refundData?.[0]?.content?.slice(0, 675),
            }}
          ></p>
          <Link
            className="float-end bgNone borderNone outlineNone textPrime underLine w500"
            to="/refund"
          >
            Read More
          </Link>
        </div>
        <hr className="my-4 textLightGray" />
      </Row>
    </Container>
  );
};
export default Legal;
