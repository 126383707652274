import React from "react";
import "./input.css";
import "../../Common/common.css";

const Input = (props) => {
  return (
    <input
      type="text"
      className="offWhiteInput bgOffWhite borderNone outlineNone ps-3"
      placeholder={props.placeHolder}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
    />
  );
};
export default Input;
