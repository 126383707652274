/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../Common/common.css";
import JobAndCity from "../../Components/JobAndCity/jobAndCity";
import axiosInstance from "../../Api/commonUrl";
import swal from "sweetalert";
import * as Constants from "../../Common/Global/constants";
import { useSelector } from "react-redux";
import Slider from "react-slick";

const AllJobsCity = () => {
  const [cityData, setCityData] = useState([]);
  const [geteducationData, setEducationData] = useState([]);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getEducation();
    getCityMaster();
  }, []);

  /******************** API CALL START HERE **************************/
  const getEducation = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetEducation}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setEducationData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getCityMaster = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetCityMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setCityData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  /******************** API CALL END HERE **************************/

  /******************** SLIDERS START HERE **************************/
  const educationSlider = {
    rows: geteducationData.length >= 12 ? 3 : (geteducationData.length >= 5 ? 2 : 1),
    speed: 500,
    slidesToShow: 4,
    className: "center",
    // dots: true,
    slidesToScroll: 1,
    infinite: geteducationData.length >= 12,
    // autoplay: true,
    // autoplaySpeed: 3000,
    arrows: geteducationData.length >= 8 ? "true" :"false",
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows:true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          rows:1,
          infinite: true,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cityDataSlider = {
    rows:cityData.length >= 12 ? 3 : (cityData.length >= 5 ? 2 : 1),
    speed: 500,
    slidesToShow: 4,
    className: "center",
    // dots: true,
    slidesToScroll: 1,
    infinite: cityData.length >= 12,
    // autoplay: true,
    // autoplaySpeed: 3000,
    arrows: cityData.length >= 8 ? "true" :"false",
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows:true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          rows:1,
          infinite: true,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /******************** SLIDERS END HERE **************************/

  return (
    <Container>
      <div className="mb-5"> 
      <Row>
        <h1 className="text-center textGray w700 mb50 mt-4">
          Job <span className="textPrime">Categories</span>
        </h1>

        <Container className="jobcategory">
          <Slider {...educationSlider}>
            {geteducationData?.map((item, key) => (
              <div className="col-md-4 col-lg-3 col-6 me-4" key={key}>
                <div className="alljobsflex" key={key}>
                  <Link className="linkNone" to="/categorydetails">
                    <JobAndCity
                      name={item?.name}
                      candidate={item?.data_count}
                    />
                  </Link>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
    
      </Row>
      <Row>
        <h1 className="text-center textGray w700 my50 ">
          All <span className="textPrime">Cities</span>
        </h1>
        <Container>
          <Slider {...cityDataSlider}>
        {cityData?.map((item, key) => (
              <div className="col-md-4 col-lg-3 me-4" key={key}>
                  <div className="alljobsflex" key={key}>
                <JobAndCity name={item.city} candidate={item?.data_count} />
              </div>
              </div>
            ))
        }
        </Slider>
        </Container>
     
      </Row>
      </div>
    </Container>
  );
};
export default AllJobsCity;
