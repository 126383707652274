/****************** API ERROR CODES *******************************/
export const CODE_ACCESS_TOKEN_UNAUTHORIZED = 401;
export const CODE_ACCESS_TOKEN_EXPIRED = "20";
export const CODE_SUCCESS = "200";
/****************** API ERROR CODES END HERE **********************/

export const WebSite = "api/v1/website/";
export const Master = "api/v1/master/";
export const User = "api/v1/user/";
export const Common = "api/v1/common/";
export const JobSeeker = "api/v1/jobseeker/";
export const JobSeekerProfile = "api/v1/jobseeker/profile/";
export const JobSeekerUser = "api/v1/jobseeker/user/";
export const Recruitment = "api/v1/recruitment/";
export const RecruitmentProfile = "api/v1/recruitment/profile/";
export const RecruitmentUser = "api/v1/recruitment/user/";

/******************** API URL START HERE **************************/

/********************HOME PAGE API START HERE **************************/
export const SaveHomePageHeader = WebSite + "get_website_homepageheader";
export const GetHomePageInfo = WebSite + "get_website_homepageinfo";
export const GetMediaCoverage = WebSite + "get_website_mediacoverage";
export const GetRecruitmentPartners = WebSite + "get_website_recruitmentpartners";
export const GetSocialicon = WebSite + "get_website_socialicon";
export const GetVideoDetail = WebSite + "get_website_videodetail";
export const GetScanOpenApp = WebSite + "scan_open_app"
export const SendLinkInMobile = WebSite + "send_linkinmobile"
/******************** HOME PAGE API END HERE **************************/

/******************** MASTER API START HERE **************************/
export const GetSkills = Master + "getskills";
export const SaveSkills = Master + "save_skills";

export const GetCompanySize = Master + "getCompanySize";
export const GetEducation = Master + "geteducation";
export const GetRequiredExperience = Master + "getRequiredExperience";
export const GetFunctionalArea = Master + "get_functional_area";
export const GetJobPreference = Master + "get_job_preference";
export const GetLocation = Master + "get_location";
export const SaveLocation = Master + "save_location";
export const GetExpactedIndustry = Master + "get_expacted_industry";
export const GetJobType = Master + "get_jobtype";
export const GetDynamicImage = Master + "get_dynamicimage";
export const GetCountyMaster = Master + "get_countrymaster";
export const GetStateMaster = Master + "get_statemaster";
export const GetCityMaster = Master + "get_citymaster";
export const SaveContactus = Master + "save_contactus";
export const GetPeopleSay = Master + "get_peoplesay";
export const GetHomePageMaster= Master + "gethomepagemaster";
/******************** MASTER API END HERE **************************/

/******************** USER API START HERE **************************/
export const SaveSignIn = User + "signin";
export const RecruitmentSignin = RecruitmentUser + "recruitment_signin";
export const SaveRecruitmentPersonalInfo =RecruitmentProfile+"save_recruitment_personalInfo";
export const SaveRecruitmentCompanyInfo=RecruitmentProfile+"save_recruitment_companyinfo"
/******************** USER API END HERE **************************/

/******************** CONFIGURATIONS API START HERE **************************/
export const GetCompanyGeneralDetail = Master + "get_company_general_detail";
export const GetFAQ = Master + "getfaq";
export const GetStaticPages = Master + "getstaticpages";
export const GetEmailTemplete = Common + "email/getemailtemplete";

/******************** CONFIGURATIONS API END HERE **************************/

/******************** JOBSEEKER API START HERE *************************/
export const GetQuizHeader = JobSeeker + "get_quizheader";
export const GetQuizDetail = JobSeeker + "get_quizdetail";
export const SaveQuizDetail = JobSeeker + "save_quiz_participate"
export const GetJobSeekerPlan = JobSeeker + "get_jobseekerplan";
export const GetRewardPointMaster = JobSeeker + "get_rewardpointmaster";
export const GetRewardPointCollectionTotal = JobSeeker + "get_rewardpoint_collectiontotal";
export const GetJobSeekerPlanPaymentByFeachers = JobSeeker + "getjobseeker_plan_payment_by_feachers";
export const GetJobSeekerSocialIcon = JobSeeker + "get_jobseeker_socialicon";
export const SaveRedeempointCollection = JobSeeker + "save_redeempointcollection";
export const GetJobSeekerPersonalInfo = JobSeekerProfile + "get_jobseeker_personalInfo";
export const GetJobSeekerJobPerferences = JobSeekerProfile + "get_jobseeker_jobpreferences";
export const GetJobSeekerEducation = JobSeekerProfile + "get_jobseeker_education";
export const GetJobSeekerData = JobSeekerProfile + "get_jobseeker_data";
export const GetJobSeekerFind = JobSeekerProfile + "get_jobseeker_find";
export const GetJobSeekerFullProfile = JobSeekerProfile + "get_jobseeker_fullprofile";
export const GetJobSeekerAdvertisement = JobSeeker + "get_jobseeker_advertisement";
export const SaveJobSeekerSignIn = JobSeekerUser + "jobseeker_signin";
export const SaveJobSeekerValidotp = JobSeekerUser + "jobseeker_validotp";
export const SaveJobSeekerFavouritePostJob = JobSeekerProfile + "save_jobseeker_favourite_postjob";
export const SaveJobSeekerFavourite = JobSeekerProfile + "save_jobfavourite"
export const GetJobSeekerPlanPayment = JobSeeker + "getjobseeker_plan_payment";
export const GetJobSeekerWorkExperience = JobSeekerProfile + "get_jobseeker_workexperience";
/******************** JOBSEEKER API END HERE *************************/

/******************** RECRUITMENT API START HERE *************************/
export const GetRecruitmentPlan = Recruitment + "get_recruitmentplan";
export const GetRecruitmentPlanPymentByFeachers = Recruitment + "getrecruitment_plan_payment_by_feachers";
export const GetRecruitmentSocialIcon = Recruitment + "get_recruitment_socialicon";
export const GetRecruitmentPersonalInfo = RecruitmentProfile + "get_recruitment_personalInfo";
export const GetRecruitmentFullProfile = RecruitmentProfile + "get_recruitment_fullprofile";
export const GetRecruitmentCompanyInfo = RecruitmentProfile + "get_recruitment_companyinfo";
export const GetRecruitmentPostJob = RecruitmentProfile + "get_recruitment_postjob";
export const GetRecruitmentAdvertisement = Recruitment + "get_recruitment_advertisement";
export const GetRecruitmentData = RecruitmentProfile + "get_recruitment_data";
export const GetRecruitmentFind = RecruitmentProfile + "get_recruitment_find";
export const GetRecruitmentStatus = RecruitmentProfile + "get_recruitment_data";
export const SaveRecruitmentSignIn = RecruitmentUser + "recruitment_signin";
export const SaveRecruitmentValidotp = RecruitmentUser + "recruitment_validotp";
export const GetRecruitmentPlanPayment = Recruitment + "getrecruitment_plan_payment";
/******************** RECRUITMENT API END HERE *************************/
