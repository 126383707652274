/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Myaccountheaderjsd from "../../Components/MyAccountHeaderJSD/myaccountheaderjsd";
import swal from "sweetalert";
import "./jspersonalinfo.css";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { useSelector } from "react-redux";
import Jobcard from "../../Components/JobCard/jobcard";
import Slider from "react-slick";
import { FaEye } from "react-icons/fa";

const JsPersonalinfo = () => {
  const [profileData, setProfileData] = useState([]);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const workexperience = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.workexperience)
    : [];
  const bio = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.bio)
    : [];
  const education = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.education)
    : [];
  const certification = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.certification)
    : [];
  const jobpreferences = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.jobpreferences)
    : [];
  const skillList = jobpreferences?.map((item) => {
    return item.skillList;
  });
  const skillName = []?.concat(...skillList)?.map((item) => item?.skillname);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 568);

  useEffect(() => {
    GetJobSeekerFullProfileData();
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 568);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  /****************** API CALL START HERE ************************/

  const GetJobSeekerFullProfileData = () => {
    const body = {
      userid: login?.userid,
      pageable: {},
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerFullProfile}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setProfileData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  /****************** API CALL END HERE ************************/
  /******************** SLIDERS START HERE **************************/

  const workExperienceSlider = {
    rows: 1,
    infinite: workexperience.length >= 2,
    arrows: workexperience.length >= 2,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const educationSlider = {
    rows: 1,
    infinite: education.length >= 2,
    arrows: education.length >= 2,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const certificationSlider = {
    rows: 1,
    infinite: certification.length >= 2,
    arrows: certification.length >= 2,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          arrows:true,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /******************** SLIDERS END HERE **************************/

  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12 mt-4">
                    <Myaccountheaderjsd />
                  </div>
                </Row>
                <div className="p-4">
                  <Row>
                    <p className="mt-4 mb-0 w600 font16">Bio</p>
                    {bio?.length > 0 &&
                      bio?.map((item, index) => (
                        <p
                          className="mt-1 text-secondary textJustify font14"
                          key={index}
                        >
                          {item?.bio}
                        </p>
                      ))}
                  </Row>
                  <hr className="opacity50" />
                  <Row>
                    <p className="mt-4 mb-0 w600 font16">Work Experience</p>
                    <div className="mt-3 text-secondary textJustify">
                    {workexperience?.length > 0 ? <Slider {...workExperienceSlider}>
                          {workexperience?.map((item, key) => {
                            const startYear = item?.startdate
                              ? item?.startdate.split("-")[2]
                              : "";
                            const endYear = item?.enddate
                              ? item?.enddate.split("-")[2]
                              : "";
                            return (
                              <div
                                className={`col-md-6 mt-3 me-5 ms-2`}
                                key={key}
                              >
                                <div className="personlinfocard">
                                  <Jobcard
                                      experienceCol="col-sm-12"
                                      experienceNone="none"
                                    key={key}
                                    Title="Company Name"
                                    jobtitle={item?.companyname}
                                    yearsAndsalary={
                                      item?.present === "Y"
                                        ? `${startYear} - Present`
                                        : `${startYear} - ${endYear} `
                                    }
                                    divnone={`${isMobile ? "none" : ""}`}
                                    eductionTitle="Designation"
                                    educationAndInstitute={item?.designation}
                                    nameTitle="Role & Responsibilities"
                                    Name={item?.roleandresponsibilities}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </Slider>:"Fresher"}
                    </div>
                  </Row>
                  <hr className="opacity50" />
                  <Row>
                    <p className="mt-4 mb-0 w600 font16">Education</p>
                    <div className="mt-1 text-secondary textJustify ">
                      <Slider {...educationSlider}>
                        {education?.length > 0 &&
                          education?.map((item, key) => {
                            const startYear = item?.startdate
                              ? item?.startdate.split("-")[2]
                              : "";
                            const endYear = item?.enddate
                              ? item?.enddate.split("-")[2]
                              : "";

                            return (
                              <div
                                className="col-md-6 mt-3 me-5 ms-2"
                                key={key}
                              >
                                <div className="personlinfocard" key={key}>
                                  <Jobcard
                                    key={key}
                                    Title="Board/University Name"
                                    experienceCol="col-sm-12"
                                    experienceNone="none"
                                    jobtitle={item?.universityname}
                                    yearsAndsalary={`${startYear} - ${endYear} `}
                                    eductionTitle="Eduction Level"
                                    educationAndInstitute={item?.educationlevel}
                                    nameTitle="Institute Name"
                                    Name={item?.institutename}
                                    divnone={`${isMobile ? "none" : ""}`}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  </Row>
                  <hr className="opacity50" />
                  <Row>
                    <p className="mt-4 mb-0 w600 font16">Certificates</p>
                    <div className="mt-1 text-secondary textJustify">
                      <Slider {...certificationSlider}>
                        {certification?.length > 0 &&
                          certification?.map((item, key) => {
                            const dateofcompletion = item?.dateofcompletion
                              ? item?.dateofcompletion.split("-")[2]
                              : "";
                            return (
                              <div
                                className="col-md-6 mt-3 me-5 ms-2"
                                key={key}
                              >
                                <div className="personlinfocardCertificate">
                                  <Jobcard
                                    key={key}
                                    Title="Course Name"
                                    jobtitle={item?.coursename}
                                    experienceCol="col-sm-12"
                                    experienceNone="none"
                                    eductionTitle="Date of Completion"
                                    educationAndInstitute={dateofcompletion}
                                    nameTitle="Certificate"
                                    divnone={`${isMobile ? "none" : ""}`}
                                    icon={
                                      <Link
                                        to={item?.attachcertification}
                                        target="_self"
                                        style={{
                                          fontSize: "14px",
                                          textDecoration: "none",
                                        }}
                                        rel="noreferrer"
                                      >
                                        <FaEye className="me-2 font18 w600" style={{color:"#404040"}}/>
                                      </Link>
                                    }
                                    Name={`${item?.filename}`}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  </Row>
                  <hr className="opacity50" />
                  <Row>
                    <p className="mt-4 mb-0 w600 font16">Job Preferences</p>
                    <p className="mt-1 text-secondary textJustify">
                      <Row>
                        {jobpreferences?.length > 0 &&
                          jobpreferences?.map((item, key) => {
                            return (
                              <div className="col-md-6 mt-3" key={key}>
                                <Jobcard
                                  key={key}
                                  experienceCol="col-sm-12"
                                  experienceNone="none"
                                  Title="Preference"
                                  jobtitle={item?.jobpreference}
                                  startSalary={
                                    item?.expactedsalaryname === null
                                      ? ""
                                      : login?.currency
                                  }
                                  yearsAndsalary={item?.expactedsalaryname}
                                  endSalary={
                                    item?.expactedsalaryname === null
                                      ? ""
                                      : "LPA"
                                  }
                                  eductionTitle="Indurstries Name"
                                  educationAndInstitute={
                                    item?.expactedindustryname
                                  }
                                  divnone={`${isMobile ? "none" : ""}`}
                                  nameTitle="Prefered City"
                                  Name={item?.city}
                                />
                              </div>
                            );
                          })}
                      </Row>
                    </p>
                  </Row>
                  <hr className="opacity50" />
                  <Row>
                    <div className="col-md-6">
                      <p className="mt-4 w600 font16">Skills</p>
                      {skillName?.map((item, key) => (
                        <button
                          className="jobdetails_skillBTN px-3 py-1 bgWhite me-2 mt-2 font14"
                          key={key}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </Row>
                </div>
              </Container>
            </Container>
    </>
  );
};

export default JsPersonalinfo;
