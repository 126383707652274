/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jslogin.css";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jsLogin } from "../../redux/action/action";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";

const Jslogin = () => {
  var [mobileno, setMobileNo] = useState("");
  var [emailid, setEmailid] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [homePageMaster, setHomepageMaster] = useState([]);
  const [sidelogo, setSidelogo] = useState([]);
  const [error, setError] = useState("");
  const Token = useSelector((state) => state?.token);
  const number = new RegExp("^[0-9]{10}$");
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  useEffect(() => {
    getHomePageMaster();
    getNavLogo()
  },[])

  const handleChangeMobile = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setMobileNo(e.target.value);
    }
  };
  
  const handleChangeEmailId = (e) => {
    setEmailid(e.target.value);
  };

  /** Validations Functions  **/
  const validate = () => {
    let errors = [];
    let emailiduser = emailid?.trim()
    if(emailid){
     if (!emailiduser?.match(pattern)) {
      errors.emailid = `Please Enter valid email [eg. xxx@xxx.xxx]`;
    }
  }
  if (mobileno) {
    if (!mobileno.match(number)) {
      errors.mobileno = "Enter valid Mobile number";
    } 
  }

     if (!emailid) emailid = "";
     if (!mobileno) mobileno = "";
     if (emailid.length <= 0 && mobileno.length <= 0) {
       errors = "Email Or Mobile number  is required ";
     }
     return setError(errors);
  };

  /******************** API CALL START HERE **************************/
  const loginUser = (e) => {
    e.preventDefault();
    validate();
    let emailiduser = emailid?.trim()
    if ( (!emailiduser.match(pattern)=="" && !emailid=="") ||
    (!mobileno.match(number)=="" && !mobileno=="")) {
      axiosInstance
        .post(
          `${Constants.SaveJobSeekerSignIn}`,
          mobileno === "" ? { emailid: emailid?.trim() ,deviceid:"WEB",} : { mobileno: mobileno,deviceid:"WEB" },      
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            dispatch(jsLogin(response.data.data));
            navigate("/js_otp");
          }
        })
        .catch((error) => {
          return swal(`${error.response.data.massage}`, "", "error");
        });
    }
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Jobseeker_Login_Promo",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getNavLogo = () => {
    const body = {
      groupname: "Home_Nav_Logo", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setSidelogo(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALL END HERE **************************/
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col-12 ps-5">
          <Link className="linkNone" to="/">
          <div className="d-flex sidebarLogoWithText">
            {sidelogo?.map((item,index)=>(
            <div
              className="ms-4 mt-2"
              key={index}
            >
              <img
               src={item?.imageurl}
                className="footerIcon2"
                alt=""
              />
            </div>
            ))}
          </div>
            </Link>
          </div>
          <div className="col-md-6 text-center mt-5">
            <h2 className="w700 mb-5">
              Get <span className="textPrime2">Jobs</span> Instantly !!!
            </h2>
            {homePageMaster?.map((item, index) => (
            <img
            key={index}
            src={item?.imageurl}
              className="jobsideImage "
              alt=""
            />
            ))}
          </div>
          <div className="col-md-6">
            <div className="logiFormContainer">
              <h4 className="w700 text-center pt-5">
                Jobseeker <span className="textPrime2">Login</span>
              </h4>
              <p className="text-center mt-4 w600 textPrime2">
                Continue with mobile number
              </p>
              <input
                type="text"
                placeholder="Enter mobile number"
                className="loginContainerInput"
                onChange={handleChangeMobile}
                name="mobileno"
                maxLength="10"
                value={mobileno}
              />
              {!mobileno.match(number) && error.mobileno ? (
                <div className="errorJSLogin text-center">{error.mobileno}</div>
              ) : null}
              <p className="text-center mt-4 w600 textPrime2">
                Or, Continue with Email ID
              </p>
              <input
                type="text"
                placeholder="Enter email id"
                className="loginContainerInput"
                onChange={handleChangeEmailId}
                value={emailid}
                name="emailid"
              />
                {!emailid.match(pattern) && error.emailid ? (
                <div className="errorJSLogin text-center">{error.emailid}</div>
              ) : null}

           {(!emailid && !mobileno) && error ? (
                <div className="errorJSLogin text-center">{error}</div>
              ) : null}
              <div className="text-center">
                <Link>
                  <button
                    className="bgPrime2 textWhite px-3 py-2 w600 borderNone btnLogin mt-5"
                    onClick={loginUser}
                  >
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Jslogin;
