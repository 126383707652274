/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import { Container, Pagination, Row } from "react-bootstrap";
import JobCardJSD from "../../Components/JobCardJSD/jobcardjsd";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const JsResume = () => {
  const [recruitmentData, setRecruitmentData] = useState([]);
  // const [recruitmentList, setrecruitmentList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pagesize, Setpagesize] = useState(6);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const location = useLocation();
  const pageno = location.state?.data;

  useEffect(() => {
    setPage(pageno !== undefined ? pageno : 1)
  }, [pageno])

  useEffect(() => {
    getRecruitmentData();
  }, [page]);

  

  /******************** API CALL START HERE **************************/
  const getRecruitmentData = () => {
    const body = {
      loginuserid: login?.userid,
      isapply: "Y",
      pageable: {
        pageno: page - 1 ,
        pagesize: pagesize,
      },
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRecruitmentData(response.data.data);
          Setpagesize(response.data.pageable.pagesize);
          setPageCount(response.data.pageable.totalPages);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });

  };
  /******************** API CALL END HERE **************************/
  
  /*** PAGINATION CODE FROM BOOTSTRAP ***/
  // HANDLE NEXT PAGE
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };

  // HANDLE PREVIOUS PAGE
  const handlePrevious = () => {
    if (page === 1) return page;
    setPage(page - 1);
  };
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/j_myaccount"
                              className="linkNone textLightGray w600"
                            >
                              My Account
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              Apply Job
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  {recruitmentData?.length > 0 &&
                    recruitmentData?.map((item, key) => (
                      <div className="col-md-6 mt-4" key={key}>
                        <Link
                          to={`/j_myaccount/j_applydetails/${item?.postjobid}`}
                          state={{ data: page }}
                          className="linkNone"
                          key={key}
                        >
                          <JobCardJSD
                            key={key}
                            jobtitle={item?.jobtitle}
                            education={item?.education}
                            location={item?.location}
                            companyName={item?.companyname}
                            Icons={
                              item?.favourite === "Y"
                                ? "font20 text-danger activeLink mx-3"
                                : "font20 textLightGray mx-3"
                            }
                            firstName={item?.firstname}
                            lastName={`${item?.lastname} \u2022 ${item?.myposition}`}
                            experience={item?.requiredexperience}
                          />
                        </Link>
                      </div>
                    ))}
                </Row>
                <Row>
                {recruitmentData?.length > 0 && (
                  <div className="mt-4 ms-auto d-flex justify-content-end paginationBox">
                    <Pagination>
                        {pageCount < 10 ? (
                          <>
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            {Array(pageCount)
                              .fill(null)
                              .map((_, index) => (
                                <Pagination.Item
                                  key={index}
                                  active={page === index + 1 ? true : false}
                                  onClick={() => {
                                    setPage(index + 1);
                                  }}
                                >
                                  {index + 1}
                                </Pagination.Item>
                              ))}
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === pageCount}
                            />
                          </>
                        ) : (
                          <>
                            <Pagination.First onClick={() => setPage(1)} />
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            <Pagination.Item
                              onClick={() => setPage(1)}
                              active={page === 1}
                            >
                              {1}
                            </Pagination.Item>
                            {page > 2 && <Pagination.Ellipsis />}
                            {Array.from(
                              { length: pageCount - 2 },
                              (_, index) => {
                                const pageNumber = index + 2;
                                if (
                                  pageNumber > 1 &&
                                  pageNumber < pageCount &&
                                  Math.abs(pageNumber - page) > 2
                                ) {
                                  return null;
                                }
                                return (
                                  <Pagination.Item
                                    key={index}
                                    active={page === pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Pagination.Item>
                                );
                              }
                            )}

                            {page < pageCount - 3 && <Pagination.Ellipsis />}

                            <Pagination.Item
                              onClick={() => setPage(pageCount)}
                              active={page === pageCount}
                            >
                              {pageCount}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === pageCount}
                            />
                            <Pagination.Last
                              onClick={() => setPage(pageCount)}
                            />
                          </>
                        )}
                      </Pagination>
                  </div>
                )}
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default JsResume;
