import { Navigate } from "react-router-dom";
import AppStore from "../../redux/store/store";

const RTPublicRoute = ({ children }) => {
  const Token = AppStore.store.getState().user?.jwttoken;
  if (Token) {
    return <Navigate to="/rt_home" />;
  }
  return children;
};
export default RTPublicRoute;
