export const SET_TOKEN = "SET_TOKEN";
export const SET_USER = "SET_USER";
export const SET_RTLOGIN = "SET_RTLOGIN";
export const SET_RTOTP = "SET_RTOTP";
export const SET_JSLOGIN = "SET_JSLOGIN";
export const SET_JSOTP = "SET_JSOTP";

// USER SIDE LOGIN PAGE 
export const SAVE_RTPROFILEINFO = "SAVE_RTPROFILEINFO";
export const SAVE_RTLOGIN = "SAVE_RTLOGIN";

// Quize
export const SAVE_QUIZEDATA = "SAVE_QUIZEDATA"