import React, { useState } from "react";
import "../../Common/common.css";
import "./jobcardjsd.css";
import { AiTwotoneHeart } from "react-icons/ai";

const JobCardJSD = (props) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };
  return (
    <>
      <div
        className={`jobcardMainContainer w-100 bgWhite p-3 ${
          isActive ? "activeData" : ""
        }`}
        onClick={handleClick}
      >
        <div className="row">
          <div>
            <p className="text12 textPrime2 w600 font16 mb-0">Job Title</p>
            <div className="d-flex justify-content-between">
              <b className="font14 textGray">{props?.jobtitle}</b>
              <AiTwotoneHeart
                className={props?.Icons}
                onClick={props?.hendleChangeLike}
              />
            </div>
          </div>
        </div>
        <div className="row  mt-3">
          <div>
            <p className="text12 textPrime2 w600 mb-0 font16">Company Name</p>
            <div className="d-flex justify-content-between">
              <p className="w600 textLightGray font14">{props?.companyName}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <p className="text12 textPrime2 w600 mb-0 font16">Education</p>
            <p className="w600 textLightGray font14">{props.education}</p>
          </div>
          <div className="col-sm-6">
            <p className="text12 textPrime2 w600 mb-0 font16">Location</p>
            <p className="w600 textLightGray font14">
              {props.location}
            </p>
          </div>
          <div className="col-sm-6">
            <p className="text12 textPrime2 w600 mb-0 font16">Designation</p>
            <p className="w600 textLightGray font14">
              {props.firstName} <span className="font14">{props.lastName}</span>
            </p>
          </div>
          <div className="col-sm-6">
            <p className="text12 textPrime2 w600 mb-0 font16">Experience</p>
            <p className="w600 textLightGray font14">{props?.experience}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCardJSD;
