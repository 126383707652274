import React from "react";
import AppStore from "../../redux/store/store";
import { Navigate } from "react-router-dom";

const JSPrivateRoute = ({ children }) => {
  const Token = AppStore.store.getState().login;
  return Token ? children : <Navigate to="/" />;
};

export default JSPrivateRoute;
