/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import { Container, Pagination, Row } from "react-bootstrap";
import MyaccountheaderRt from "../../Components/MyAccountHeaderRT/myaccountheaderrt";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";

const Rtmypayment = () => {
  const [paymentData, setPaymentData] = useState([]);
  const user = useSelector((state) => state?.user);
  const Token = useSelector((state) => state?.token);
  const [page, setPage] = useState(1);
  const [pagesize, Setpagesize] = useState(6);
  const [totalPages, SettotalPages] = useState(0);
  const navigate = useNavigate();
  // const [leftDay, setLeftDay] = useState("");

  useEffect(() => {
    getRecruitmentPlanPayment();
  }, [page]);

  // useEffect(() => {
  //   if (leftDay >= 0) {
  //     Setpagesize(5);
  //   } else {
  //     Setpagesize(6);
  //   }
  // }, [leftDay, pagesize]);

  /******************** API CALL START HERE **************************/
  const getRecruitmentPlanPayment = () => {
    const body = {
      userid: user?.userid,
      isactive: "Y",
      ispayment: "Y",
      pageable: {
        pageno: page - 1,
        pagesize: pagesize,
      },
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentPlanPayment}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPaymentData(response.data.data);
          Setpagesize(response.data.pageable.pagesize);
          SettotalPages(response.data.pageable.totalPages);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  /******************** API CALL END HERE **************************/

  /* PAGINATION CODE FROM BOOTSTRAP */
  // HANDLE NEXT PAGE
  const handleNext = () => {
    if (page === totalPages) return page;
    setPage(page + 1);
  };

  // /  HANDLE PREVIOUS /
  const handlePrevious = () => {
    if (page === 1) return page;
    setPage(page - 1);
  };
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/rt_myaccount"
                              className="linkNone textLightGray w600"
                            >
                              My Account
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              My Payment
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12 mt-4">
                    <MyaccountheaderRt name="My Plan" />
                  </div>
                </Row>
                <Row>
                  {paymentData?.map((item, key) => {
                    const convertDateFormat = (dateString) => {
                      const [day, month, year] = dateString.split("-");
                      return `${year}-${month.padStart(2, "0")}-${day.padStart(
                        2,
                        "0"
                      )}`;
                    };
                    const endDateString = convertDateFormat(item?.validon);
                    const currentDate = new Date();
                    const endDate = new Date(endDateString);
                    const daysLeft = Math.max(
                      Math.ceil(
                        (endDate - currentDate) / (1000 * 60 * 60 * 24)
                      ),
                      -1
                    );
                    return item?.isvalid === "Y" && daysLeft >= 0 ? (
                      <>
                        <div className="col-md-6 mt-4" key={key}>
                          <div className="singlePaymentSlip2 w-100 p-3">
                            <h4 className="w600 mb-0 font20">
                              Current Plan
                              <span className="textGray w600 font16 ms-1">
                                {`(${daysLeft} Days Left)`}
                              </span>
                            </h4>
                            <div className="d-flex justify-content-between w-100 mt-2">
                              <div className="text-wrap w-75">
                                <h4 className="textPrime2 w600 font18">
                                  {item?.planname}
                                  <span className="textGray w600 font14 d-contents ms-1">
                                    ({item?.day} Day's)
                                  </span>
                                </h4>
                              </div>
                              <div className="text-wrap w-35 text-end">
                                <h4 className="textPrime2 w600 font18 ">
                                 {item?.currency} {item?.amount_pay}.0
                                </h4>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                              <h5 className="textLightGray w600 mb-0 font18">
                                Activated On
                              </h5>
                              <h4 className="textGray w600 mb-0 font18">
                                {item?.payment_date}
                              </h4>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                              <h5 className="textLightGray w600 mb-0 font18">
                                Expires On
                              </h5>
                              <h4 className="textGray w600 mb-0 font18">
                                {item?.validon}
                              </h4>
                            </div>
                          </div>
                        </div>
                        {daysLeft >= 0 && key === 0 ? null : (
                          <div className="col-md-6 mt-4" key={key}>
                            <div className="singlePaymentSlip w-100 h-100 bgWhite p-3">
                              <div className="d-flex justify-content-between w-100 mt-2">
                                <div className="text-wrap1 w-75">
                                  <h4 className="textPrime2 w600 font18 ">
                                    {item?.planname}
                                    <span className="textGray w600 font14 d-contents ms-1">
                                      ({item?.day} Day's)
                                    </span>
                                  </h4>
                                </div>
                                <div className="text-wrap w-35 text-end">
                                  <h4 className="textPrime2 w600 font18 ">
                                    {item.currency}{item?.amount_pay}.0
                                  </h4>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                                <h5 className="textLightGray w600 mb-0 font18">
                                  Activated On
                                </h5>
                                <h4 className="textGray w600 mb-0 font18">
                                  {item?.payment_date}
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                                <h5 className="textLightGray w600 mb-0 font18">
                                  Expires On
                                </h5>
                                <h4 className="textGray w600 mb-0 font18">
                                  {item?.validon}
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="col-md-6 mt-4" key={key}>
                        <div className="singlePaymentSlip w-100 h-100 bgWhite p-3">
                          <div className="d-flex justify-content-between w-100 mt-2">
                            <div className="text-wrap1 w-75">
                              <h4 className="textPrime2 w600 font18 ">
                                {item?.planname}
                                <span className="textGray w600 font14 d-contents ms-1">
                                  ({item?.day} Day's)
                                </span>
                              </h4>
                            </div>
                            <div className="text-wrap w-35 text-end">
                              <h4 className="textPrime2 w600 font18 ">
                             {item.currency}{item?.amount_pay}.0
                              </h4>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <h5 className="textLightGray w600 mb-0 font18">
                              Activated On
                            </h5>
                            <h4 className="textGray w600 mb-0 font18">
                              {item?.payment_date}
                            </h4>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <h5 className="textLightGray w600 mb-0 font18">
                              Expires On
                            </h5>
                            <h4 className="textGray w600 mb-0 font18">
                              {item?.validon}
                            </h4>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Row>
                <Row>
                  {paymentData?.length > 0 && (
                    <div className="mt-4 ms-auto d-flex justify-content-end paginationBox">
                      <Pagination>
                        {totalPages < 10 ? (
                          <>
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            {Array(totalPages)
                              .fill(null)
                              .map((_, index) => (
                                <Pagination.Item
                                  key={index}
                                  active={page === index + 1 ? true : false}
                                  onClick={() => {
                                    setPage(index + 1);
                                  }}
                                >
                                  {index + 1}
                                </Pagination.Item>
                              ))}
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === totalPages}
                            />
                          </>
                        ) : (
                          <>
                            <Pagination.First onClick={() => setPage(1)} />
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            <Pagination.Item
                              onClick={() => setPage(1)}
                              active={page === 1}
                            >
                              {1}
                            </Pagination.Item>
                            {page > 2 && <Pagination.Ellipsis />}
                            {Array.from(
                              { length: totalPages - 2 },
                              (_, index) => {
                                const pageNumber = index + 2;
                                if (
                                  pageNumber > 1 &&
                                  pageNumber < totalPages &&
                                  Math.abs(pageNumber - page) > 2
                                ) {
                                  return null;
                                }
                                return (
                                  <Pagination.Item
                                    key={index}
                                    active={page === pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Pagination.Item>
                                );
                              }
                            )}

                            {page < totalPages - 3 && <Pagination.Ellipsis />}

                            <Pagination.Item
                              onClick={() => setPage(totalPages)}
                              active={page === totalPages}
                            >
                              {totalPages}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === totalPages}
                            />
                            <Pagination.Last
                              onClick={() => setPage(totalPages)}
                            />
                          </>
                        )}
                      </Pagination>
                    </div>
                  )}
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Rtmypayment;
