/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-array-constructor */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "../RTCandidateDetails/rtcandidatedetails.css";
import { Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const RtAllJobsDetails = () => {
  const [postJobData, setPostJobData] = useState([]);
  const { id } = useParams();
  const Token = useSelector((state) => state?.token);
  const user = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const location = useLocation();
  const pageno = location.state?.data;
  const selectedValue = location.state.selectedvalue;
  const status = location.state.status;
  const searchField = location.state.searchField;
  const [defaultImg, setDefaultImg] = useState([]);

  useEffect(() => {
    GetRecruitmentPostJob();
    getHomePageMaster();
  }, []);

  /******************** API CALL START HERE **************************/
  const GetRecruitmentPostJob = () => {
    if (id != "null") {
      const body = {
        userid: user?.userid,
        postjobid: id,
        isactive: "Y",
      };
      axiosInstance
        .post(`${Constants.GetRecruitmentData}`, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            setPostJobData(response.data.data);
          } else {
            swal(`${response.data.error}`, "", "error");
          }
        })
        .catch((error) => {
          if (
            error.response.data?.status ===
            Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          }
        });
    }
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Profile_image",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setDefaultImg(response.data.data?.[0]?.imageurl);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  /******************** API CALL END HERE **************************/
  return (
    <>
      <Container fluid className="mainPagesContainer">
        <Container>
          <Row>
            <div className="col-12 mt-2">
              {postJobData?.map((item, key) => (
                <>
                  <div className="d-flex align-items-baseline">
                    <Link
                      to={"/rt_myaccount/rt_alljobs"}
                      state={{
                        data: pageno,
                        selectedvalue: selectedValue,
                        searchField: searchField,
                        status: status,
                      }}
                    >
                      <h4 className="mb-0 textPrime2 w600">
                        <MdOutlineKeyboardBackspace
                          style={{ width: "50px", height: "35px" }}
                        />
                      </h4>
                    </Link>
                    <h4 className="mb-0 textPrime2 w600 font18">
                      {item?.jobtitle}
                    </h4>
                  </div>
                  <div className="jobDetailsHeaderContainer bgWhite p-4 d-flex justify-content-between mt-3 w-100">
                    <div className="d-flex">
                      <img
                        src={
                          item?.companyprofile
                            ? item?.companyprofile
                            : defaultImg
                        }
                        className="userImage rounded-circle"
                        alt=""
                      />
                      <div key={key} className="ms-3 mt-2">
                        <h4 className="mb-0 w600 font18">
                          {item?.companyname}
                        </h4>
                        <p className="mb-0 w500 text-secondary font16">
                          {item?.industries} &bull;{item?.companysize}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="col-12 mt-4">
              {postJobData?.map((item, key) => (
                <div
                  className="myAccountHeaderMainContainer bg-white px-4 align-center"
                  key={key}
                >
                  <div className={item.personal ? "d-flex mt-4" : "d-flex"}>
                    <img
                      src={ item?.profileimage === "" || null || "null"
                      ? defaultImg
                      :  item?.profileimage}
                      className="userImage rounded-circle"
                      alt=""
                    />
                    <div className="ms-3">
                      <h3 className="mb-0 w600 textPrime2 font18">
                        {item?.firstname} {item?.lastname}
                      </h3>

                      <div>
                        <p className="textLightGray w500 font16" key={key}>
                          {item?.myposition}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Row>
          {postJobData?.map((item, key) => {
            const skillsArray = item?.skillname
              .split(",")
              .map((skill) => skill.trim());
            return (
              <div key={key} className="p-4">
                <Row>
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap mt-3">
                      <div className="me-5">
                        <p className="mb-0 textGray w600 font16">Experiences</p>
                        <p className="mb-0 textPrime2 w600 font14">
                          {item?.requiredexperience}
                        </p>
                      </div>
                      <div className="me-5">
                        <p className="mb-0 textGray w600 font16">Education</p>
                        <p className="mb-0 textPrime2 w600 font14">
                          {item?.education}
                        </p>
                      </div>
                      <div className="me-5">
                        <p className="mb-0 textGray w600 font16">Location</p>
                        <p className="mb-0 textPrime2 w600 font14">
                          {item?.location}
                        </p>
                      </div>
                      <div className="me-5">
                        <p className="mb-0 textGray w600 font16">
                          Offered Salary
                        </p>
                        <p className="mb-0 textPrime2 w600 font14">
                          {user.currency} {item?.salary} LPA
                        </p>
                      </div>
                    </div>
                  </div>
                </Row>
                <hr className="opacity50 mt-4" />
                <Row>
                  <p className="mt-4 mb-0 w600 font16">Description</p>
                  <p className="mt-1 text-secondary textJustify font14">
                    {item?.description}
                  </p>
                </Row>
                <hr className="opacity50 mt-3" />
                <Row>
                  <div className="col-md-12" key={key}>
                    <p className="mt-2 w600 font16">Skills</p>
                    {skillsArray?.map((item, key) => (
                      <button
                        className="jobdetails_skillBTN px-3 py-1 bgWhite me-2 mt-2 font14"
                        key={key}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                </Row>
              </div>
            );
          })}
        </Container>
      </Container>
    </>
  );
};

export default RtAllJobsDetails;
