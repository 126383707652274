/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jsjoblist.css";
import { Container, Row } from "react-bootstrap";
import JobCardJSD from "../../Components/JobCardJSD/jobcardjsd";
import { FaFilter } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import Pagination from "react-bootstrap/Pagination";
import { useSelector } from "react-redux";

const Jsjoblist = () => {
  const [recruitmentData, setRecruitmentData] = useState([]);
  const location = useLocation();
  const jobtypename1 = location.state?.jobtypename;
  const expactedindustryname1 = location.state?.expactedindustryname;
  const requiredexperience1 = location.state?.requiredexperience;
  const companycity1 = location.state?.companycity;
  const [companycity, setCompanycity] = useState(companycity1 === undefined ? "" : companycity1);
  const [jobtypename, setJobtypename] = useState(jobtypename1 === undefined ? "" : jobtypename1);
  const [jobtype, setJobtype] = useState([]);
  const [expactedindustryname, setExpactedIndustryName] = useState(expactedindustryname1 === undefined ? "" : expactedindustryname1);
  const [expactedIndustry, setExpactedIndustry] = useState([]);
  const [requiredexperience, setRequiredexperience] = useState(requiredexperience1 === undefined ? "" : requiredexperience1);
  const [requirEdexperienceData, setRequirEdexperienceData] = useState([]);
  const [jobpreferenceData, setJobPreferenceData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesize, Setpagesize] = useState(6);
  const [totalPages, SettotalPages] = useState();
  const pageno = location.state?.data;
  const selectedValue = location.state?.selectedvalue;
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const [jobpreference, setJobPreference] = useState(
    selectedValue === undefined ? "" : selectedValue
  );
  
  useEffect(() => {
    setPage(pageno !== undefined ? pageno : 1);
    if (selectedValue === undefined) {
      setJobPreference(jobpreference);
    } else {
      setJobPreference(selectedValue);
    }
    setCompanycity(companycity1 === undefined ? "" : companycity1)
    setJobtypename(jobtypename1 === undefined ? "" : jobtypename1)
    setExpactedIndustryName(expactedindustryname1 === undefined ? "" : expactedindustryname1)
    setRequiredexperience(requiredexperience1 === undefined ? "" : requiredexperience1)
  }, [pageno, selectedValue,expactedindustryname1,requiredexperience1,jobtypename1,companycity1]);

  
  useEffect(() => {
    getJobPreference();
    getJobTypeData();
    getRecruitmentData();
    getExpactedIndustry();
    getRequiredExperience();
  }, [jobpreference, page,selectedValue,pageno]);


  /****************** API CALL START HERE ************************/
  const getRecruitmentData = () => {
    const body = {
      loginuserid: login?.userid,
      jobpreference: jobpreference || "",
      jobtypename:jobtypename,
      location:companycity,
      expactedindustryname:expactedindustryname,
      requiredexperience:requiredexperience,
      status: "OPEN",
      pageable: {
        pageno: page - 1,
        pagesize: pagesize,
      },
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          Setpagesize(response.data.pageable.pagesize)
          SettotalPages(response.data.pageable.totalPages)
          setRecruitmentData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  // const saveJobSeekerFavouritePostJob = async (
  //   loginuserid,
  //   postjobid,
  //   isfavourite
  // ) => {
  //   const body = {
  //     userid: loginuserid,
  //     postjobid: postjobid,
  //     isfavourite: isfavourite === "Y" ? "N" : "Y",
  //   };
  //   return axiosInstance
  //     .post(`${Constants.SaveJobSeekerFavourite}`, body, {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: `Bearer ${Token}`,
  //       },
  //     })
  //     .then((response) => {
  //       if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
  //         localStorage.clear();
  //         navigate("/");
  //       } else if (response.data.status === Constants.CODE_SUCCESS) {
  //         getRecruitmentData();
  //         return response.data.data;
  //       } else {
  //       }
  //     });
  // };

  const getJobPreference = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetJobPreference}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobPreferenceData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  const getJobTypeData = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetJobType}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobtype(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  const getExpactedIndustry = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetExpactedIndustry}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setExpactedIndustry(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  const getRequiredExperience = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRequiredExperience}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRequirEdexperienceData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  /****************** API CALL END HERE ************************/

  /* PAGINATION CODE FROM BOOTSTRAP */
  // HANDLE NEXT PAGE
  const handleNext = () => {
    if (page === totalPages) return page;
    setPage(page + 1);
  };

  // /  HANDLE PREVIOUS /
  const handlePrevious = () => {
    if (page === 1) return page;
    setPage(page - 1);
  };

  // /  FILTER DATA /
  const filteredData = (e) => {
    e.preventDefault();
    setPage(1)
    const body = {
      loginuserid: login?.userid,
      jobpreference: jobpreference || "",
      jobtypename:jobtypename,
      location:companycity,
      expactedindustryname:expactedindustryname,
      requiredexperience:requiredexperience,
      status: "OPEN",
      pageable: {
        pageno: page-1,
        pagesize: pagesize,
      },
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRecruitmentData(response.data.data);
          Setpagesize(response.data.pageable.pagesize)
          SettotalPages(response.data.pageable.totalPages)
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  return (
    <>

            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 w600 textGray font18 ms-2">
                      Job Preference
                    </p>
                    <select
                      className="p-2 filterOutline outlineNone ms-4 custom-select"
                      name="jobpreference"

                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setJobPreference(selectedValue);
                        const newLocation = { ...location, state: null };
                        setPage(1);
                        setCompanycity("");
                        setExpactedIndustryName("");
                        setRequiredexperience("");
                        setJobtypename("");
                        navigate(newLocation);
                      }}
                      
                      value={jobpreference}
                    >
                      <option value={""}>All</option>
                      {jobpreferenceData?.length >= 0 &&
                        jobpreferenceData?.map((item, index) => (
                          <option value={item?.name} key={index}>
                            {item?.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <form onSubmit={filteredData}>
                    <div className="rowScrollbar">
                      <div className="singleFilterItemBox  bgWhite ms-3 pb-2 p-2">
                        <a className="linkNone w600 textPrime2 font14 py-2">
                          Location
                        </a>
                        <input
                          type="text"
                          name="companycity"
                          value={companycity}
                          onChange={(e) => setCompanycity(e.target.value)}
                          placeholder="eg. Pune"
                          className="filterInput"
                        />
                      </div>
                      <div className="singleFilterItemBox bgWhite ms-3 pb-2 p-2">
                        <a className="linkNone w600 textPrime2 font14 py-2">
                          Job Type
                        </a>
                        <select
                          className="p-2 filterOutline outlineNone"
                          name="jobtypename"
                          onChange={(e) => setJobtypename(e.target.value)}
                          value={jobtypename}
                        >
                          <option
                            selected={true}
                            disabled="disabled"
                            value={""}
                          >
                            Select
                          </option>
                          {jobtype.length >= 0 &&
                            jobtype?.map((item, index) => (
                              <option value={item?.name} key={index}>
                                {item?.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="singleFilterItemBox bgWhite ms-3 pb-2 p-2">
                        <a className="linkNone w600 textPrime2 font14 py-2">
                          Industry
                        </a>
                        <select
                          className=" p-2 filterOutline outlineNone "
                          name="expactedindustryname"
                          onChange={(e) =>
                            setExpactedIndustryName(e.target.value)
                          }
                          value={expactedindustryname}
                        >
                          <option
                            selected={true}
                            disabled="disabled"
                            value={""}
                          >
                            Select
                          </option>
                          {expactedIndustry.length >= 0 &&
                            expactedIndustry?.map((item, index) => (
                              <option value={item?.name} key={index}>
                                {item?.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="singleFilterItemBox bgWhite ms-3 pb-2 p-2">
                        <a className="linkNone w600 textPrime2 font14 mpy2">
                          Required Experience
                        </a>
                        <select
                          className="p-2 filterOutline outlineNone"
                          name="requiredexperience"
                          onChange={(e) =>
                            setRequiredexperience(e.target.value)
                          }
                          value={requiredexperience}
                        >
                          <option
                            selected={true}
                            disabled="disabled"
                            value={""}
                          >
                            Select
                          </option>
                          {requirEdexperienceData.length >= 0 &&
                            requirEdexperienceData?.map((item, index) => (
                              <option value={item?.name} key={index}>
                                {item?.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="singleFilterItemBox2 rightmargin">
                        <button
                          className="mt-3 borderNone bgPrime2 textWhite p-2 px-3 filterButton "
                          type="submit"
                        >
                          <FaFilter />
                        </button>
                      </div>
                    </div>
                  </form>
                </Row>
                <hr className="opacity50" />
                <Row>
                  {recruitmentData?.length > 0? (
                    recruitmentData?.map((item, key) => (
                      <div className="col-md-6 mt-4 w-card" key={key}>
                        <Link
                          to={`/j_joblist/j_jobdetails/${item?.postjobid}`}
                          state={{
                            data: page,
                            selectedvalue: jobpreference,
                            postjobid: item?.postjobid,
                            jobtypename:jobtypename,
                            companycity:companycity,
                            expactedindustryname:expactedindustryname,
                            requiredexperience:requiredexperience,
                            
                          }}
                          className="linkNone"
                          key={key}
                        >
                          <JobCardJSD
                            key={key}
                            jobtitle={item?.jobtitle}
                            education={item?.education}
                            location={item?.location}
                            companyName={item?.companyname}
                            Icons={
                              item?.favourite === "Y"
                                ? "font20 text-danger activeLink mx-3"
                                : "font20 textLightGray mx-3"
                            }
                            firstName={item?.firstname}
                            lastName={`${item?.lastname} \u2022 ${item?.myposition}`}
                            experience={item?.requiredexperience}
                          />
                        </Link>
                      </div>
                    ))
                    ) : (
                      <div className="col-md-12 text-center mt-5">
                        <p>No results found</p>
                      </div>
                    )}
                </Row>
                {recruitmentData?.length === 0 ? (
                  ""
                ) : (
                  <Row>
                    <div className="mt-4 ms-auto d-flex justify-content-end paginationBox">
                    <Pagination>
                        {totalPages < 10 ? (
                          <>
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            {Array(totalPages)
                              .fill(null)
                              .map((_, index) => (
                                <Pagination.Item
                                  key={index}
                                  active={page === index + 1 ? true : false}
                                  onClick={() => {
                                    setPage(index + 1);
                                  }
                                }
                                >
                                  {index + 1}
                                </Pagination.Item>
                              ))}
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === totalPages}
                            />
                          </>
                        ) : (
                          <>
                            <Pagination.First onClick={() => setPage(1)} />
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            <Pagination.Item
                              onClick={() => setPage(1)}
                              active={page === 1}
                            >
                              {1}
                            </Pagination.Item>
                            {page > 2 && <Pagination.Ellipsis />}
                            {Array.from(
                              { length: totalPages - 2 },
                              (_, index) => {
                                const pageNumber = index + 2;
                                if (
                                  pageNumber > 1 &&
                                  pageNumber < totalPages &&
                                  Math.abs(pageNumber - page) > 2
                                ) {
                                  return null;
                                }
                                return (
                                  <Pagination.Item
                                    key={index}
                                    active={page === pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Pagination.Item>
                                );
                              }
                            )}

                            {page < totalPages - 3 && <Pagination.Ellipsis />}

                            <Pagination.Item
                              onClick={() => setPage(totalPages)}
                              active={page === totalPages}
                            >
                              {totalPages}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === totalPages}
                            />
                            <Pagination.Last
                              onClick={() => setPage(totalPages)}
                            />
                          </>
                        )}
                      </Pagination>
                    
                    </div>
                  </Row>
                )}
              </Container>
            </Container>
    </>
  );
};
export default Jsjoblist;
