import React from "react";
import "./listing.css";
import "../../Common/common.css";
import { Container, Row } from "react-bootstrap";
import Filter from "../../Components/Filter/filter";
import InfoCard from "../../Components/InfoCard/infoCard";
import Pagination from "../../Components/Pagination/pagination";
import { Link } from "react-router-dom";

const Listing = () => {
  return (
    <Container fluid="lg">
      <Row>
        <h6 className="w600 textGray text-center m-0 resultText mb50">
          Showing Results for
          <span className="textPrime"> Sales Executive</span> Across India
        </h6>
      </Row>
      <Row>
        <div className="col-lg-9 col-md-8">
          <Link to="/info" className="linkNone">
            <InfoCard
              image="/assets/images/dp.png"
              name="Ross Galler"
              experience="7 Years"
              education="Graduate"
              salary="6-9 LPA"
              companyName="Hoch Tech"
              position="Sale Executive"
              workingFrom="2021"
              workTill="Present"
              educationName="Computer Engineering"
              edStart="2017"
              edEnd="2020"
              city="Bangalore"
              state="Karnataka"
            />
          </Link>
          <InfoCard
            image="/assets/images/dp.png"
            name="Ross Galler"
            experience="7 Years"
            education="Graduate"
            salary="6-9 LPA"
            companyName="Hoch Tech"
            position="Sale Executive"
            workingFrom="2021"
            workTill="Present"
            educationName="Computer Engineering"
            edStart="2017"
            edEnd="2020"
            city="Bangalore"
            state="Karnataka"
          />
          <InfoCard
            image="/assets/images/dp.png"
            name="Ross Galler"
            experience="7 Years"
            education="Graduate"
            salary="6-9 LPA"
            companyName="Hoch Tech"
            position="Sale Executive"
            workingFrom="2021"
            workTill="Present"
            educationName="Computer Engineering"
            edStart="2017"
            edEnd="2020"
            city="Bangalore"
            state="Karnataka"
          />
          <InfoCard
            image="/assets/images/dp.png"
            name="Ross Galler"
            experience="7 Years"
            education="Graduate"
            salary="6-9 LPA"
            companyName="Hoch Tech"
            position="Sale Executive"
            workingFrom="2021"
            workTill="Present"
            educationName="Computer Engineering"
            edStart="2017"
            edEnd="2020"
            city="Bangalore"
            state="Karnataka"
          />
          <InfoCard
            image="/assets/images/dp.png"
            name="Ross Galler"
            experience="7 Years"
            education="Graduate"
            salary="6-9 LPA"
            companyName="Hoch Tech"
            position="Sale Executive"
            workingFrom="2021"
            workTill="Present"
            educationName="Computer Engineering"
            edStart="2017"
            edEnd="2020"
            city="Bangalore"
            state="Karnataka"
          />
          <InfoCard
            image="/assets/images/dp.png"
            name="Ross Galler"
            experience="7 Years"
            education="Graduate"
            salary="6-9 LPA"
            companyName="Hoch Tech"
            position="Sale Executive"
            workingFrom="2021"
            workTill="Present"
            educationName="Computer Engineering"
            edStart="2017"
            edEnd="2020"
            city="Bangalore"
            state="Karnataka"
          />
        </div>
        <div className="col-lg-3 col-md-4 pe-2 mb-2">
          <Filter />
        </div>
      </Row>
      <Row>
        <div className="d-flex justify-content-center my-3">
          <Pagination />
        </div>
      </Row>
    </Container>
  );
};
export default Listing;
