/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jsquizmain.css";
import { Container, Row } from "react-bootstrap";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { quizeData } from "../../redux/action/action";

const Jsquizmain = () => {
  // const quizes = useSelector((state) => state?.qiuize)
  const [quizDetails, setQuizDetails] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectData, setSelectData] = useState(
    selectedOptions[quizDetails[currentQuestion]?.detailid] || ""
  );
  
  
  const [detailsId, setDetailsId] = useState();
  const [quize, setQuize] = useState([]);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const [lastObject, setLastObject] = useState();
  const location = useLocation();
  const { id } = useParams();
  const idquiz = location.state?.quizId;
  const str = idquiz != id ? "" : window.atob(id);
  const quizId = str.substring(0, str.indexOf(' '));

  const pageno = location.state?.data;
  const navigate = useNavigate();
  const dispatch=useDispatch();

  useEffect(() => {
    if(idquiz != id){
      navigate("*")
      }
  }, [id]);
  
  useEffect(() => {
    geQuizDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quizDetails && quizDetails[currentQuestion]) {
      setDetailsId(quizDetails[currentQuestion].detailid);
    }
  }, [quizDetails, currentQuestion, quize, detailsId]);

  /******************** API CALL START HERE **************************/
  const geQuizDetails = () => {
    const body = {
      isactive: "Y",
      quizid: quizId,
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetQuizDetail}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setQuizDetails(response.data.data);
        } else {
          swal(`${response.data.massage}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  /******************** API CALL END HERE **************************/

  /******************** FUNCTION CALL START HERE **************************/
  const PrevQuestion = () => {
    if (currentQuestion > 0) {
      const prevQuestionIndex = currentQuestion - 1;
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [quizDetails[currentQuestion]?.detailid]: selectData,
      }));

      setCurrentQuestion(prevQuestionIndex);
      const previousQuestionData = quize.find(
        (item) => item.detailid === quizDetails[prevQuestionIndex].detailid
      );
      if (previousQuestionData) {
        setSelectData(
          selectedOptions[quizDetails[currentQuestion - 1]?.detailid] || ""
        );
      } else {
        // setSelectData("");
      }
    }
  };

  // Function to update the state based on previous state
  const nextQuestions = () => {
    const data = {
      quizid: quizId,
      detailid: detailsId,
      option_select: selectData,
      correctoption: quizDetails?.[currentQuestion]?.correctoption,
    };
    const detailDict = {};
    const questions = [...quize, data];
    for (const entry of questions) {
      detailDict[entry.detailid] = entry;
    }
    const updatedData = Object.values(detailDict);
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [quizDetails[currentQuestion]?.detailid]: selectData,
    }));
    if (lastObject) {
      quize.forEach((item) => {
        if (item.detailid == data.detailid) {
          item.option_select = selectData;
        }
      });
      setQuize([...quize, data]);
      setLastObject();
    } else {
      setQuize([...quize, data]);
    }
    if (currentQuestion < quizDetails?.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectData(
        selectedOptions[quizDetails[currentQuestion + 1]?.detailid] || ""
      );
    } else {
      const body = {
        isactive: "Y",
        quizid: quizId,
        userid: login?.userid,
        quizDetailParticipateList: updatedData,
      };
      axiosInstance
        .post(`${Constants.SaveQuizDetail}`, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            navigate("/j_myaccount/j_result",{ replace: true ,header:quizDetails?.[currentQuestion]?.name})
            setSelectData("");
            dispatch(quizeData({ShowResults:true, ScoreTotal:response.data.data ,id:quizId,quizDetails:quizDetails}))
          } else {
            swal(`${response.data.massage}`, "", "error");
          }
        })
      // setShowResults(true);
    }
    let allRadio = document.querySelectorAll(".optionGroup");
    allRadio.forEach((value) => (value.checked = false));
  };
  // const nextQuestions = () => {
  //   const data = {
  //     quizid: id,
  //     detailid: detailsId,
  //     option_select: selectData,
  //     correctoption: quizDetails?.[currentQuestion]?.correctoption,
  //   };
  //   const detailDict = {};
  //   const questions = [...quize, data];
  //   for (const entry of questions) {
  //     detailDict[entry.detailid] = entry;
  //   }
  //   const updatedData = Object.values(detailDict);
  //   setSelectedOptions((prevSelectedOptions) => ({
  //     ...prevSelectedOptions,
  //     [quizDetails[currentQuestion]?.detailid]: selectData,
  //   }));
  //   if (lastObject) {
  //     quize.forEach((item) => {
  //       if (item.detailid == data.detailid) {
  //         item.option_select = selectData;
  //       }
  //     });
  //     setQuize([...quize, data]);
  //     setLastObject();
  //   } else {
  //     setQuize([...quize, data]);
  //   }
  //   if (currentQuestion < quizDetails?.length - 1) {
  //     setCurrentQuestion(currentQuestion + 1);
  //     setSelectData(
  //       selectedOptions[quizDetails[currentQuestion + 1]?.detailid] || ""
  //     );
  //   } else {
  //     const body = {
  //       isactive: "Y",
  //       quizid: id,
  //       userid: login?.userid,
  //       quizDetailParticipateList: updatedData,
  //     };
  //     axiosInstance
  //       .post(`${Constants.SaveQuizDetail}`, body, {
  //         headers: {
  //           "Content-type": "application/json",
  //           Authorization: `Bearer ${Token}`,
  //         },
  //       })
  //       .then((response) => {
  //         if (
  //           response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
  //         ) {
  //           localStorage.clear();
  //           navigate("/");
  //         } else if (response.data.status === Constants.CODE_SUCCESS) {
  //           setScoreTotal(response.data.data);
  //           // swal(`${response.data.massage}`, "", "success");
  //           setSelectData("");
  //           dispatch(quizeData({ShowResults:true ,score:response.data.data}))
  //         } else {
  //           swal(`${response.data.massage}`, "", "error");
  //         }
  //       })
  //     setShowResults(true);
  //   }
  //   let allRadio = document.querySelectorAll(".optionGroup");
  //   allRadio.forEach((value) => (value.checked = false));
  // };


  /******************** FUNCTION CALL END HERE **************************/

  return (
    <>
                <Container fluid className="mainPagesContainer">
                  <Container>
                    <Row>
                      <div className="col-12">
                        <div className="breadcrubsContainer bgWhite p-3">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                              <li className="breadcrumb-item">
                                <Link
                                  to="/j_myaccount"
                                  className="linkNone textLightGray w600"
                                >
                                  My Account
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <Link
                                  to="/j_myaccount/j_quizhome"
                                  state={{data: pageno}}
                                  className="linkNone textLightGray w600"
                                >
                                  My Quiz
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <a href="#" className="linkNone textGray w600">
                                  {quizDetails?.[currentQuestion]?.name}
                                </a>
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </Row>
                    {quizDetails.length > 0 ? (
                      <Row>
                        <div className="col-md-6">
                          <div className="quizQestionContainer w-100 p-3 bgWhite mt-4">
                            <p className="text-end textLightGray w60">
                              {currentQuestion + 1} /{quizDetails?.length}
                            </p>
                            <div>
                              <h4 className="mb-4">
                                {currentQuestion + 1}.
                                {quizDetails?.[currentQuestion]?.questions}
                              </h4>

                              <input
                                type="radio"
                                id="1"
                                name="fav_language"
                                value={quizDetails?.[currentQuestion]?.option1}
                                className="optionGroup"
                                checked={
                                  selectData ===
                                  quizDetails?.[currentQuestion]?.option1
                                }
                                onChange={(e) =>
                                  setSelectData(
                                    quizDetails?.[currentQuestion]?.option1
                                  )
                                }
                              />
                              <label
                                htmlFor="1"
                                className="ms-3 w600 textPrime2"
                              >
                                {quizDetails?.[currentQuestion]?.option1}
                              </label>
                              <hr className="optionHr" />
                              <input
                                type="radio"
                                id="2"
                                name="fav_language"
                                value={quizDetails?.[currentQuestion]?.option2}
                                className="optionGroup"
                                checked={
                                  selectData ===
                                  quizDetails?.[currentQuestion]?.option2
                                }
                                onChange={() =>
                                  setSelectData(
                                    quizDetails?.[currentQuestion]?.option2
                                  )
                                }
                              />
                              <label
                                htmlFor="2"
                                className="ms-3 w600 textPrime2"
                              >
                                {quizDetails?.[currentQuestion]?.option2}
                              </label>
                              <hr className="optionHr" />
                              <input
                                type="radio"
                                id="3"
                                name="fav_language"
                                value={quizDetails?.[currentQuestion]?.option3}
                                checked={
                                  selectData ===
                                  quizDetails?.[currentQuestion]?.option3
                                }
                                className="optionGroup"
                                onChange={() =>
                                  setSelectData(
                                    quizDetails?.[currentQuestion]?.option3
                                  )
                                }
                              />
                              <label
                                htmlFor="3"
                                className="ms-3 w600 textPrime2"
                              >
                                {quizDetails?.[currentQuestion]?.option3}
                              </label>
                              <hr className="optionHr" />
                              <input
                                type="radio"
                                id="4"
                                name="fav_language"
                                value={quizDetails?.[currentQuestion]?.option4}
                                checked={
                                  selectData ===
                                  quizDetails?.[currentQuestion]?.option4
                                }
                                className="optionGroup"
                                onChange={() =>
                                  setSelectData(
                                    quizDetails?.[currentQuestion]?.option4
                                  )
                                }
                              />
                              <label
                                htmlFor="4"
                                className="ms-3 w600 textPrime2"
                              >
                                {quizDetails?.[currentQuestion]?.option4}
                              </label>
                              <hr className="optionHr" />
                              <div className="text-center d-flex">
                                {currentQuestion !== 0 && (
                                  <button
                                    className={
                                      "borderNone textWhite bgPrime2 px-3 py-2"
                                    }
                                    type="button"
                                    onClick={() => PrevQuestion()}
                                  >
                                    Previous
                                  </button>
                                )}
                                {currentQuestion !== quizDetails.length - 1 && (
                                  <button
                                    className={
                                      !selectData
                                        ? "borderNone textWhite bgLightGray btnsize py-2 ms-3"
                                        : "borderNone textWhite bgPrime2 px-4 btnsize py-2 ms-3"
                                    }
                                    disabled={!selectData}
                                    type="button"
                                    onClick={() => nextQuestions()}
                                  >
                                    Next
                                  </button>
                                )}
                                {currentQuestion === quizDetails.length - 1 && (
                                  <button
                                    className={
                                      !selectData
                                        ? "borderNone textWhite bgLightGray px-4 py-2 ms-3"
                                        : "borderNone textWhite bgPrime2 px-4 py-2 ms-3"
                                    }
                                    disabled={!selectData}
                                    type="button"
                                    onClick={() => nextQuestions()}
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    ) : null}
                  </Container>
                </Container>
    </>
  );
};
export default Jsquizmain;
