import React from "react";
import "./jobAndCity.css";
import "../../Common/common.css";

const JobAndCity = (props) => {
  return (
    <div className="bgOffWhite text-center jobAndCityBoxContainer " onClick={props.onClick}>
      <p className="textGray font18 w700 mb-0">{props.name}</p>
      <p className="textLightGray font16 w600 mb-0">
        {props.candidate} + Candidates
      </p>
    </div>
  );
};

export default JobAndCity;
