// / eslint-disable jsx-a11y/anchor-is-valid /
import React, { useEffect, useState } from "react";
import "./jsquizresult.css";
import "../../Common/common.css";
import "../JSQuizMain/jsquizmain.css";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { quizeData } from "../../redux/action/action";
import { RiEmotionSadFill } from "react-icons/ri";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";

const Jsquizresult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state?.token);
  const [gif, setGif] = useState([])
  const quizes = useSelector((state) => state?.qiuize);
  const totalScore =
    quizes?.ScoreTotal.reward_point_each_question *
    quizes?.ScoreTotal.total_question;
  const result = (
    (quizes?.ScoreTotal.erningpoint_total / totalScore) *
    100
  ).toFixed(2);
  useEffect(() => {
    getGif();
  }, [])

  const getGif = () => {
    const body = {
      groupname: "Quiz_Gif",
      isactive: "Y",

    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setGif(response?.data?.data[0]?.imageurl);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  const resetData = () => {
    dispatch(quizeData(null));
    navigate("/j_myaccount/j_quizhome");
  };
  return (
    <>
      <Container fluid className="mainPagesContainer">
        <Container>
          <Row>
            <div className="col-12">
              <div className="breadcrubsContainer bgWhite p-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link
                        to="/j_myaccount"
                        className="linkNone textLightGray w600"
                        onClick={resetData}
                      >
                        My Account
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link
                        to="/j_myaccount/j_quizhome"
                        className="linkNone textLightGray w600"
                        onClick={resetData}
                      >
                        My Quiz
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#" className="linkNone textGray w600">
                        {quizes?.ScoreTotal?.name}
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </Row>
          {quizes?.ScoreTotal.correctanswer > 0 &&
            <Row className="text-center pt-5 imagess mx-2 " style={{ backgroundImage: `url(${gif})`
         
            }}>
              <div className="mt-5 p-3">
                <p className="mt-5 font24 w700 textGray">
                  Congratulations !!! you have scored
                </p>
                <h1 className="fontXL textPrime2 mb-0">
                  {quizes?.quizDetails.length} /{" "}
                  {quizes?.ScoreTotal.correctanswer} correct - {result} %
                </h1>
                <p className="font20 w700 textGray mt-2">
                  & You have earned
                  <span className="textPrime2">
                    &nbsp;{quizes?.ScoreTotal.erningpoint_total} Reward
                    Points
                  </span>
                </p>
                <div className="text-center">
                  <button
                    className="borderNone bgPrime2 textWhite px-3 py-2 r20"
                    onClick={resetData}
                  >
                    Claim reward
                  </button>
                </div>
              </div>
            </Row>
          }
          {quizes?.ScoreTotal.correctanswer === 0 &&
            <Row className="text-center pt-5 ">
              <div className="mt-5 p-3">

                <p className="mt-5 font24 w700 textGray d-flex justify-content-center">
                  Your total score was  <span className="fontXL textPrime2 mb-0 ms-2"> {quizes?.ScoreTotal.correctanswer} / {quizes?.quizDetails.length}
                    </span>
                </p>

                <p className="font20 w700 textGray mt-2">
                  Better luck next time <RiEmotionSadFill className="emogy" />
                </p>
                <div className="text-center">
                  <button
                    className="borderNone bgPrime2 textWhite px-3 py-2 r20"
                    onClick={resetData}
                  >
                    Play new quiz
                  </button>
                </div>
              </div>
            </Row>
          }
        </Container>
      </Container>
    </>
  );
};
export default Jsquizresult;
