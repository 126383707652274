import {
  SET_JSLOGIN,
  SET_JSOTP,
  SET_RTOTP,
  SET_RTLOGIN,
  SET_USER,
  SET_TOKEN,
  SAVE_RTLOGIN,
  SAVE_RTPROFILEINFO,
  SAVE_QUIZEDATA,
} from "./action-types";
import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  user: null,
  login: null,
  token: null,
  
};
export const logout = () => ({ type: REHYDRATE, payload: initialState });
export const setToken = (token) => ({ type: SET_TOKEN, token });

// User Login
export const userLogin = (user) => ({ type: SET_USER, payload: user });

// Recruiter login and otp
export const rtLogin = (user) => ({
  type: SET_RTLOGIN,
  payload: user,
});
export const rtOtp = (user) => ({ type: SET_RTOTP, payload: user });

//Jobseeker Login and otp
export const jsLogin = (login) => ({
  type: SET_JSLOGIN,
  payload: login,
});

export const jsOtp = (user) => ({ type: SET_JSOTP, payload: user });

export const rtloginUser = (users) => ({ type: SAVE_RTLOGIN,payload:users});
export const rtProfileInfo = (users) => ({ type:SAVE_RTPROFILEINFO,payload:users});

// quize data 
export const quizeData = (users) => ({ type:SAVE_QUIZEDATA,payload:users});