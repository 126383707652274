import { SET_RTLOGIN, SET_JSLOGIN, SET_RTOTP, SET_JSOTP, SET_USER, SET_TOKEN, SAVE_RTLOGIN, SAVE_RTPROFILEINFO, SAVE_QUIZEDATA } from "../action/action-types";
import { REHYDRATE } from "redux-persist/lib/constants";

let initial = {
  users:null,
  user: null,
  login: null,
  token: null,
  
};

const reducer = (state = initial, action) => {
  switch (action.type) {
    case SET_USER:
      return {...state,  users: action.payload};
    case SET_RTLOGIN:
      return {...state,  user: action.payload };
    case SET_RTOTP:
      return Object.assign({}, state, { user: action.payload });
    case SET_JSLOGIN:
      return { ...state, login: action.payload };
    case SET_JSOTP:
        return Object.assign({}, state, { login: action.payload});
    case SET_TOKEN:
      return Object.assign({}, state, { token: action.token });
    case SAVE_RTLOGIN:
      return { ...state, users: action.payload };
    case SAVE_RTPROFILEINFO:
      return { ...state, users: action.payload };
      case SAVE_QUIZEDATA:
      return { ...state, qiuize: action.payload }; 
    case REHYDRATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
