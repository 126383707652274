import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import AppStore from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={AppStore.store}>
    <PersistGate loading={null} persistor={AppStore.persistor}>
        <Router>
          <App className="hide-overflow"/>
        </Router>
        </PersistGate>
    </Provider>
   
  </React.StrictMode>
);
reportWebVitals();
