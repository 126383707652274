/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jsshare.css";
import { Container, Row } from "react-bootstrap";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from "react-slick";

const Jsshare = () => {
  const [socialIconData, setSocialIconData] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getJobSeekerSocialIcon();
    getCompanyGeneralDetail();
  }, []);

  /******************** API CALL START HERE **************************/
  const getJobSeekerSocialIcon = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerSocialIcon}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setSocialIconData(response.data.data);
        } else {
          swal(`${response.data.massage}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
        swal(`${error.response}`, "", "error")
      });
  };
  const getCompanyGeneralDetail = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetCompanyGeneralDetail}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_SUCCESS) {
          setCompanyDetails(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  /******************** API CALL END HERE **************************/
  const downloadModalSlider = {
    rows: 1,
    className: "center",
    infinite: true,
    dots: false, 
    arrows: true, 
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
   
  };

  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-xl-8 col-lg-9 col-md-12 mt-4">
                    <div className="shareMainBox bgWhite p-3">
                    <div className="row"> 
                      <div className="col-md-4 socialIconCol">
                    <Slider {...downloadModalSlider} className="">
                        <div className="qrbox p-3">
                      <div>           
                        <p className="text-center textGray w600">
                         App Store QR code
                        </p>
                        <img
                            src={companyDetails[0]?.link_ios_qrcode}
                          className="qrImage"
                          alt=""
                        />
                            </div>
                        </div>   
                         <div className="qrbox p-3">   
                        <div>           
                        <p className="text-center textGray w600">
                        Play Store QR code 
                        </p>
                        <img
                          src={companyDetails[0]?.link_android_qrcode}
                          className="qrImage"
                          alt=""
                        />
                        </div>      
                    </div>
                      </Slider>
                      </div>
                         <div className="col-md-8 ScanCol">
                      <div className="mx-auto p-3 shareLink text-center">
                        <p className="text-center textGray w600">
                          Share via app
                        </p>
                        <div className="d-flex flex-wrap socialIconBox">
                          {socialIconData?.map((item, key) => {
                             const platformLink = navigator.userAgent.match(/iPhone|iPad|iPod/i)
                             ? item?.linkurlios || item?.linkurl 
                             : item?.linkurl;
                            return (
                              <Link to={platformLink} key={key}>
                                <img
                                  src={item?.imageurl}
                                  alt=""
                                  className="mx-2 imgIcon"
                                />
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Jsshare;
