/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import { Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { Link, useNavigate } from "react-router-dom";
import Candidatecardrt from "../../Components/CandidateCardRT/candidatecardrt";
import { useSelector } from "react-redux";

const Rtsearch = () => {
  const [recruitmentData, setRecruitmentData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getRecruitmentData();
  }, []);

  /******************** API CALL START HERE **************************/
  const getRecruitmentData = () => {
    const body = {
      loginuserid: 23041610034887,
      isfavourite: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRecruitmentData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };
  /******************** API CALL END HERE **************************/
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/rt_alljobs"
                              className="linkNone textLightGray w600"
                            >
                              Hire
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              Search
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12 mt-4">
                    <div className="searchMainBox w-100 bgWhite p-3">
                      <Row>
                        <div className="col-md-6">
                          <input
                            onChange={(e) => setSearchField(e.target.value)}
                            type="text"
                            placeholder="Enter job title, keywords or company name"
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            onChange={(e) => setSearchLocation(e.target.value)}
                            placeholder="Location"
                          />
                        </div>
                        <div className="text-center">
                          <button className="bgPrime2 textWhite borderNone px-3 py-2 mt-4 searchBtn">
                            Search
                          </button>
                        </div>
                      </Row>
                    </div>
                  </div>
                  {recruitmentData
                    ?.filter((item) => {
                      if (searchField === "") {
                        return item;
                      } else if (
                        item?.skillname
                          .toLowerCase()
                          .includes(searchField.toLowerCase()) ||
                        item?.jobtypename
                          .toLowerCase()
                          .includes(searchField.toLowerCase()) ||
                        item?.functionalareaname
                          .toLowerCase()
                          .includes(searchField.toLowerCase()) ||
                        item?.education
                          .toLowerCase()
                          .includes(searchField.toLowerCase()) ||
                        item?.companyname
                          .toLowerCase()
                          .includes(searchField.toLowerCase()) ||
                        item?.requiredexperience
                          .toLowerCase()
                          .includes(searchField.toLowerCase()) ||
                        item?.salary
                          .toLowerCase()
                          .includes(searchField.toLowerCase()) ||
                        item?.location
                          .toLowerCase()
                          .includes(searchLocation.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item, key) => (
                      <Row>
                        <div className="col-md-6 mt-4" key={key}>
                          <Link
                            to={`/rt_alljobs/rt_candidatelist/rt_candidatedetails/${item?.jobtypeid}`}
                            className="linkNone"
                          >
                            <Candidatecardrt
                              description={item?.description}
                              firstname={item?.firstname}
                              lastname={item?.lastname}
                              company={item?.companyname}
                              experience={item?.requiredexperience}
                              salary={item?.salary}
                              education={item?.education}
                              Icons={
                                item?.isfavourite === "Y"
                                  ? "font20 text-danger mx-3"
                                  : "font20 textLightGray mx-3"
                              }
                            />
                          </Link>
                        </div>
                      </Row>
                    ))}
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Rtsearch;
