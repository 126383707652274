/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "../JSMyPayment/jsmypayment.css";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { BsFillDiamondFill } from "react-icons/bs";
import { AiFillCheckSquare } from "react-icons/ai";

const Jsmyplan = () => {
  const [paymentData, setPaymentData] = useState();
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getJobSeekerPlanPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************** API CALL START HERE **************************/
  const getJobSeekerPlanPayment = () => {
    const body = {
      userid: login?.userid,
      isactive: "Y",
      ispayment: "Y",
      isvalid: "Y",
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerPlanPaymentByFeachers}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPaymentData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });
  
  };

  /******************** API CALL END HERE **************************/
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/j_myaccount"
                              className="linkNone textLightGray w600"
                            >
                              My Account
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              My Plan
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  {paymentData?.map((item, key) => {
                    const convertDateFormat = (dateString) => {
                      const [day, month, year] = dateString.split("-");
                      return `${year}-${month.padStart(2, "0")}-${day.padStart(
                        2,
                        "0"
                      )}`;
                    };
                    const validon = item?.plan?.validon;
                    const endDateString = convertDateFormat(validon);
                    const currentDate = new Date();
                    const endDate = new Date(endDateString);
                    const daysLeft = Math.max(
                      Math.ceil(
                        (endDate - currentDate) / (1000 * 60 * 60 * 24)
                      ),
                      -1
                    );
                    return (
                      daysLeft >= 0 && (
                        <div className="col-md-6 mt-4" key={key}>
                          <div className="singlePaymentSlip3 w-100 p-3">
                            <div className="d-flex justify-content-between">
                              <h4 className="w600 mb-0 textWhite font18">
                                {item?.planname}
                              </h4>
                              <span>
                                <AiFillCheckSquare className="font30 textWhite w600 custom-check-icon" />
                              </span>
                            </div>
                            <div className="my-4 dotted-line textWhite" />
                            <div className="d-flex justify-content-between mt-2">
                              <div>
                                {item?.planfeachers?.map((feacher, index) => (
                                  <h5 className="textWhite w600 font16" key={index}>
                                    <BsFillDiamondFill className="me-2 w600 font14" />
                                    {feacher?.feachers}
                                  </h5>
                                ))}
                              </div>
                              <div>
                                <h5 className="textWhite w600 font16">
                                 {item.currency}{item.amount_pay}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </Row>
              </Container>
            </Container>
    </>
  );
};

export default Jsmyplan;
