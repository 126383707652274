/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "../../Common/common.css";
import "./download.css";
import DownloadModal from "../DownloadModal/downloadModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsCursorFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import * as Constants from "../../Common/Global/constants";
import axiosInstance from "../../Api/commonUrl";
import swal from "sweetalert";

const Download = () => {
  const [show, setShow] = useState(false);
  let [contactdata,setContactdata] = useState("");
  const [active, setActive] = useState(false);
  const [homePageMaster, setHomepageMaster] = useState([])
  let [error, setError] = useState("");
  const number = new RegExp("^[0-9]{10}$");
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/jobseeker") setActive(true);
  }, [active, location]);

  useEffect(() => {
    getHomePageMaster();
  },[])
  

    /**Validation Function */
    const validate = () => {
      let errors = [];
      if (!contactdata?.trim()) {
        errors.contactdata = "Mobile number is required";
      } else if (!contactdata?.match(number)) {
        errors.contactdata = "Enter valid Mobile number";
      }
      return setError(errors);
    };
    const numberOnchange = (e) => {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setContactdata(e.target.value);
      }
    };
  const saveContact=()=>{
    validate()
    if(!contactdata?.match(number)==""){
      setContactdata(contactdata)
      setShow(true)
      setError("")
    }
  }

  const handleDocumentClick = (e) => {
    if (
      e.target.name !== "contactdata" &&
      !e.target.classList.contains("downloadSendBtn")
    ) {
      setError(""); // Clear the error message
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  /******************** API CALLS START HERE **************************/
  const getHomePageMaster = () => {
    const body = {
      groupname: "Home_App_Screen", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
    /******************** API CALLS END HERE **************************/

  return (
    <>
      <Container fluid className="bgOffWhite downloadContainer">
        <Container>
          <Row className="pt-5">
          {homePageMaster?.map((item,index)=>(
            <div className="col-md-3 d-none d-lg-block" key={index}>
              <img src={item?.imageurl} className="mockImg" alt="" />
            </div>
            ))}
            <div className="col-md-8">
              <h1 className="w700 textGray">
                Get the <span className="textPrime">App</span> Now
              </h1>
              <p className="w500 textLightGray mt-4">
              Provide Your Mobile Number here to get Android or iOS Application Link for real-time job updates using our App.
              </p>
              <Row>
                <div className="inputWrap mt-4">
                  <input
                    type="text"
                    name="contactdata"
                    value={contactdata}
                    onChange={numberOnchange}
                    className="downloadInput borderNone outlineNone"
                    placeholder="Enter mobile number"
                    maxLength={10}
                  />
                  <button
                    className="downloadSendBtn fullRadious borderNone bgPrime textWhite greenHover"
                    onClick={saveContact}
                  >

                  < BsCursorFill  />
                  </button>
                  {!contactdata?.match(number) && error.contactdata ? (
            <p className="errContact mt-2">{error.contactdata}</p>
          ) : null}
                </div>
                {active === true ? (
                  <div className="col-6 downloadLogin">
                    <span className="w700 orText"> OR </span>
                    <Link to="/j_login">
                      <button className="buttonCommon borderNone w500 mt-4 greenHover loginbutton text-white bgPrime">
                        Login
                      </button>
                    </Link>
                  </div>
                ) : null}
              </Row>
              <DownloadModal show={show} contactdata={contactdata} setShow={setShow} setContactdata={setContactdata} />
            </div>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Download;
