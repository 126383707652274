/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-array-constructor */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./rtcandidatedetails.css";
import { Container, Row } from "react-bootstrap";
import { AiTwotoneHeart } from "react-icons/ai";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import Jobcard from "../../Components/JobCard/jobcard";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Slider from "react-slick";

const Rtcandidatedetails = () => {
  const [jobSeekerData, setJobSeekerData] = useState([]);
  const { id } = useParams();
  const Token = useSelector((state) => state?.token);
  const user = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState([]);
  const workexperience = Array.isArray(profileData)? profileData?.flatMap((item) => item?.workexperience): [];
  const bio = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.bio)
    : [];
  const education = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.education)
    : [];
  const certification = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.certification)
    : [];
  const jobpreferences = Array.isArray(profileData)
    ? profileData?.flatMap((item) => item?.jobpreferences)
    : [];
  const skillList = jobpreferences?.map((item) => {
    return item.skillList;
  });
  const skillName = []?.concat(...skillList)?.map((item) => item?.skillname);
  const location = useLocation();
  const pageno = location.state?.data;
  const selectedValue = location?.state?.selectedvalue;
  const postjobid = location?.state?.postjobid;
  const pageCount = location?.state?.pageCount;
  const jobSeekerData1 = location?.state?.jobSeekerList1;
  const jobtypename1 = location.state?.jobtypename;
  const expactedindustryname1 = location.state?.expactedindustryname;
  const requiredexperience1 = location.state?.requiredexperience;
  const city1 = location.state?.city;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 568);
  const [defaultImg, setDefaultImg] = useState([])
  useEffect(() => {
    getJobSeekerData();
    GetJobSeekerFullProfileData();
    getHomePageMaster();
  }, [postjobid]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 568);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  /******************** API CALL START HERE **************************/

  const GetJobSeekerFullProfileData = () => {
    const body = {
      userid: id,
      pageable: {},
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerFullProfile}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setProfileData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getJobSeekerData = () => {
    if (id !== "null") {
      const body = {
        userid: id,
        postjobid: postjobid,
      };
      axiosInstance
        .post(`${Constants.GetJobSeekerData}`, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            setJobSeekerData(response.data.data);
          } else {
            swal(`${response.data.error}`, "", "error");
          }
        })
        .catch((error) => {
          if (
            error.response.data?.status ===
            Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          }
        });
    }
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Profile_image", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setDefaultImg(response.data.data?.[0]?.imageurl);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  /******************** API CALL END HERE **************************/
  
  /******************** SLIDERS START HERE **************************/
    const workExperienceSlider = {
      rows: 1,
      infinite: workexperience.length >= 2,
      arrows: workexperience.length >= 2,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1080,
          settings: {
            arrows: workexperience.length >= 2,
            infinite: workexperience.length >= 2,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const educationSlider = {
      rows: 1,
      infinite: education.length >= 2,
      arrows: education.length >= 2,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const certificationSlider = {
      rows: 1,
      infinite: certification.length >= 2,
      arrows: certification.length >= 2,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 2,
            rows: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            arrows:true,

            slidesToScroll: 1,
          },
        },
      ],
    };
   /******************** SLIDERS END HERE **************************/
   


  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                {jobSeekerData?.slice(0, 1).map((item, index) => (
                  <div key={index}>
                    <Row>
                      <div className="col-12">
                        <div className="d-flex align-items-baseline">
                          <Link
                            to={`/rt_candidatelist/`}
                            state={{
                              data: pageno,
                              selectedvalue: selectedValue,
                              pageCount: pageCount,
                              jobSeekerList1: jobSeekerData1,
                              jobtypename: jobtypename1,
                              city: city1,
                              expactedindustryname: expactedindustryname1,
                              requiredexperience: requiredexperience1,
                            }}
                          >
                            <h4 className="mb-0 textPrime2 w600">
                              <MdOutlineKeyboardBackspace
                                style={{ width: "50px", height: "35px" }}
                              />
                            </h4>
                          </Link>
                          <h4 className="mb-0 textPrime2 w600 font18">Back</h4>
                        </div>
                        <div className="jobDetailsHeaderContainer bgWhite p-4 d-flex mt-3 justify-content-between w-100">
                          <div className="d-flex">
                            <img
                              src={
                                item?.profileimage === "" || null || "null"
                                ? defaultImg
                                :  item?.profileimage
                              }
                              className="userImage rounded-circle"
                              alt=""
                            />
                            <div className="ms-4">
                              <h4 className="mb-0 w600 textPrime2 font18">
                                {item?.firstname} {item?.lastname}
                              </h4>
                              <p className="mb-0 w500 text-secondary font16">
                                {item?.workexperience_roleandresponsibilities}
                              </p>
                            </div>
                          </div>

                          <div>
                            <AiTwotoneHeart
                              className={
                                item?.isfavouritebyrecruiter === "Y"
                                  ? "font20 text-danger activeLink mx-3"
                                  : "font20 textLightGray mx-3"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Row>
                    <div className="p-4">
                      <Row>
                        <div>
                          <p className="mt-4 mb-0 w600 font16">Description</p>
                          {bio?.length > 0 &&
                            bio?.map((item, index) => (
                              <p
                                className="mt-1 text-secondary textJustify font14"
                                key={index}
                              >
                                {item?.bio}
                              </p>
                            ))}
                        </div>
                      </Row>
                      <hr className="opacity50" />
                      <Row>
                        <div className="d-flex mt-3">
                          <div className="me-5">
                            <p className="mb-0 textGray w600 font16">
                              Experiences
                            </p>
                            <p className="mb-0 textPrime2 w600 font14">
                              {item?.totalexperience}
                            </p>
                          </div>
                          <div className="me-5">
                            <p className="mb-0 textGray w600 font16">
                              Education
                            </p>
                            <p className="mb-0 textPrime2 w600 font14">
                              {item?.educationlevel}
                            </p>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                ))}
                <div className="p-4">
                  <hr className="opacity50" />
                  <Row>
                    <div className="mt-3">
                      <p className=" mb-0 w600 font16">Job Preferences</p>
                      <p className="mt-1 text-secondary textJustify">
                        <Row>
                          {jobpreferences?.length > 0 &&
                            jobpreferences?.map((item, key) => {
                              return (
                                <div className="col-md-6 mt-3" key={key}>
                                  <Jobcard
                                  experienceCol="col-sm-12"
                                  experienceNone="none"
                                    key={key}
                                    Title="Preference"
                                    jobtitle={item?.jobpreference}
                                    startSalary={
                                      item?.expactedsalaryname === null
                                        ? ""
                                        : user?.currency
                                    }
                                    divnone={`${isMobile ? 'none' : ''}`}
                                    yearsAndsalary={item?.expactedsalaryname}
                                    endSalary={
                                      item?.expactedsalaryname === null
                                        ? ""
                                        : "LPA"
                                    }
                                    eductionTitle="Indurstries Name"
                                    educationAndInstitute={
                                      item?.expactedindustryname
                                    }
                                    nameTitle="Prefered City"
                                    Name={item?.city}
                                  />
                                </div>
                              );
                            })}
                        </Row>
                      </p>
                    </div>
                  </Row>

                  <hr className="opacity50" />
                  <Row>
                    <div className="col-md-6">
                      <p className="mt-4 w600 font16">Skills</p>
                      {skillName?.map((item, key) => (
                        <button
                          className="jobdetails_skillBTN px-3 py-1 bgWhite me-2 font14 mt-2"
                          key={key}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </Row>
                  <hr className="opacity50 mt-4" />
                  <Row>
                    <div>
                      <p className="mt-4 mb-0 w600 font16">Work Experience</p>
                      <div className="mt-1 text-secondary textJustify">
                      {workexperience?.length > 0 ? <Slider {...workExperienceSlider}>
                          
                            {workexperience?.map((item, key) => {
                              const startYear = item?.startdate
                                ? item?.startdate.split("-")[2]
                                : "";
                              const endYear = item?.enddate
                                ? item?.enddate.split("-")[2]
                                : "";
                              return (
                                <div className={`col-md-6 mt-3 me-5 ms-2`} key={key}>
                                <div className="personlinfocard">
                                  <Jobcard
                                    key={key}
                                    experienceCol="col-sm-12"
                                    experienceNone="none"
                                    Title="Company Name"
                                    jobtitle={item?.companyname}
                                    yearsAndsalary={
                                      item?.present === "Y"
                                        ? `${startYear} - Present`
                                        : `${startYear} - ${endYear} `
                                    }
                                    divnone={`${isMobile ? 'none' : ''}`}
                                    eductionTitle="Designation"
                                    educationAndInstitute={item?.designation}
                                    nameTitle="Role & Responsibilities"
                                    Name={item?.roleandresponsibilities}
                                  />
                                </div>
                                </div>
                              );
                            })}
                        </Slider> : "Fresher"}
                        </div>
                    </div>
                  </Row>
                  <hr className="opacity50" />
                  <Row>
                    <div>
                      <p className="mt-4 mb-0 w600 font16">Education</p>
                      <div className="mt-1 text-secondary textJustify">
                      <Slider {...educationSlider}>
                          {education?.length > 0 &&
                            education?.map((item, key) => {
                              const startYear = item?.startdate
                                ? item?.startdate.split("-")[2]
                                : "";
                              const endYear = item?.enddate
                                ? item?.enddate.split("-")[2]
                                : "";
                              return (
                                <div className="col-md-6 mt-3 me-5 ms-2" key={key}>
                                <div className="personlinfocard">
                                  <Jobcard
                                       experienceCol="col-sm-12"
                                       experienceNone="none"
                                    key={key}
                                    Title="Board/University Name"
                                    divnone={`${isMobile ? 'none' : ''}`}
                                    jobtitle={item?.universityname}
                                    yearsAndsalary={`${startYear} - ${endYear} `}
                                    eductionTitle="Eduction Level"
                                    educationAndInstitute={item?.educationlevel}
                                    nameTitle="Institute Name"
                                    Name={item?.institutename}
                                  />
                                </div>
                                </div>
                              );
                            })}
                      </Slider>
                      </div>
                    </div>
                  </Row>
                  <hr className="opacity50" />
                  <Row>
                    <div className="col-md-12">
                      <p className="mt-4 mb-0 w600 font16">Certificates</p>
                      <div className="mt-1 text-secondary textJustify">
                    <Slider {...certificationSlider}>
                          {certification?.length > 0 &&
                            certification?.map((item, key) => {
                              const dateofcompletion = item?.dateofcompletion
                                ? item?.dateofcompletion.split("-")[2]
                                : "";
                              return (
                                <div className="col-md-6 mt-3  ms-1" key={key} style={{ margin: '0 10px' }}>
                                <div className="personlinfocardCertificate">
                                  <Jobcard
                                    key={key}
                                    experienceCol="col-sm-12"
                                    experienceNone="none"
                                    Title="Course Name"
                                    jobtitle={item?.coursename}
                                    eductionTitle="Date of Completion"
                                    educationAndInstitute={dateofcompletion}
                                    nameTitle="Certificate"
                                    divnone={`${isMobile ? 'none' : ''}`}
                                    icon={
                                      <Link
                                        to={item?.attachcertification}
                                        target="_self"
                                        style={{
                                          fontSize: "14px",
                                          textDecoration: "none",
                                        }}
                                        rel="noreferrer"
                                      >
                                        <FaEye  className="me-2 font18 w600" style={{color:"#404040"}} />
                                      </Link>
                                    }
                                    Name={`${item?.filename}`}
                                  />
                                
                                </div>
                                </div>
                              );
                            })}
                            </Slider>
                            </div>
                    </div>
                  </Row>
                </div>
              </Container>
            </Container>
    </>
  );
};
export default Rtcandidatedetails;
