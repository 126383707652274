import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./myaccountheaderrt.css";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MyaccountheaderRt = (props) => {
  const [personalInfoData, setPersonalInfoData] = useState([]);
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const user = useSelector((state) => state.user);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const [defaultImg, setDefaultImg] = useState([])

  useEffect(() => {
    getRecruitmentPersonalInfo();
    getRecruitmentCompanyInfo();
    getHomePageMaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************** API CALL START HERE **************************/
  const getRecruitmentPersonalInfo = () => {
    const body = {
      userid: user?.userid,
      isapply: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentPersonalInfo}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPersonalInfoData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };

  const getRecruitmentCompanyInfo = () => {
    const body = {
      userid: user?.userid,
      isapply: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentCompanyInfo}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setCompanyInfoData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Profile_image", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setDefaultImg(response.data.data?.[0]?.imageurl);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  /******************** API CALL END HERE **************************/
  return (
    <>
      {personalInfoData?.map((item, key) => (
        <div
          className="myAccountHeaderMainContainer bg-white px-4 align-center"
          key={key}
        >
          <div className={props.personal ? "d-flex mt-2" : "d-flex"}>
            <img
              src={
                item?.profileimage === "" || null || "null"
                ? defaultImg
                :  item?.profileimage
              }
              className="accountHeaderImage rounded-circle"
              alt=""
            />
            <div className="ms-3 mt-2">
              <h3 className="mb-0 w600 textPrime2 font18">
                {item?.firstname} {item?.lastname}
              </h3>
              {companyInfoData?.map((item, key) => (
                <div key={key}>
                  <p className="textLightGray w500 font16" key={key}>
                    {props.personal ? props.company : item?.name}

                    <p className="textLightGray w400 font18">
                      {props.personal}{" "}
                      <Link to="/rt_myaccount/rt_personalinfo">
                        {props.icon}
                      </Link>
                    </p>
                  </p>
                </div>
              ))}
            </div>
          </div>
          <Link to="/rt_myaccount/rt_myplan">
            <p className="textUnderline textPrime2 w600 font18">{props.name}</p>
          </Link>
        </div>
      ))}
    </>
  );
};

export default MyaccountheaderRt;
