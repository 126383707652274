import { React, useState } from "react";
import "./slider.css";

const Slider = () => {
  const [currentItem, setcurrentItem] = useState(0);

  const Items = [
    {
      id: 1,
      image: `/assets/images/seeker1.png`,
      name: "Frencis Underwood",
      city: "New york city,USA",
      description:
        "dipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempusdipiscing elit.",
    },
    {
      id: 2,
      image: `/assets/images/seeker2.png`,
      name: "Alvaro Morte",
      city: "Medrid,Spain",
      description:
        "ac aliquet dipiscing elit. Nunc vulputate libero et velit interdum, odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus",
    },
    {
      id: 3,
      image: `/assets/images/seeker3.png`,
      name: "Andres Fonniosa",
      city: "Tokyo,Japan",
      description:
        "Class aptent taciti sociosq dipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. u ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus",
    },
  ];

  const handleItemPrev = () => {
    setcurrentItem(currentItem - 1);
    if (currentItem <= 0) {
      setcurrentItem(Items.length - 1);
    }
  };
  const handleItemNext = () => {
    setcurrentItem(currentItem + 1);
    if (currentItem >= Items.length - 1) {
      setcurrentItem(0);
    }
  };

  return (
    <div className="sliderContainer">
      <div
        className="btn"
        onClick={() => {
          alert("ff");
        }}
      ></div>
      <div className="box">
        <img
          src={Items[currentItem].image}
          alt=""
          key={Items[currentItem].id}
          style={{ animation: "flash 1.5s .5s" }}
        />
        <div className="left" onClick={handleItemPrev}></div>
        <div className="right" onClick={handleItemNext}></div>
      </div>
      <div className="d-flex">
        <div className="d1" style={{ width: "250px" }}>
          <p style={{ width: "250px" }} className="bg-primary">
            Job Seekers
          </p>
        </div>
        <div className="d2">2</div>
      </div>
    </div>
  );
};
export default Slider;
