import React from "react";
import "./card.css";
import "../../Common/common.css";

const Card = (props) => {
  return (
    <div>
      <div className="cardContainer">
        <div className="pnx-msg-icon d-flex justify-content-center w-100">
          
          <img src={props.src} alt=""   align="center" />
          <div className="font15 textGray ms-3 w600 mb-0">
            <p>{props.name}</p>
          </div>
        </div>
        <hr />
        <p
          className="w500 textLightGray font14 cardhide  "
          dangerouslySetInnerHTML={{ __html: props.description }}
        ></p>
      </div>  
    </div>
  );
};
export default Card;
