/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jscontact.css";
import { Container, Row } from "react-bootstrap";

import Myaccountheaderjsd from "../../Components/MyAccountHeaderJSD/myaccountheaderjsd";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Jscontact = () => {
  const [personalInfo, setPersonalInfo] = useState([]);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getJobSeekerPersonalInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************** API CALL START HERE **************************/
  const getJobSeekerPersonalInfo = () => {
    const body = {
      userid: login?.userid,
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerPersonalInfo}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPersonalInfo(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });
    }
  /******************** API CALL END HERE **************************/
  return (
    <>
            <Container fluid className="mainPagesContainer">
              {personalInfo?.map((item, key) => (
                <Container key={key}>
                  <Row>
                    <div className="col-12">
                      <div className="breadcrubsContainer bgWhite p-3">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                              <Link
                                to="/j_myaccount"
                                className="linkNone textLightGray w600"
                              >
                                My Account
                              </Link>
                            </li>
                            <li className="breadcrumb-item">
                              <a href="#" className="linkNone textGray w600">
                                Contact Us
                              </a>
                            </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-12 mt-4">
                      <Myaccountheaderjsd />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-4 mt-4">
                      <div className="singleContactInfoCard w-100 bgWhite p-4 d-flex">
                        <div>
                          <img src="/assets/images/call.png" alt="" />
                        </div>
                        <div className="ms-3">
                          <p className="mb-0 font18 w600 textGray">
                            Phone number
                          </p>
                          <p className="mb-0 font18 w600 textLightGray">
                            {item?.mobileno}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mt-4">
                      <div className="singleContactInfoCard w-100 bgWhite p-4 d-flex">
                        <div>
                          <img src="/assets/images/location.png" alt="" />
                        </div>
                        <div className="ms-3">
                          <p className="mb-0 font18 w600 textGray">Location</p>
                          <p className="mb-0 font18 w600 textLightGray">
                            {item?.city},{item?.state}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mt-4">
                      <div className="singleContactInfoCard w-100 bgWhite p-4 d-flex">
                        <div>
                          <img src="/assets/images/mail.png" alt="" />
                        </div>
                        <div className="ms-3">
                          <p className="mb-0 font18 w600 textGray">Email</p>
                          <p className="mb-0 font18 w600 textLightGray">
                            {item?.emailid}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Container>
              ))}
            </Container>
    </>
  );
};
export default Jscontact;
