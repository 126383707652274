import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Landingpage from "./Pages/Landingpage/landingpage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import JobSeeker from "./Pages/JobSeeker/jobSeeker";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import Homepage from "./Pages/Homepage/homepage";
import Recruiter from "./Pages/Reruiter/recruiter";
import AllJobsCity from "./Pages/AllJobsCity/allJobsCity";
import CategoryDetails from "./Pages/CategoryDetails/categoryDetails";
import Legal from "./Pages/Legal/legal";
import TermsAndContion from "./Pages/Legal/termsAndContion";
import Contact from "./Pages/Contact/contact";
import CandidateInfo from "./Pages/CandidateInfo/candidateInfo";
import About from "./Pages/About/about";
import Listing from "./Pages/Listing/listing";
import Slider from "./Pages/Slider/slider";
import Pricing from "./Pages/Pricing/pricing";
import PrivacyPolicy from "./Pages/Legal/privacyPolicy";
import RefundPolicy from "./Pages/Legal/refundPolicy";
//jobseeker dashboard
import Jshome from "./JSPages/JSHome/jshome";
import Jsjobdetails from "./JSPages/JSJobDetails/jsjobdetails";
import Jslogin from "./JSPages/JSLogin/jslogin";
import Jsmyaccount from "./JSPages/JSMyAccount/jsmyaccount";
import Jsjoblist from "./JSPages/JSJobList/jsjoblist";
import Jsgreetings from "./JSPages/JSGreetings/jsgreetings";
import Jscontact from "./JSPages/JSContact/jscontact";
import Jsjobpreferences from "./JSPages/JSJobPreferences/jsjobpreferences";
import Jssearch from "./JSPages/JSSearch/jssearch";
import Jsshare from "./JSPages/JSShare/jsshare";
import Jsrewards from "./JSPages/JSRewards/jsrewards";
import Jsquizhome from "./JSPages/JSQuizHome/jsquizhome";
import Jsmypayment from "./JSPages/JSMyPayment/jsmypayment";
import Jsquizmain from "./JSPages/JSQuizMain/jsquizmain";
import Jsquizresult from "./JSPages/JSQuizResult/jsquizresult";
// Recruiter Dashboard
import Rthome from "./RTPages/RTHome/rthome";
import Rtcandidatelist from "./RTPages/RTCandidateList/rtcandidatelist";
import Rtjobinfo from "./RTPages/RTJobInfo/rtjobinfo";
import Rtcandidatedetails from "./RTPages/RTCandidateDetails/rtcandidatedetails";
import Rtmyaccount from "./RTPages/RTMyAccount/rtmyaccount";
import Rtmanagejobs from "./RTPages/RTManageJobs/rtmanagejobs";
import Rtsavedjobs from "./RTPages/RTSavedCandidates/rtsavedcandidates";
import Rtshare from "./RTPages/RTShare/rtshare";
import Rtsearch from "./RTPages/RTSearch/rtseach";
import Rtgreetings from "./RTPages/RTGreetings/rtgreetings";
import Rtcontact from "./RTPages/RTContact/rtcontact";
import Rtmypayment from "./RTPages/RTMypayment/rtmypayment";
import Rtlogin from "./RTPages/RTLogin/rtlogin";
import RtAllJobs from "./RTPages/RTAllJobs/rtalljobs";
import RtOtp from "./RTPages/RTOtp/rtotp";
import JsOtp from "./JSPages/JSOtp/jsotp";
import RTPrivateRoute from "./RTPages/RTPrivateRoute/rtprivateroute";
import JSPrivateRoute from "./JSPages/JSPrivateRoute/jsprivateroute";
import  JSPublicRoute  from "./JSPages/JSPrivateRoute/jspublicroute";
import { useEffect } from "react";
import Jsmyplan from "./JSPages/JSMyPlan/jsmyplan";
import Rtmyplan from "./RTPages/RTMyPlan/rtmyplan";
import JsPersonalinfo from "./JSPages/JSPersonalInfo/jspersonalinfo";
import JsResume from "./JSPages/JSSaved/jsresume";
import  RTPublicRoute from "./RTPages/RTPrivateRoute/rtpublicroute";
import RTPersonalInfo from "./RTPages/RTPersonalInfo/rtPersonalinfo";
import Jshomedetails from "./JSPages/JSHome/jshomeDetails";
import Jsresumedetails from "./JSPages/JSSaved/jsresumeDetails";
import RtAllJobsDetails from "./RTPages/RTAllJobs/rtalljobDetails";
import { NoMatchRoute } from "./Components/NoMatchRouter/nomatchrouter";
import Sidebarjsd from "./Components/SideBarJSD/sidebarjsd";
import { Container, Row } from "react-bootstrap";
import Sidebarrt from "./Components/SidebarRT/sidebarrt";


const JobseekerOutLet = () => {
  return (
    <Container fluid>
        <Row>
          <div className="d-flex">
            <div className="fixed">
              <Sidebarjsd />
            </div>
            <JSPrivateRoute>
        <Outlet />
        </JSPrivateRoute>
        </div>
        </Row>
      </Container>
  )
}

const RecruiterOutLet = () => {
  return (
    <Container fluid>
        <Row>
          <div className="d-flex">
            <div className="fixed">
              <Sidebarrt />
            </div>
            <RTPrivateRoute>
        <Outlet />
        </RTPrivateRoute>
        </div>
        </Row>
      </Container>
  )
}
const App = () => {
  const routePath = useLocation().pathname;

  useEffect(() => {
    if (!routePath.includes('/categorydetails')) {
      onTop(); 
    }
  }, [routePath]);
  
  const onTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Routes>
        {/* Web Dashbord */}
        <Route exact path="/" element={<Homepage />}>
          <Route exact path="" element={<Landingpage />} />
          <Route exact path="/jobseeker" element={<JobSeeker />} />
          <Route exact path="/recruiter" element={<Recruiter />} />
          <Route exact path="/alljobs" element={<AllJobsCity />} />
          <Route exact path="/categorydetails" element={<CategoryDetails />} />
          <Route exact path="/legal" element={<Legal />} />
          <Route exact path="/terms" element={<TermsAndContion />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/refund" element={<RefundPolicy />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/info" element={<CandidateInfo />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/listing" element={<Listing />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/lider" element={<Slider />} />
        </Route>

        {/* Job Seeker Dashboard */}
        <Route exact path="/j_login" element={<Jslogin />} />
        <Route exact path="/js_otp" element={<JSPublicRoute><JsOtp /></JSPublicRoute>}/>
        <Route path="" element={<JSPrivateRoute><JobseekerOutLet /></JSPrivateRoute>}>
        <Route exact  path="/j_home"  element={<Jshome />} />
        <Route exact  path="/j_home/j_homedetails/:id"  element={<Jshomedetails />}/>
        <Route exact path="/j_share" element={<Jsshare />}/>
        {/* jobList */}
        <Route exact path="/j_joblist" element={  <Jsjoblist/>}/>
        <Route exact path="/j_joblist/j_jobdetails/:id" element={<Jsjobdetails />}/>
        <Route exact path="/j_joblist/j_search" element={<Jssearch/>}/>

        {/* {My Account} */}
        <Route exact path="/j_myaccount" element={<Jsmyaccount/>}/>
        <Route exact path="/j_myaccount/j_greetings" element={<Jsgreetings />}/>
        <Route exact path="/j_myaccount/j_personalinfo" element={<JsPersonalinfo />}/>
        <Route exact path="/j_myaccount/j_contact" element={<Jscontact />}/>
        <Route exact path="/j_myaccount/j_jobpreference" element={ <Jsjobpreferences />}/>
        <Route exact path="/j_myaccount/j_applyjob" element={<JsResume /> }/>
        <Route exact  path="/j_myaccount/j_applydetails/:id"  element={<Jsresumedetails />} />
        <Route exact path="/j_myaccount/j_rewards" element={ <Jsrewards />}/>
        <Route exact path="/j_myaccount/j_quizhome" element={<Jsquizhome />}/>
        <Route exact path="/j_myaccount/j_mypayment" element={ <Jsmypayment />}/>
        <Route exact path="/j_myaccount/j_myplan" element={ <Jsmyplan />}/>
        <Route exact path="/j_myaccount/j_quiz/:id" element={ <Jsquizmain />}/>
        <Route exact path="/j_myaccount/j_quizresult" element={<Jsquizresult />}/>
        <Route exact path="/j_myaccount/j_result" element={ <Jsquizresult />}/>
        </Route>
        {/* Recruiter Dashboard */}
        <Route exact path="/rt_login" element={<Rtlogin />} />
        <Route exact path="/rt_otp" element={<RTPublicRoute><RtOtp /></RTPublicRoute>} />
        <Route path="" element={<RTPrivateRoute><RecruiterOutLet /></RTPrivateRoute>}>
        <Route exact path="/rt_share" element={<Rtshare />}/>
        {/* Home */}
        <Route exact path="/rt_home" element={<Rthome />}/>
        <Route exact path="/rt_home/rt_jobinfo/:id" element={<Rtjobinfo />}/>
        {/* All Job */}
        <Route exact path="/rt_candidatelist" element={<Rtcandidatelist />}/>
        <Route exact path="/rt_candidatelist/rt_candidatedetails/:id" element={<Rtcandidatedetails />}/>
        <Route exact path="/rt_search" element={<Rtsearch />}/>
        {/* My Account */}
        <Route exact path="/rt_myaccount" element={<Rtmyaccount />}/>
        <Route exact path="/rt_myaccount/rt_alljobs" element={<RtAllJobs />}/>
        <Route exact path="/rt_myaccount/rt_alljobs/rt_alljobsdetails/:id" element={<RtAllJobsDetails />}/>
        <Route exact path="/rt_myaccount/rt_managejobs" element={<Rtmanagejobs />}/>
        <Route exact path="/rt_myaccount/rt_personalinfo" element={<RTPersonalInfo />}/>
        <Route exact path="/rt_myaccount/rt_savedcandidates" element={<Rtsavedjobs />}/>
        <Route exact path="/rt_myaccount/rt_greetings" element={<Rtgreetings />}/>
        <Route exact path="/rt_myaccount/rt_contact" element={<Rtcontact />}/>
        <Route exact path="/rt_myaccount/rt_mypayment" element={<Rtmypayment />} />
        <Route exact path="/rt_myaccount/rt_myplan" element={<Rtmyplan />} />
        </Route>
        <Route exact path="*"  element={<NoMatchRoute />}/>
      </Routes>
    </>
  );
};

export default App;
