import React from "react";
import "../../Common/common.css";

const Pagination = () => {
  return (
    <>
      <div className="d-flex">
        <button className="borderNone outlineNone bgOffWhite textLightGray px-3 py-2 mx-1 w600 font18">
          &lt;
        </button>
        <button className="borderNone outlineNone bgPrime textWhite px-3 py-2 mx-1 w600 font18">
          1
        </button>
        <button className="borderNone outlineNone bgOffWhite textGray px-3 py-2 mx-1 w600 font18">
          2
        </button>
        <button className="borderNone outlineNone bgOffWhite textGray px-3 py-2 mx-1 w600 font18">
          3
        </button>
        <button className="borderNone outlineNone bgOffWhite textPrime px-3 py-2 mx-1 w600 font18">
          &gt;
        </button>
      </div>
    </>
  );
};

export default Pagination;
