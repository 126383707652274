import React, { useState } from "react";
import "../../Common/common.css";
import "./jobpreferencecardjsd.css";

const Jobpreferencecardjsd = (props) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div
        className={`singlePreferenceBox bgWhite p-3 ${
          isActive ? "activeData" : ""
        }`}
        onClick={handleClick}
      >
        <div className="row">
          <p className="text12 textPrime2 w600 mb-0">Preference</p>
          <div className="d-flex justify-content-between">
            <b className="font14 textGray">{props?.name}</b>
            <b className="font14 textGray">{props?.salary} LPA</b>
          </div>
        </div>
        <div className="row mt-3">
          <p className="text12 textPrime2 w600 mb-0">Indurstries Name</p>
          <p className="textLightGray w600 font14">{props?.technology}</p>
        </div>
        <div className="row">
          <p className="text12 textPrime2 w600 mb-0">Preference City</p>
          <p className="textLightGray w600 font14">{props?.city}</p>
        </div>
      </div>
    </>
  );
};

export default Jobpreferencecardjsd;
