/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "../../Common/common.css";
import "./jsgreetings.css";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Myaccountheaderjsd from "../../Components/MyAccountHeaderJSD/myaccountheaderjsd";

const Jsgreetings = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioClick = (id) => {
    setSelectedOption(id);
  };
  return (
    <>
      <Container fluid className="mainPagesContainer">
        <Container>
          <Row>
            <div className="col-12">
              <div className="breadcrubsContainer bgWhite p-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link
                        to="/j_myaccount"
                        className="linkNone textLightGray w600"
                      >
                        My Account
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#" className="linkNone textGray w600">
                        Greetings
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-12 mt-4">
              <Myaccountheaderjsd />
            </div>
          </Row>
          <Row>
            <div className="col-12 mt-4">
              <div className="greetingOptionContainer w-100 bgWhite p-4">
                <div className="signleGreetingOptionbox py-3">
                  <input
                    type="radio"
                    id="1"
                    name="Greetings"
                    value=" "
                    checked={selectedOption === "1"}
                    onClick={() => handleRadioClick("1")}
                  />
                  <label
                    for="1"
                    className={`ms-3 w500 textLightGray ${
                      selectedOption === "1" ? "textPrime2" : ""
                    }`}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry"s
                  </label>
                  <br />
                </div>
                <div className="signleGreetingOptionbox py-3">
                  <input
                    type="radio"
                    id="2"
                    name="Greetings"
                    value=" "
                    checked={selectedOption === "2"}
                    onClick={() => handleRadioClick("2")}
                  />
                  <label
                    for="2"
                    className={`ms-3 w500 textLightGray ${
                      selectedOption === "2" ? "textPrime2" : ""
                    }`}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </label>
                  <br />
                </div>
                <div className="signleGreetingOptionbox py-3">
                  <input
                    type="radio"
                    id="3"
                    name="Greetings"
                    value=" "
                    checked={selectedOption === "3"}
                    onClick={() => handleRadioClick("3")}
                  />
                  <label
                    for="3"
                    className={`ms-3 w500 textLightGray ${
                      selectedOption === "3" ? "textPrime2" : ""
                    }`}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </label>
                  <br />
                </div>
                <div className="signleGreetingOptionbox py-3">
                  <input
                    type="radio"
                    id="4"
                    name="Greetings"
                    value=" "
                    checked={selectedOption === "4"}
                    onClick={() => handleRadioClick("4")}
                  />
                  <label
                    for="4"
                    className={`ms-3 w500 textLightGray ${
                      selectedOption === "4" ? "textPrime2" : ""
                    }`}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </label>
                  <br />
                </div>
                <div className="signleGreetingOptionbox py-3">
                  <input
                    type="radio"
                    id="5"
                    name="Greetings"
                    value=" "
                    checked={selectedOption === "5"}
                    onClick={() => handleRadioClick("5")}
                  />
                  <label
                    for="5"
                    className={`ms-3 w500 textLightGray ${
                      selectedOption === "5" ? "textPrime2" : ""
                    }`}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </label>
                  <br />
                </div>
                <div className="signleGreetingOptionbox py-3">
                  <input
                    type="radio"
                    id="6"
                    name="Greetings"
                    value=" "
                    checked={selectedOption === "6"}
                    onClick={() => handleRadioClick("6")}
                  />
                  <label
                    for="6"
                    className={`ms-3 w500 textLightGray ${
                      selectedOption === "6" ? "textPrime2" : ""
                    }`}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </label>
                  <br />
                </div>
                <div className="signleGreetingOptionbox py-3">
                  <input
                    type="radio"
                    id="7"
                    name="Greetings"
                    value=" "
                    checked={selectedOption === "7"}
                    onClick={() => handleRadioClick("7")}
                  />
                  <label
                    for="7"
                    className={`ms-3 w500 textLightGray ${
                      selectedOption === "7" ? "textPrime2" : ""
                    }`}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </label>
                  <br />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default Jsgreetings;
