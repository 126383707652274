/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "../../Common/common.css";
import "./rtlogin.css";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { rtLogin, setToken } from "../../redux/action/action";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";

const Rtlogin = () => {
  var [mobileno, setMobileNo] = useState("");
  var [emailid, setEmailid] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [error, setError] = useState("");
  const number = new RegExp("^[0-9]{10}$");
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const handleChangeMobile = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobileNo(e.target.value);
    }
  };
  const handleChangeEmailId = (e) => {
    setEmailid(e.target.value);
  };

  /**Validation Function */
  const validate = () => {
    let errors = [];
    if (emailid) {
      if (!emailid.match(pattern)) {
        errors.emailid = `Please Enter valid email [eg. xxx@xxx.xxx]`;
      }
    }
    if (mobileno) {
      if (!mobileno.match(number)) {
        errors.mobileno = "Enter valid Mobile number";
      }
    }

    if (!emailid) emailid = "";
    if (!mobileno) mobileno = "";
    if (emailid.length <= 0 && mobileno.length <= 0) {
      errors = "Email Or Mobile number  is required ";
    }
    return setError(errors);
  };

  /******************** API CALL START HERE **************************/
  const loginUser = (e) => {
    e.preventDefault();
    validate();
    if (
      (!emailid.match(pattern) == "" && !emailid == "") ||
      (!mobileno.match(number) == "" && !mobileno == "")
    ) {
      axiosInstance
        .post(
          `${Constants.SaveRecruitmentSignIn}`,
          mobileno === ""
            ? { emailid: emailid, deviceid: "WEB" }
            : { mobileno: mobileno, deviceid: "WEB" },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
              navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            dispatch(rtLogin(response.data.data));
            dispatch(setToken(response.data.data.accessToken));
            navigate("/rt_otp");
          }
        })
        .catch((error) => {
          return swal(`${error.response.data.massage}`, "", "error");
        });
    }
  };
  /******************** API CALL END HERE **************************/
  return (
    <>
      <Container fluid>
        <Row>
          <div className="col-12 ps-5">
            <Link className="linkNone" to="/">
            <div className="d-flex sidebarLogoWithText">
            <div
              className="ms-4  mt-2"
            >
              <img
                src="/assets/images/CityjobsLogoPNG2.png"
                className="footerIcon2"
                alt=""
              />
            </div>
          </div>
              {/* <img
                src="/assets/images/city-job-text-icon.png"
                alt=""
                className="sidebarLogoWithText"
              /> */}
            </Link>
          </div>
          <div className="col-md-6 text-center mt-5">
            <h2 className="w700 mb-5">
              Get <span className="textPrime2">Hired</span> Instantly !!!
            </h2>
            <img
              src="/assets/images/sidePhoto.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="logiFormContainer">
              <h4 className="w700 text-center pt-5">
                {/* Verify Yourself and  */}
                Recruiter
                <span className="textPrime2"> Login</span>
              </h4>
              <p className="text-center mt-4 w600 textPrime2">
                Continue with mobile number
              </p>
              <input
                type="text"
                placeholder="Enter mobile number"
                className="loginContainerInput"
                onChange={handleChangeMobile}
                name="mobileno"
                maxLength="10"
                value={mobileno}
              />
              {!mobileno.match(number) && error.mobileno ? (
                <div className="errorRtLogin text-center">{error.mobileno}</div>
              ) : null}
              <p className="text-center mt-4 w600 textPrime2">
                Or, Continue with Email ID
              </p>
              <input
                type="email"
                placeholder="Enter email id"
                className="loginContainerInput"
                onChange={handleChangeEmailId}
                name="emailid"
                value={emailid}
              />
              {!emailid.match(pattern) && error.emailid ? (
                <div className="errorRtLogin text-center">{error.emailid}</div>
              ) : null}

              {!emailid && !mobileno && error ? (
                <div className="errorRtLogin text-center">{error}</div>
              ) : null}
              <div className="text-center">
                <Link>
                  <button
                    className="bgPrime2 textWhite px-3 py-2 w600 borderNone btnLogin mt-5"
                    onClick={loginUser}
                  >
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Rtlogin;
