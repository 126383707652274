/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "../../Common/common.css";
import JobAndCity from "../../Components/JobAndCity/jobAndCity";
import DownloadModal from "../../Components/DownloadModal/downloadModal";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

const CategoryDetails = () => {
  const [show, setShow] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [city, setCity] = useState(false);
  const [skill, setSKills] = useState(false);
  const [skillsData, setSkillsData] = useState([]);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const routePath = useLocation();
  
  useEffect(() => {
    getSkills();
    getCityMaster();
  }, []);



useEffect(() => {
  onTop(true);
}, [routePath === "categorydetails"]);

const onTop = (shouldScroll) => {
  if (shouldScroll) {
    window.scrollTo(0, 0);
  }
};


  /******************** API CALL START HERE **************************/
  const getSkills = () => {
    const body = {
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetSkills}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setSkillsData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getCityMaster = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetCityMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setCityData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  /******************** API CALL END HERE **************************/

  return (
    <Container>
      {/* <div className="d-flex justify-content-center mt50">
        <SearchBar />
      </div> */}
      <Row>
        <h1 className="text-center textGray w700 mb50 mt-4">
          IT Categories Accross <span className="textPrime">India</span>
        </h1>
        {skill ? (
          <>
            {skillsData?.map((item, key) => (
              <div className="col-md-4 col-lg-3 col-6 cursor-pointer"  key={key}>
                <JobAndCity
                  onClick={() => setShow(true)}
                  name={item?.name}
                  candidate={item?.data_count ? item?.data_count : "0"}
                />
              </div>
            ))}

            <div className="col-md-3 col-6   ">
              <div className="bgOffWhite text-center jobAndCityBoxContainer">
                <Link
                  className="textGray font18 w700  bgNone borderNone linkNone"
                  onClick={() => {setSKills(false);
                    onTop(false);
                  }}
                >
                  View All
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            {skillsData?.slice(0, 7).map((item, key) => (
              <div className="col-md-4 col-lg-3 col-6 cursor-pointer" key={key}>
                <JobAndCity
                  onClick={() => setShow(true)}
                  name={item?.name}
                  candidate={item?.data_count ? item?.data_count : "0"}
                />
              </div>
            ))}
            {skillsData.length >= 8 && (
              <div className="col-md-3 col-6 ">
                <div className="bgOffWhite text-center jobAndCityBoxContainer">
                  <Link
                    className="textGray font18 w700  bgNone borderNone linkNone"
                    onClick={() => {setSKills(true);
                      onTop(false);
                    }}
                  >
                    View All
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </Row>
      <Row>
        <h1 className="text-center textGray w700 my50">
          Top IT Cities Accross <span className="textPrime">India</span>
        </h1>
        {city ? (
          <>
            {cityData?.map((item, key) => (
              <div className="col-md-4 col-lg-3 col-6 " key={key}>
                <JobAndCity
                  name={item?.city}
                  candidate={item?.data_count ? item?.data_count : "0"}
                />
              </div>
            ))}
            <div className="col-md-3 col-6 ">
              <div className="bgOffWhite text-center jobAndCityBoxContainer">
                <Link
                  className="textGray font18 w700  bgNone borderNone linkNone"
                  onClick={() =>{ setCity(false);
                    onTop(false);
                  }}
                >
                  View All
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            {cityData?.slice(0, 7).map((item, key) => (
              <div className="col-md-4 col-lg-3 col-6 " key={key}>
                <JobAndCity
                  name={item?.city}
                  candidate={item?.data_count ? item?.data_count : "0"}
                />
              </div>
            ))}
            {cityData.length >= 8 && (
              <div className="col-md-3 col-6 ">
                <div className="bgOffWhite text-center jobAndCityBoxContainer">
                  <Link
                    className="textGray font18 w700  bgNone borderNone linkNone"
                    onClick={() => {setCity(true);
                      onTop(false);
                    }}
                  >
                    View All
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </Row>
      <Row>
        <h5 className="w600 textPrime mt-5">
          Soft Skills Required in IT Enginering
        </h5>
        <p className="w500 textGary">&#8226; Communication Skills</p>
        <p className="w500 textGary">&#8226; Management Skills</p>
        <p className="w500 textGary">&#8226; Time Management</p>
        <p className="w500 textGary">&#8226; Critical Thinking</p>
        <p className="w500 textGary">&#8226; Collaboration</p>
        <p className="w500 textGary">&#8226; Attention to Detail</p>
        <p className="w500 textGary">&#8226; Accountability</p>
        <p className="w500 textGary">&#8226; Creativity</p>
        <p className="w500 textGary">&#8226; Ability to Learn</p>
      </Row>
      <Row>
        <h5 className="w600 textPrime mt-5">
          Technical Skills Required in IT Engineering
        </h5>
        <p className="w500 textGary">
          &#8226; Bachelor's Degree in Computer Science or Engineering
        </p>
        <p className="w500 textGary">
          &#8226; Knowledge of Computer Hardware Systems
        </p>
        <p className="w500 textGary">&#8226; Coding Skills</p>
        <p className="w500 textGary">
          &#8226; PHP, Ruby on Rails, Ruby or C++, C# Languages
        </p>
        <p className="w500 textGary">
          &#8226; Familiarity with General OS Systems
        </p>
        <p className="w500 textGary">&#8226; Enterprise Programming Language</p>
        <p className="w500 textGary">
          &#8226; Knowledge of LAN and Wireless Network
        </p>
        <p className="w500 textGary">
          &#8226; Troubleshooting complex software and hardware issues
        </p>
        <p className="w500 textGary">
          &#8226; Knowledge of Database and Networking Security Systems
        </p>
        <p className="w500 textGary">
          Companies can hire IT professionals across various verticals such as
          Hire PHP Developer, Hire Backend Developer, Hire Ruby on Rails
          Developer, and Hire Ruby Developer from Hirect App.
        </p>
      </Row>
      <DownloadModal show={show} setShow={setShow} />
    </Container>
  );
};
export default CategoryDetails;
