/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jshome.css";
import { Container, Pagination, Row } from "react-bootstrap";
import JobCardJSD from "../../Components/JobCardJSD/jobcardjsd";
import Slider from "react-slick";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { useSelector } from "react-redux";

const JShome = () => {
  const [advertisementData, setadevertisementData] = useState([]);
  const [jobSeekerData, setJobSeekerData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesize, Setpagesize] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const location = useLocation();
  const pageno = location.state?.data;
  
  useEffect(() => {
    setPage(pageno !== undefined ? pageno : 1)
  }, [pageno])

  useEffect(() => {
    getjobSeekerAdvertisement();
    getJobSeekerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);



  /******************** API CALL START HERE **************************/
  const getjobSeekerAdvertisement = () => {
    const body = {
      isactive: "Y",
     
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerAdvertisement}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setadevertisementData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getJobSeekerData = () => {
    const body = {
      loginuserid: login?.userid,
      favourite: "Y",
      pageable: {
        pageno: page - 1,
        pagesize:pagesize,
      },
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobSeekerData(response.data.data);
          Setpagesize(response.data.pageable.pagesize)
          setPageCount(response.data.pageable.totalPages);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };

  /******************** API CALL END HERE **************************/

  /**  ADVERTISEMENT SLIDER **/
  const advertisementSlider = {
    infinite: advertisementData.length >= 3,
    arrows: advertisementData.length >= 3,
    speed: 500,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: advertisementData.length >= 3,
    arrows: advertisementData.length >= 3,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /*** PAGINATION CODE FROM BOOTSTRAP ***/
  // HANDLE NEXT PAGE
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };

  /**  HANDLE PREVIOUS **/
  const handlePrevious = () => {
    if (page === 1) return page;
    setPage(page - 1);
  };

  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                <div className="col-md-12 coustom-slider">
                    <Slider {...advertisementSlider} className="slike1">
                      {advertisementData?.map((item, index) => (
                        <div key={index}>
                          <video
                          controlsList="nodownload"
                            src={item?.videourl}
                            controls
                            loop
                            className="videodata"
                            key={index}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </Row>
                <Row>
                  <p className="mb-0 w600 textGray font18 mt-5">
                    Favourite Jobs
                  </p>
                  {jobSeekerData?.length > 0 &&
                    jobSeekerData?.map((item, index) => (
                      <div className="col-md-6 mt-3" key={index}>
                        <Link
                          to={`/j_home/j_homedetails/${item?.postjobid}`}
                          state={{ data: page }}
                          className="linkNone"
                          key={index}
                        >
                          <JobCardJSD
                            key={index}
                            jobtitle={item?.jobtitle}
                            education={item?.education}
                            companyName={item?.companyname}
                            location={item?.location}
                            Icons={
                              item?.favourite
                                ? "font20 text-danger mx-3"
                                : "font20 textLightGray mx-3"
                            }
                            firstName={item?.firstname}
                            lastName={`${item?.lastname} \u2022 ${item?.myposition}`}
                            experience={item?.requiredexperience}
                          />
                        </Link>
                      </div>
                    ))}
                </Row>
                {jobSeekerData?.length >0 && (
                  <Row>
                    <div className="mt-4 ms-auto d-flex justify-content-end paginationBox">
                    <Pagination>
                        {pageCount < 10 ? (
                          <>
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            {Array(pageCount)
                              .fill(null)
                              .map((_, index) => (
                                <Pagination.Item
                                  key={index}
                                  active={page === index + 1 ? true : false}
                                  onClick={() => {
                                    setPage(index + 1);
                                  }}
                                >
                                  {index + 1}
                                </Pagination.Item>
                              ))}
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === pageCount}
                            />
                          </>
                        ) : (
                          <>
                            <Pagination.First onClick={() => setPage(1)} />
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            <Pagination.Item
                              onClick={() => setPage(1)}
                              active={page === 1}
                            >
                              {1}
                            </Pagination.Item>
                            {page > 2 && <Pagination.Ellipsis />}
                            {Array.from(
                              { length: pageCount - 2 },
                              (_, index) => {
                                const pageNumber = index + 2;
                                if (
                                  pageNumber > 1 &&
                                  pageNumber < pageCount &&
                                  Math.abs(pageNumber - page) > 2
                                ) {
                                  return null;
                                }
                                return (
                                  <Pagination.Item
                                    key={index}
                                    active={page === pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Pagination.Item>
                                );
                              }
                            )}

                            {page < pageCount - 3 && <Pagination.Ellipsis />}

                            <Pagination.Item
                              onClick={() => setPage(pageCount)}
                              active={page === pageCount}
                            >
                              {pageCount}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === pageCount}
                            />
                            <Pagination.Last
                              onClick={() => setPage(pageCount)}
                            />
                          </>
                        )}
                      </Pagination>
                    </div>
                  </Row>
                )}
              </Container>
            </Container>
    </>
  );
};
export default JShome;
