/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import "../../Common/common.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./rthome.css";
import { Container, Pagination, Row } from "react-bootstrap";
import Slider from "react-slick";
import Postedjobsrt from "../../Components/PostedJobsRT/postedjobsrt";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { useSelector } from "react-redux";

const Rthome = () => {
  const [advertisementData, setadevertisementData] = useState([]);
  const [postJobData, setPostJobData] = useState([]);

  const [page, setPage] = useState(1);
  const [pagesize, Setpagesize] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const user = useSelector((state) => state?.user);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const location = useLocation();
  const pageno = location.state?.data;

  useEffect(() => {
    setPage(pageno !== undefined ? pageno : 1)
  }, [pageno])

  useEffect(() => {
    getRecruitmentAdvertisement();
    getRecruitmentPostJob();
  }, [page]);


  /******************** API CALL START HERE **************************/
  const getRecruitmentAdvertisement = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
      }
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentAdvertisement}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setadevertisementData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };

  const getRecruitmentPostJob = () => {
    const body = {
      userid: user?.userid,
      isactive: "Y",
      pageable: {
        pageno: page - 1,
        pagesize: pagesize,
      },
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPostJobData(response.data.data);
          Setpagesize(response.data.pageable.pagesize);
          setPageCount(response.data.pageable.totalPages);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };
  /******************** API CALL END HERE **************************/

  /**  VIDIOS SLIDER **/
  const advertisementSlider = {
    infinite: advertisementData.length >= 3,
    arrows: advertisementData.length >= 3,
    speed: 500,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /*** PAGINATION CODE FROM BOOTSTRAP ***/
  // HANDLE NEXT PAGE
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
  };

  /**  HANDLE PREVIOUS **/
  const handlePrevious = () => {
    if (page === 1) return page;
    setPage(page - 1);
  };
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-md-12 coustom-slider">
                    <Slider
                      {...advertisementSlider}
                      className="imgTextComponenetSlider"
                    >
                      {advertisementData?.map((item, index) => (
                        <div key={index} className="col-md-6">
                          <video
                          controlsList="nodownload"
                            src={item?.videourl}
                            controls
                            loop
                            className="videodata"
                          ></video>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </Row>
                <Row>
                  <p className="mt-4 mb-0 w600 textGray mt-5 font18">
                    Job Posted By You
                  </p>
                  {postJobData?.length > 0 &&
                    postJobData?.map((item, index) => (
                      <div className="col-md-6 mt-4 " key={index}>
                        <Link
                         to={`/rt_home/rt_jobinfo/${item?.postjobid}`} state={{ data: page }}
                          className="linkNone"
                        >
                          <Postedjobsrt
                            key={index}
                            companyName={item?.companyname}
                            metadata=""
                            noOfApplication="none"
                            name={item?.jobtitle}
                            salary={`${user.currency} ${item?.salary}`}
                            education={item?.education}
                            location={item?.location}
                            experience={item?.requiredexperience}
                            statusName="Status"
                            status={item?.status}
                          />
                        </Link>
                      </div>
                    ))}
                </Row>
                {postJobData?.length > 0 && (
                <Row>
                  <div className="mt-4 ms-auto d-flex justify-content-end paginationBox">
                  <Pagination>
                        {pageCount < 10 ? (
                          <>
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            {Array(pageCount)
                              .fill(null)
                              .map((_, index) => (
                                <Pagination.Item
                                  key={index}
                                  active={page === index + 1 ? true : false}
                                  onClick={() => {
                                    setPage(index + 1);
                                  }}
                                >
                                  {index + 1}
                                </Pagination.Item>
                              ))}
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === pageCount}
                            />
                          </>
                        ) : (
                          <>
                            <Pagination.First onClick={() => setPage(1)} />
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            <Pagination.Item
                              onClick={() => setPage(1)}
                              active={page === 1}
                            >
                              {1}
                            </Pagination.Item>
                            {page > 2 && <Pagination.Ellipsis />}
                            {Array.from(
                              { length: pageCount - 2 },
                              (_, index) => {
                                const pageNumber = index + 2;
                                if (
                                  pageNumber > 1 &&
                                  pageNumber < pageCount &&
                                  Math.abs(pageNumber - page) > 2
                                ) {
                                  return null;
                                }
                                return (
                                  <Pagination.Item
                                    key={index}
                                    active={page === pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Pagination.Item>
                                );
                              }
                            )}

                            {page < pageCount - 3 && <Pagination.Ellipsis />}

                            <Pagination.Item
                              onClick={() => setPage(pageCount)}
                              active={page === pageCount}
                            >
                              {pageCount}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === pageCount}
                            />
                            <Pagination.Last
                              onClick={() => setPage(pageCount)}
                            />
                          </>
                        )}
                      </Pagination>
                  </div>
                </Row>
                )}
              </Container>
            </Container>
    </>
  );
};
export default Rthome;
