/* eslint-disable no-array-constructor */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jsjobdetails.css";
import { Container, Row } from "react-bootstrap";
import { AiTwotoneHeart } from "react-icons/ai";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const Jsjobdetails = () => {
  const [recruitmentData, setRecruitmentData] = useState([]);
  var skillName = new Array();
  skillName = recruitmentData?.map((item) => {
    return item.skillname?.split(",");
  });
  const { id } = useParams();
  const Token = useSelector((state) => state?.token);
  const login = useSelector((state) => state?.login);
  const navigate = useNavigate();
  const location = useLocation();
  const pageno = location?.state?.data;
  const selectedValue = location?.state?.selectedvalue;
  const postjobid = location?.state?.postjobid;
  const jobtypename1 = location.state?.jobtypename;
  const expactedindustryname1 = location.state?.expactedindustryname;
  const requiredexperience1 = location.state?.requiredexperience;
  const companycity1 = location.state?.companycity;
  const [defaultImg, setDefaultImg] = useState([]);

  useEffect(() => {
    getRecruitmentData();
    getHomePageMaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, postjobid]);

  /******************** API CALL START HERE **************************/
  const getRecruitmentData = () => {
    if (id !== "null") {
      const body = {
        postjobid: id || postjobid,
        loginuserid: login?.userid,
      };
      axiosInstance
        .post(`${Constants.GetRecruitmentData}`, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            setRecruitmentData(response.data.data);
          } else {
            swal(`${response.data.error}`, "", "error");
          }
        })
        .catch((error) => {
          if (
            error.response.data?.status ===
            Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          }
        });
    }
  };

  const getHomePageMaster = () => {
    const body = {
      groupname: "Profile_image",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setDefaultImg(response.data.data?.[0]?.imageurl);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  /******************** API CALL END HERE **************************/

  return (
    <>
      <Container fluid className="mainPagesContainer">
        {recruitmentData.length > 0 &&
          recruitmentData?.map((item, key) => (
            <Container key={key}>
              <div key={key}>
                <Row>
                  <div className="col-12">
                    <div className="d-flex align-items-baseline">
                      <Link
                        to={"/j_joblist"}
                        state={{
                          data: pageno,
                          selectedvalue: selectedValue,
                          jobtypename: jobtypename1,
                          companycity: companycity1,
                          expactedindustryname: expactedindustryname1,
                          requiredexperience: requiredexperience1,
                        }}
                      >
                        <h4 className="mb-0 textPrime2 w600">
                          <MdOutlineKeyboardBackspace
                            style={{ width: "50px", height: "35px" }}
                          />
                        </h4>
                      </Link>
                      <h4 className="mb-0 textPrime2 w600 font18">
                        {item?.jobtitle}
                      </h4>
                    </div>
                    <div className="jobDetailsHeaderContainer bgWhite p-4 d-flex justify-content-between mt-3 w-100">
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            item?.profileimage === "" || null || "null"
                            ? defaultImg
                            :  item?.profileimage
                          }
                          className="userImage rounded-circle"
                          alt=""
                        />
                        <div className="ms-3">
                          <h4 className="mb-0 w600 font18">
                            {item?.companyname}
                          </h4>
                          <p className="mb-0 w500 text-secondary font16">
                            {item?.industries} &nbsp;&bull;{item?.companysize}
                          </p>
                        </div>
                      </div>
                      <div>
                        <AiTwotoneHeart
                          className={
                            item?.favourite === "Y"
                              ? "font20 text-danger activeLink mx-3"
                              : "font20 textLightGray mx-3"
                          }
                        />
                        <div></div>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12 mt-4">
                    <div
                      className="myAccountHeaderMainContainer bg-white px-4"
                      key={key}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            item?.companyprofile
                              ? item?.companyprofile
                              : defaultImg
                          }
                          className="userImage rounded-circle"
                          alt=""
                        />
                        <div className="ms-3">
                          <h3 className="mb-0 w600 textPrime2 font18">
                            {item?.firstname} {item?.lastname}
                          </h3>
                          <p className="textLightGray w500 font16" key={key}>
                            {item?.myposition}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="p-4">
                  <div>
                    <Row>
                      <div className="d-flex flex-wrap">
                        <div className="me-5 mt-4">
                          <p className="mb-0 textGray w600 font16">
                            Experiences
                          </p>
                          <p className="mb-0 textPrime2 w600 font14">
                            {item?.requiredexperience}
                          </p>
                        </div>
                        <div className="me-5 mt-4">
                          <p className="mb-0 textGray w600 font16">Education</p>
                          <p className="mb-0 textPrime2 w600 font14">
                            {item?.education}
                          </p>
                        </div>
                        <div className="me-5  mt-4">
                          <p className="mb-0 textGray w600 font16">Location</p>
                          <p className="mb-0 textPrime2 font14 w600">
                            {item?.location}
                          </p>
                        </div>
                      </div>
                    </Row>
                    <hr className="opacity50" />
                    <Row>
                      <p className="mt-4 mb-0 w600 font16">Description</p>
                      <p className="mt-1 text-secondary textJustify font14">
                        {item?.description}
                      </p>
                    </Row>
                    <hr className="opacity50" />
                    <Row>
                      <div className="col-md-6">
                        <p className="mt-4 w600 font16">Skills</p>
                        {skillName?.map((item) =>
                          item?.map((item, key) => (
                            <button
                              className="jobdetails_skillBTN px-3 py-1 bgWhite me-2 mt-2 font14"
                              key={key}
                            >
                              {item}
                            </button>
                          ))
                        )}
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Container>
          ))}
      </Container>
    </>
  );
};
export default Jsjobdetails;
