import { React, useEffect, useState } from "react";
import "./sidebarrt.css";
import "../../Common/common.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/action/action";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";

const Sidebarrt = () => {
  const [data, setData] = useState(localStorage.getItem("sideBar") === "true" && window.innerWidth >= 768);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Token = useSelector((state) => state?.token);
  const [homeLogoName, setHomeLogoName] = useState([])
  const [homePageMaster, setHomepageMaster] = useState([])
  const [sidelogo, setSidelogo] = useState([]);

  const handleCollaps = () => {
    const newData = !data;
    setData(newData);
    localStorage.setItem("sideBar", newData);
  };

  const logOut = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    getHomeLogoName();
    getHomePageMaster();
    getNavLogo();
  }, [])

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768) {
        setData(false);
      } else {
        setData(localStorage.getItem("sideBar") === "true");
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //SIDEBAR MENU ITEMS OBJECT
  const menuItem = [
    {
      path: "/rt_home",
      name: "Home",
    },
    {
      path: "/rt_candidatelist",
      name: "Hire",
    },
    {
      path: "/rt_myaccount",
      name: "My Account",
    },
    {
      path: "/rt_share",
      name: "Share",
    },
    {
      path: "/",
      name: "Logout",
      onClick: logOut,
    },
  ];
  /******************** API CALL START HERE **************************/

  const getHomeLogoName = () => {
    const body = {
      groupname: "Home_Nav_Logo",
      isactive: "Y",

    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomeLogoName(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getNavLogo = () => {
    const body = {
      groupname: "Home_Logo",
      isactive: "Y",

    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setSidelogo(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Recruiter_Sidebar",
      isactive: "Y",

    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALL END HERE **************************/

  const commonData = menuItem?.map((item, index) => {
    const matchedItem = homePageMaster?.find(menu => menu?.text === item?.name);
    if (matchedItem) {
      return {
        key: index,
        name: item?.name,
        imageurl: matchedItem?.imageurl,
        path: item?.path,
        onClick: item?.onClick,
      };
    }

    // handle case when there is no match
  });


  return (
    <>
      <div className="sideBarMainCotainer">
        <div
          className="sideBarContent"
          style={{ width: data === true ? "250px" : "58px" }}
        >
          <div className="d-flex align-content-center">
            {sidelogo?.map((item, index) => (
              <div key={index} className="link-icon" style={{ display: data ? "none" : "block" }}>
                <img
                  src={item?.imageurl} width="50px" height="50px" alt="" />
              </div>
            ))}
            {homeLogoName?.map((item, index) => (
              <div
                key={index}
                style={{ display: data ? "block" : "none" }}
                className="ms-4 mt-2"
              >
                <img

                  src={item?.imageurl}
                  className="footerIcon2"
                  alt=""
                />
              </div>
            ))}
          </div>
          {/* <img
            src="/assets/images/city-job-text-icon.png"
            className="sidebarLogoWithText mb-4"
            alt=""
          /> */}
          <br />
          {commonData[0] !== undefined && commonData?.map((item, key) => (
            <div className="cursorPointer d-flex" key={key}>
              <NavLink
                to={item?.path}
                key={key}
                className="link"
                activeclassname="activeLink"
                onClick={item?.onClick}
              >
                <div className="link-icon"><img src={item?.imageurl} width="25px" height="25px" alt="" /></div>
                <div
                  style={{ display: data ? "block" : "none" }}
                  className="linkNone textGray ms-4 font18 w600 link-text cursorPointer"
                >
                  {item?.name}
                </div>
              </NavLink>
              <div> </div>
            </div>
          ))}
        </div>
        <button
          className="collapsButton borderNone bgWhite textPrime2"
          onClick={handleCollaps}
        >
          &#x2630;
        </button>
      </div>
    </>
  );
};

export default Sidebarrt;
