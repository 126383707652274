import React from "react";
import "./buttons.css";
import "../../Common/common.css";

const Buttons = (props) => {
  return (
    <button className={`bg${props.bgColor} text${props.textColor} ${props.hover} buttonCommon borderNone w500`} onClick={props.onClick} type={props.type} >
      {props.content}
    </button>
  )
}
export default Buttons

