/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "../../Common/common.css";
import Download from "../../Components/Download/download";
import KeyFeture from "../../Components/KeyFeture/keyFeture";
import "./jobSeeker.css";
import Accordion from "react-bootstrap/Accordion";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import Slider from "react-slick";
import VideoText from "../../Components/VideoText/videoText";
import DownloadModal from "../../Components/DownloadModal/downloadModal";
import { useSelector } from "react-redux";
import JobSeekerPlans from "../Pricing/jobSeekerPlans";
import { useNavigate } from "react-router-dom";

const JobSeeker = () => {
  const [show, setShow] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [videodetailData, setVideodetailData] = useState([]);
  const [jobSeekerDreamJob, setJobSeekerDreamJob] = useState([]);
  const [key_Features, setKey_Features] = useState([])
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getVideoDetail();
    getFaqDetails();
    getJobSeekerDreamJob();
    getJobseekerKeyFeatures();
  }, []);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle the open/close state of the clicked accordion item
  };
  /******************** API CALL START HERE **************************/
  const getVideoDetail = () => {
    const body = {
      pagecode: "JOBSEEKER_PAGE",
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetVideoDetail}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setVideodetailData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });

  };

  const getFaqDetails = () => {
    const body = {
      applicableto: "Job Seeker",
    };
    axiosInstance
      .post(`${Constants.GetFAQ}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setFaqData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })

  };
 
  const getJobSeekerDreamJob = () => {
    const body = {
      groupname: "JobSeeker_Dream_Job_Steps",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobSeekerDreamJob(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getJobseekerKeyFeatures = () => {
    const body = {
      groupname: "JobSeeker_Key_Features",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setKey_Features(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALL END HERE **************************/

  //VIDEOS SLIDER
  const videoSlider = {
    rows: 1,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
  };

  return (
    <Container fluid>
      <Download />
      <Container className="my100">
        <Slider {...videoSlider}>
          {videodetailData?.map((item, key) => (
            <VideoText
              key={key}
              video={item?.videourl}
              Heading={item?.header}
              Content={item?.description}
            />
          ))}
        </Slider>
      </Container>
      <div className="bgOffWhite text-center featuresKey p-3">
        <h1 className="w700 textGray ">
          How To Get Your <span className="textPrime">Dream</span> Job
        </h1>
        <div className="seekerImageContainer mt50 mb-3">
        {jobSeekerDreamJob?.map((item, index) => (
          <div className="oneStep" key={index}>
            <img src={item?.imageurl} alt="" className="dreamImage" />
            <p className="w500 textGray mt-2">{item?.text}</p>
          </div>
        ))}
        </div>
        <button
        className="bgPrime textWhite greenHover buttonCommon borderNone w500 mb-4"
          onClick={() => {
            setShow(true);
          }}
        >Get Hired</button>
        <DownloadModal show={show} setShow={setShow} />
      </div>
      <Container className="my100">
        <div className="text-center py50 featuresKey">
          <h1 className="w700 textGray mb-5">
            Our Key <span className="textPrime">Features</span>
          </h1>
          <Row>
          {key_Features?.map((item,index)=>(
            <div className="col-md-4 d-flex justify-content-center" key={index}>
              <KeyFeture
              content={item?.text}
              path={item?.imageurl}
                class="keyImage"
              />
            </div>
               ))}
          </Row>
        </div>
      </Container>
      <Container>
        <div className="my-4">
          <JobSeekerPlans />
        </div>
      </Container>
      <Container fluid className="py50">
        <h1 className="w700 textPrime text-center">
          FAQ<span className="textGray">s</span>
        </h1>
        {faqData?.map((item, index) => (
          <div className="faq_accordian" key={index}>
            <Accordion activeKey={openIndex}>
              <Accordion.Item eventKey={index} >
                <Accordion.Header onClick={() => handleToggle(index)}>{item?.question}</Accordion.Header>
                <Accordion.Body>
                  <p className="mb-0 textJustify">{item?.answer}</p>
                </Accordion.Body>
              </Accordion.Item>
              <hr className="mx-1" />
            </Accordion>
          </div>
        ))}
      </Container>
    </Container>
  );
};
export default JobSeeker;
