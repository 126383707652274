/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./rtmanagejobs.css";
import { Container, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Postedjobsrt from "../../Components/PostedJobsRT/postedjobsrt";
import MyaccountheaderRt from "../../Components/MyAccountHeaderRT/myaccountheaderrt";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Rtmanagejobs = () => {
  const [statusData, setStatusData] = useState([]);
  const [status, setStatus] = useState([]);
  const user = useSelector((state) => state?.user);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getRecruitmentStatus();
  }, []);

  /******************** API CALL START HERE **************************/
  const getRecruitmentStatus = () => {
    const body = {
      userid: user?.userid,
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentStatus}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setStatusData(response.data.data);
          setStatus(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });
       
  };
  /******************** API CALL END HERE **************************/

  /** SELECT TABS FUNCTION CALL **/
  const handleSelect = (key) => {
    if (key === "ALL") {
      const allData = statusData?.filter((item) => item);
      setStatus(allData);
    } else if (key === "OPEN") {
      const allData = statusData?.filter((item) => item?.status === "OPEN");
      setStatus(allData);
    } else if (key === "CLOSED") {
      const allData = statusData?.filter((item) => item?.status === "CLOSED");
      setStatus(allData);
    } else if (key === "REJETED") {
      const allData = statusData?.filter((item) => item?.status === "REJETED");
      setStatus(allData);
    }
  };

  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/rt_myaccount"
                              className="linkNone textLightGray w600"
                            >
                              My Account
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              Manage Jobs
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12 mt-4">
                    <MyaccountheaderRt />
                  </div>
                </Row>
                <Row className="manageJobBox">
                  <Tabs
                    defaultActiveKey="ALL"
                    id="fill-tab-example"
                    className="my-5"
                    fill
                    onSelect={(e) => handleSelect(e)}
                  >
                    <Tab eventKey="ALL" title="All">
                      <Row>
                        {status?.map((item, key) => (
                          <div className="col-md-6 mb-4" key={key}>
                            <Postedjobsrt
                              metadata=""
                              noOfApplication="none"
                              education={item?.education}
                              location={item?.location}
                              experience={item?.requiredexperience}
                            />
                          </div>
                        ))}
                      </Row>
                    </Tab>
                    <Tab eventKey="OPEN" title="Opening">
                      <Row>
                        {status?.map((item, key) => (
                          <div className="col-md-6 mb-4" key={key}>
                            <Postedjobsrt
                              metadata=""
                              noOfApplication="none"
                              education={item?.education}
                              location={item?.location}
                              experience={item?.requiredexperience}
                            />
                          </div>
                        ))}
                      </Row>
                    </Tab>
                    <Tab eventKey="CLOSED" title="Closed">
                      <Row>
                        {status?.map((item, key) => (
                          <div className="col-md-6 mb-4" key={key}>
                            <Postedjobsrt
                              metadata=""
                              noOfApplication="none"
                              education={item?.education}
                              location={item?.location}
                              experience={item?.requiredexperience}
                            />
                          </div>
                        ))}
                      </Row>
                    </Tab>
                    <Tab eventKey="REJETED" title="Rejected">
                      <Row>
                        {status?.map((item, key) => (
                          <div className="col-md-6 mb-4" key={key}>
                            <Postedjobsrt
                              metadata=""
                              noOfApplication="none"
                              education={item?.education}
                              location={item?.location}
                              experience={item?.requiredexperience}
                            />
                          </div>
                        ))}
                      </Row>
                    </Tab>
                  </Tabs>
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Rtmanagejobs;
