import React from "react";

const Jobcard = (props) => {
  return (
    <>
      <div className="jobcardMainContainer w-100 h-100 bgWhite p-3">
        <div className="">
          <div>
            <p className="text12 textPrime2 w600 mb-0 text-break">{props.Title}</p>
          </div>
          <div className="d-flex justify-content-between">
            <b className="textGray font14 w-75">{props?.jobtitle}</b>
            <b className="textGray font14 w-35 text-end">
              {props.startSalary} {props?.yearsAndsalary} {props.endSalary}
            </b>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <p className="text12 textPrime2 mt-3 w600 mb-0">
              {props.eductionTitle}
            </p>
            <p className="w600 textLightGray font14 ">
              {props.educationAndInstitute}
            </p>
          </div>
          <div className="col-sm-6 mt-3 " style={{display: props.divnone}}>
            <p className="text12 textPrime2 w600 mb-0">
              {props.locationTitle}
            </p>
            <p className="w600 textLightGray font14">{props.location}</p>
          </div>
          <div className={`${ props.experienceCol}`} >
            <p className="text12 textPrime2 w600 mb-0">{props.nameTitle}</p>
            <div className="d-flex align-items-center">
              <p className="w600 textLightGray font14">{props.icon}</p>
              <p className="w600 textLightGray font14 text-break">{props.Name}</p>
            </div>
          </div>
          <div className="col-sm-6" style={{display: props.experienceNone}}>
            <p className="text12 textPrime2 w600 mb-0">{props.experience}</p>
            <p className="w600 textLightGray">{props?.experienceName}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobcard;
