/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import { Container, Pagination, Row } from "react-bootstrap";
import Postedjobsrt from "../../Components/PostedJobsRT/postedjobsrt";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./rtalljobs.css";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function RtAllJobs() {
  const [recruitmentData, setRecruitmentData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [status, setStatus] = useState([]);
  const [allJobData, setAllJobData] = useState([]);
  const location = useLocation();
  const pageno = location.state?.data;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const user = useSelector((state) => state?.user);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [select, setSelect] = useState("ALL");
  const selectedValue = location.state?.selectedvalue;
  const statusValue = location.state?.status;
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [filteredProducts, setfilteredProducts] = useState([]);

  useEffect(() => {
    if (selectedValue !== undefined) {
      setSelect(selectedValue);
    }
    if (statusValue !== undefined) {
      setStatus(statusValue);
    }
  }, [selectedValue, statusValue]);

  useEffect(() => {
    const lowerSearchField = searchField.toLowerCase();
    let filteredProducts1 = filteredStatus;

    if (searchField != "") {
      filteredProducts1 = filteredStatus.filter((post) =>
        [
          post?.functionalareaname,
          post?.jobtitle,
          post?.location,
          post?.salary,
          post?.jobtypename,
          post?.companyname,
          post?.education,
          post?.description,
          post?.requiredexperience,
        ].some((field) => field.toLowerCase().includes(lowerSearchField))
      );
      setPage(1)
    } else if (pageno !== undefined) {
      setPage(pageno);
    }
    setfilteredProducts(filteredProducts1);
  }, [searchField, filteredStatus, pageno]);

  const handleClick = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    getRecruitmentStatus();
  }, [selectedValue, page, searchField]);

  useEffect(() => {
    const filterFunctions = {
      ALL: (item) => true,
      OPEN: (item) => item.status === "OPEN",
      CLOSED: (item) => item.status === "CLOSED",
      PENDING: (item) => item.status === "PENDING",
      REJECTED: (item) => item.status === "REJECTED",
    };
    const filteredData = status.filter(filterFunctions[select] || (() => true));
    setFilteredStatus(filteredData);
    const lowerSearchField = searchField.toLowerCase();
    const filteredProducts1 = filteredData?.filter((post) =>
      [
        post?.functionalareaname,
        post?.jobtitle,
        post?.location,
        post?.salary,
        post?.jobtypename,
        post?.companyname,
        post?.education,
        post?.description,
        post?.requiredexperience,
      ].some((field) => field.toLowerCase().includes(lowerSearchField))
    );
    setfilteredProducts(filteredProducts1);
  }, [select, status]);

  useEffect(() => {
    const pagedatacount = Math.ceil(filteredProducts?.length / 6);
    setPageCount(pagedatacount);
    if (page) {
      const pagesize = 6
      const skip = pagesize * page;
      const dataskip = filteredProducts?.slice(
        page === 1 ? 0 : skip - pagesize,
        skip
      );
      setAllJobData(dataskip);
    }
   
  }, [page, filteredStatus,searchField]);

  /******************** API CALL START HERE **************************/
  const getRecruitmentStatus = () => {
    const body = {
      userid: user?.userid,
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentStatus}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          if (statusValue !== undefined) {
            setStatus(statusValue);
          } else {
            setStatus(response.data.data);
          }
          setRecruitmentData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  /******************** API CALL END HERE **************************/

  /**  HANDLE CHANGE FUNCTION  CALL **/
  const handalechange = (e) => {
    const value = e.target.value;
    setPage(1);
    setSelect(value);
    const filterFunctions = {
      ALL: (item) => item?.status,
      OPEN: (item) => item.status === "OPEN",
      CLOSED: (item) => item.status === "CLOSED",
      PENDING: (item) => item.status === "PENDING",
      REJECTED: (item) => item.status === "REJECTED",
    };

    const filteredData = recruitmentData?.filter(
      filterFunctions[value] || (() => true)
    );
    setStatus(filteredData);
    setSearchField("")
    const newLocation = {
      ...location,
      state: null,
    };
    navigate(newLocation);
  };

  /*** PAGINATION CODE FROM BOOTSTRAP ***/
  // HANDLE NEXT PAGE
  const handleNext = () => {
    if (page === pageCount) return page;
    setPage(page + 1);
    const newLocation = { ...location, state: null };
    navigate(newLocation);
  };

  /**  HANDLE PREVIOUS **/
  const handlePrevious = () => {
    if (page === 1) return page;
    setPage(page - 1);
    const newLocation = { ...location, state: null };
    navigate(newLocation);
  };

  const handleClearBtn = () => {
    setSearchField("");
  };

  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="d-flex align-items-baseline">
                    <Link to="/rt_myaccount">
                      <h4 className="mb-0 textPrime2 w600">
                        <MdOutlineKeyboardBackspace
                          style={{ width: "50px", height: "35px" }}
                        />
                      </h4>
                    </Link>
                    <h4 className="mb-0 textPrime2 w600 font18">Manage Job</h4>
                  </div>
                  <div className="d-flex justify-content-betweem  mt-3">
                    <select
                      className={`dropdownBorder w-auto px-2 py-2 cardss borderNone mb-3 ${
                        isActive ? "activeData" : ""
                      }`}
                      value={select}
                      name="select"
                      onChange={handalechange}
                      onClick={handleClick}
                    >
                      <option value={"ALL"} selected>
                        All
                      </option>
                      <option value={"OPEN"}>Opening</option>
                      <option value={"CLOSED"}>Closed</option>
                      {/* <option value={"PENDING"}>Pending</option> */}
                      <option value={"REJECTED"}>Rejected</option>
                    </select>

                    <div className="searchContainer">
                    <div class="input-group">
                    <input
                        type="text"
                        autoComplete="off"
                        name="searchField"
                        value={searchField}
                        onChange={(e) => {
                          setSearchField(e.target.value);
                          const newLocation = { ...location, state: null };
                          navigate(newLocation);
                        }}
                        className="borderNone outlineNone bgWhite cardss ps-2 pe-5"
                        id="example-search-input"

                        placeholder="Search..."
                      />
            
                    {/* <button class="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border ms-n5" type="button">
                        <i class="fa fa-search"></i>
                    </button>
              */}
          
                   
               <span class="input-group-append">
                      <AiOutlineCloseCircle
                       type="button"
                        className="searchIcon   bg-white  border-start-0 border-bottom-0 border-none ms-n5"
                        onClick={handleClearBtn}
                      />
                         </span>
                    </div>
                         </div>
                  </div>
                  {allJobData?.length > 0 &&
                    allJobData?.map((item, key) => (
                      <div className="col-md-6 mb-4" key={key}>
                        <Link
                          to={`/rt_myaccount/rt_alljobs/rt_alljobsdetails/${item?.postjobid}`}
                          state={{
                            data: page,
                            selectedvalue: select,
                            status: status,
                          }}
                          className="linkNone"
                        >
                          <Postedjobsrt
                            companyName={item?.companyname}
                            applicantsNumber={item?.applaycount}
                            name={item?.jobtitle}
                            salary={`${user.currency} ${item?.salary}`}
                            education={item?.education}
                            location={item?.location}
                            experience={item?.requiredexperience}
                          />
                        </Link>
                      </div>
                    ))}
                </Row>

                <Row>
                  {allJobData?.length > 0 && (
                    <div className="mt-4 ms-auto d-flex justify-content-end paginationBox">
                      <Pagination>
                        {pageCount < 10 ? (
                          <>
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            {Array(pageCount)
                              .fill(null)
                              .map((_, index) => (
                                <Pagination.Item
                                  key={index}
                                  active={page === index + 1 ? true : false}
                                  onClick={() => {
                                    setPage(index + 1);
                                  }}
                                >
                                  {index + 1}
                                </Pagination.Item>
                              ))}
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === pageCount}
                            />
                          </>
                        ) : (
                          <>
                            <Pagination.First onClick={() => setPage(1)} />
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            <Pagination.Item
                              onClick={() => setPage(1)}
                              active={page === 1}
                            >
                              {1}
                            </Pagination.Item>
                            {page > 2 && <Pagination.Ellipsis />}
                            {Array.from(
                              { length: pageCount - 2 },
                              (_, index) => {
                                const pageNumber = index + 2;
                                if (
                                  pageNumber > 1 &&
                                  pageNumber < pageCount &&
                                  Math.abs(pageNumber - page) > 2
                                ) {
                                  return null;
                                }
                                return (
                                  <Pagination.Item
                                    key={index}
                                    active={page === pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Pagination.Item>
                                );
                              }
                            )}

                            {page < pageCount - 3 && <Pagination.Ellipsis />}

                            <Pagination.Item
                              onClick={() => setPage(pageCount)}
                              active={page === pageCount}
                            >
                              {pageCount}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === pageCount}
                            />
                            <Pagination.Last
                              onClick={() => setPage(pageCount)}
                            />
                          </>
                        )}
                      </Pagination>
                    </div>
                  )}
                </Row>
              </Container>
            </Container>
    </>
  );
}
