import React from "react";
import { Container, Row } from "react-bootstrap";
import Download from "../../Components/Download/download";
import "../../Common/common.css";
import "./candidateInfo.css";

const CandidateInfo = () => {
  return (
    <>
      <Container>
        <Row>
          <div className="mt100 namContainer">
            <img src="/assets/images/dp.png" alt="" />
            <div className="ms-3">
              <h4 className="w600 textGray mb-0">Alex Robert</h4>
              <p className="mb-0 font14 textLightGray w500">
                junior softwere engineer
              </p>
            </div>
          </div>
        </Row>
        <Row className="my50">
          <p className="textGray w600 mb-0">About Me</p>
          <p className="textLightGray w400">
            An automotive engineer by profession and a technology enthusiast as
            a person, I have gained experience in automotive feature innovation,
            research and development especially in the field of ADAS and Active
            Safety Systems.
          </p>
        </Row>
        <Row>
          <div className="candidateInfoGroup">
            <div className="me-0 me-sm-5 mb-3 mb-sm-0">
              <p className="textGray w600 mb-0">Experiences</p>
              <p className="textPrime w600 mb-0">7 Years</p>
            </div>
            <div className="me-0 me-sm-5 mb-3 mb-sm-0">
              <p className="textGray w600 mb-0">Education</p>
              <p className="textPrime w600 mb-0">B.Tech</p>
            </div>
            <div className="me-0 me-sm-5 mb-3 mb-sm-0">
              <p className="textGray w600 mb-0">Location</p>
              <p className="textPrime w600 mb-0">Bangalore,Karnataka</p>
            </div>
          </div>
        </Row>
        <Row className="mt50">
          <div className="col-md-6">
            <p className="textGray w600 mb-0">Job Preference</p>
            <p className="textPrime w600 mb-0">
              Web Developer
              <span className="font14 textLightGray w500">
                Information Technology
              </span>
            </p>
            <p className="textLightGray w400 mb-0">In Pune,Maharashtra</p>
            <p className="textLightGray w600">
              Full Time <span className="textPrime"> Rs 8-10 LPA</span>
            </p>
          </div>
          <div className="col-md-6">
            <p className="textGray w600 mb-0">Skills</p>
            <button className="bgOffWhite px-3 py-2 borderNone outLineNone w-auto mt-2 me-2">
              HTML
            </button>
            <button className="bgOffWhite px-3 py-2 borderNone outLineNone w-auto mt-2 me-2">
              CSS
            </button>
            <button className="bgOffWhite px-3 py-2 borderNone outLineNone w-auto mt-2 me-2">
              React.js
            </button>
            <button className="bgOffWhite px-3 py-2 borderNone outLineNone w-auto mt-2 me-2">
              Git Hub
            </button>
          </div>
        </Row>
        <Row className="my50">
          <div className="col-md-6">
            <p className="textGray w600 mb-0">Work Experiences</p>
            <div>
              <p className="textPrime w600 mb-0">
                AABC Pharma Inc.
                <span className="font14 textLightGray w500">2022-Present</span>
              </p>
              <p className="textLightGray w400 mb-0">Project Manager</p>
              <p className="textLightGray w600">React.js | UI/UX</p>
            </div>
            <div>
              <p className="textPrime w600 mb-0">
                ERC Pharma Inc.
                <span className="font14 textLightGray w500">2020-2022</span>
              </p>
              <p className="textLightGray w400 mb-0">Junior Project Manager</p>
              <p className="textLightGray w600">React.js | UI/UX</p>
            </div>
          </div>
          <div className="col-md-6">
            <p className="textGray w600 mb-0">Education</p>
            <p className="textPrime w600 mb-0">
              Computer Engineering
              <span className="font14 textLightGray w500">2015-2019</span>
            </p>
            <p className="textLightGray w400 mb-0">Bachelor’s Degree</p>
            <p className="textLightGray w600">Gujrat Technical University</p>
          </div>
        </Row>
      </Container>
      <Download />
    </>
  );
};
export default CandidateInfo;
