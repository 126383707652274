/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jsmyaccount.css";
import { Container, Row } from "react-bootstrap";
import Myaccountheaderjsd from "../../Components/MyAccountHeaderJSD/myaccountheaderjsd";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const Jsmyaccount = () => {
  const [recruitmentData, setRecruitmentData] = useState([]);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const [homePageMaster, setHomepageMaster] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    getRecruitmentData();
    getHomePageMaster();
  }, []);

    // MENU ITEMS OBJECT
    const menuItem = [
      {
        path:"/j_myaccount/j_contact",
        name: "Contact Us",
      },
      {
        path: "/j_myaccount/j_jobpreference",
        name: "Job Preferences",
      },
      {
        path: "/j_myaccount/j_rewards",
        name: "My Rewards",
      },
      {
        path: "/j_myaccount/j_quizhome",
        name: "My Quiz",
      },
      {
        path: "/j_myaccount/j_mypayment",
        name: "Payment History",
   
      },
    ];
   /******************** API CALL START HERE **************************/
  const getRecruitmentData = () => {
    const body = {
      loginuserid: login?.userid,
      isapply: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRecruitmentData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });
      
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "JobSeeker_My_Account", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALL END HERE **************************/

  const commonData = menuItem?.map(item => {
    const matchedItem = homePageMaster?.find(menu => menu?.text === item?.name);
    if (matchedItem) {
      return {
        name: item?.name,
        imageurl: matchedItem?.imageurl,
        path: item?.path,
      };
    }
  });

  return (
          <Container fluid className="mainPagesContainer">
            <Container>
              <Row>
                <div className="col-12">
                  <Myaccountheaderjsd />
                </div>
                <div className="col-md-6 mt-4">
                  <div className="myAccountMenuSectionMain bgWhite p-1">
                   {commonData[0] !== undefined && commonData?.map((item,index)=>(
                    <div className="singleMenuItemBox px-4 py-3" key={index}>
                      <div className="w-15">
                        <img
                          src={item?.imageurl}
                          className="me-4"
                          style={{width:"19px" ,height:"19px"}}
                          alt=""
                        />
                      </div>
                      <Link
                        to={item?.path}
                        className="mb-0 textLightGray w600 linkNone text-wrap font16 ms-2"
                      >
                       {item?.name}
                      </Link>
                    </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="myAccountMenuSectionMain bgWhite p-1">
                    <div className="singleMenuItemBox2 px-4 py-3">
                      <Link
                        to="/j_myaccount/j_applyjob"
                        className="mb-0 textLightGray w600 linkNone font16"
                      >
                        Resume Sent
                      </Link>
                      <p className="mb-0 w600">{recruitmentData.length}</p>
                    </div>
                  </div>
                </div>
              </Row>
            </Container>
          </Container>
  );
};
export default Jsmyaccount;
