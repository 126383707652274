import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/footer";
import NavigationBar from "../../Components/NavigationBar/navigationBar";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { setToken, userLogin } from "../../redux/action/action";
import { useDispatch } from "react-redux";

const Homepage = () => {
  const [componentDidMountFlag, setComponentDidMountFlag] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const data = {
      emailid: "guest@cityjob.com",
      password: "CityJob@123",
    };
    axiosInstance
      .post(`${Constants.SaveSignIn}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          dispatch(userLogin(response.data.data));
          dispatch(setToken(response.data.data.accessToken));
          localStorage.setItem(
            "token",
            JSON.stringify(response.data.data.accessToken)
          );
          setComponentDidMountFlag(true);
        }
      });
  }, [dispatch, navigate]);

  return (
    componentDidMountFlag && (
      <>
        <NavigationBar />
        <div className="marginMain">
          <Outlet />
        </div>
        <Footer />
      </>
    )
  );
};
export default Homepage;
