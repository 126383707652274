/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./footer.css";
import "../../Common/common.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const Footer = () => {
  const [companyDetails, setCompanyDetails] = useState([]);
  const [socialiconData, setSocialIconData] = useState([]);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    getCompanyGeneralDetail();
    getSocialicon();
  }, []);

  /******************** API CALL START HERE **************************/
  const getCompanyGeneralDetail = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetCompanyGeneralDetail}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_SUCCESS) {
          setCompanyDetails(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getSocialicon = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetSocialicon}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setSocialIconData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
    //   if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
  };
  /******************** API CALL END HERE **************************/
  return (
    <>
      <Container fluid className="bgGray footer p-5">
        {companyDetails?.map((item, key) => (
          <div key={key}>
            <Row>
              <div className="col-md-4 pe-lg-5">
                {/* <img src={item?.logo_image} className="footerIcon" alt="" /> */}
                <NavLink to="" className="linkNone">
                  <img
                    src={item?.favicon_image}
                    className="footerIcon2"
                    height="60px"
                    width="190px"
                    alt=""
                  />
                  {/* <img
                    src="/assets/images/Untitled-removebg-preview.png"
                    height="60px"
                    width="190px"
                    alt=""
                  /> */}
                </NavLink>
                <p className="textOffWhite textFooter  mt-3">
                  {item?.description}
                </p>
                <p className="textWhite w600 mt-2 mt-5 mt-md-0 fs-6">Connect with us</p>
                {socialiconData?.map((item, key) => (
                  <NavLink to={item?.linkurl} key={key}>
                    <img
                      key={key}
                      className="font26 textWhite me-3 imgsize"
                      src={item?.imageurl}
                      alt=""
                    />
                  </NavLink>
                ))}
              </div>
              <div className="col-md-3">
                <p className="textWhite w600 mt-2 mt-5 mt-md-0">Job Portal</p>
                <NavLink
                  to=""
                  className={`linkNone footerActive ${location.pathname === "/" || location.pathname === "/alljobs" || location.pathname === "/categorydetails" ? "active" : ""}`}
                  activeclassname="active"
                >
                  <p className="textWhite w400 mt-2 font14 mb-0">Home</p>
                </NavLink>
                <NavLink
                  to="/about"
                  className="linkNone footerActive"
                  activeclassname="active"
                >
                  <p className="textWhite w400 mt-2 font14 mb-0">About Us</p>
                </NavLink>
                <NavLink
                  to="/recruiter"
                  className="linkNone footerActive"
                  activeclassname="active"
                >
                  <p className="textWhite w400 mt-2 font14 mb-0">Recruiters</p>
                </NavLink>
                <NavLink
                  to="/jobseeker"
                  className="linkNone footerActive"
                  activeclassname="active"
                >
                  <p className="textWhite w400 mt-2 font14 mb-0">Job Seekers</p>
                </NavLink>
              </div>
              <div className="col-md-3">
                <p className="textWhite w600 mt-2 mt-5 mt-md-0">Support</p>
                {/* <Link to="#" className="linkNone"> */}
                <p className="textWhite w400 mt-2 font14 mb-0 text-break">
                  {item?.emailid}
                </p>
                {/* </Link> */}
                <NavLink
                  to="/contact"
                  className="linkNone footerActive"
                  activeclassname="active"
                >
                  <p className="textWhite w400 mt-2 font14 mb-0">Contact Us</p>
                </NavLink>
              </div>
              <div className="col-md-2">
                <p className="textWhite w600 mt-2 mt-5 mt-md-0">Legal</p>
                <NavLink
                  to="/privacy"
                  className="linkNone footerActive"
                  activeclassname="active"
                >
                  <p className="textWhite w400 mt-2 font14 mb-0">
                    Privacy Policy
                  </p>
                </NavLink>
                <NavLink to="/terms" className="linkNone footerActive">
                  <p
                    className="textWhite w400 mt-2 font14 mb-0"
                    activeclassname="active"
                  >
                    Terms and Conditions
                  </p>
                </NavLink>
              </div>
            </Row>
            <Row>
              <p className="text-center textWhite  mt-5 mb-0">
                {item?.copyright}
              </p>
            </Row>
          </div>
        ))}
      </Container>
    </>
  );
};

export default Footer;
