import React from "react";
import "../../Common/common.css";
import "./postedjobsrt.css";

export default function Postedjobsrt(props) {
  return (
    <>
      <div className="jobcardMainContainer bgWhite p-3">

          <div className="row">
          <p className="text12 textPrime2 w600 mb-0 font16">Job Title</p>
          <div  className="d-flex w-100 justify-content-between">
            <b className="font14 textGray ">{props.name}</b>
            <b className="font14 textGray w600 w-35 text-end"> {props.salary} LPA</b>
          </div>
          </div>
          <div className="row mt-2">
            <p className="text12 textPrime2 w600 mb-0 font16">Company Name</p>
            </div>

            <div className="w600 textLightGray">
            <div className="d-flex justify-content-between">
              <p className="font14">{props.companyName}</p>       
              <span
              style={{ display: props.noOfApplication }}
              className="textWhite bgPrime2 w600 applicantNumber"
            >
              {props.applicantsNumber}
              
            </span>    
            </div>
          </div>
        <div className="row" style={{ display: props.metadata }}>
          <div className="d-flex justify-content-between">
          <div className="col-sm-6">
            <p className="text12 textPrime2 w600 mb-0 font16">Education</p>
            <p className="w600 textLightGray font14">{props.education}</p>
          </div>
          <div className="col-sm-6">
            <p className="text12 textPrime2 w600 mb-0 font16">Location</p>
            <p className="w600 textLightGray font14">
              <span className="font14">{props.location}</span>
            </p>
          </div>
          </div>
          
        </div>
        <div className="row">
          <div className="d-flex justify-content-between">
          <div className="col-sm-6">
            <p className="text12 textPrime2 w600 mb-0 font16">Experience</p>
            <p className="w600 textLightGray font14">{props.experience}</p>
          </div>
          <div className="col-sm-6">
            <p className="text12 textPrime2 w600 mb-0 font16"> {props.statusName} </p>
            <p className="w600 textLightGray font14">
              <span className="font14">{props.status}</span>
            </p>
          </div>
          </div>
          </div>
      </div>
    </>
  );
}
