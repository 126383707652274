import React from "react";
import "../../Common/common.css";
import "./keyFeture.css";

const KeyFeture = (props) => {
  return (
    <div className="singleFeatureContainer text-center bgWhite">
      <img src={props.path} className={props.class} alt="" />
      <p className="textPrime w500 mt-3">{props.content}</p>
    </div>
  );
};

export default KeyFeture;
