/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "../RTLogin/rtlogin.css";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import "./rtotp.css";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { rtLogin, rtOtp, setToken } from "../../redux/action/action";

const RtOtp = () => {
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [homePageMaster, setHomepageMaster] = useState([])
  const [sidelogo, setSidelogo] = useState([]);
  const user = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector((state) => state?.token);
  const [Otpdata,setOtpdata] = useState(  seconds > 0 ?true :false);
  const loginOtp = user?.otp;
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setOtpdata(false);
  
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [user,minutes, seconds]);

  const handleChange = (value, e) => {
    setOtp(value);
  };
  useEffect(() => {
    getHomePageMaster();
    getNavLogo();
  },[])

  /******************** API CALL START HERE **************************/
  const handleOtpVerify = (e) => {
    e.preventDefault();
    setLoading(true)
    const body = {
      ...(user?.mobileno
        ? { mobileno: user?.mobileno }
        : { emailid: user?.emailid }),
      otp: otp,
      userid: user?.userid,
      deviceid: "WEB",
    };
    if (otp.length == 4) {
      axiosInstance
        .post(`${Constants.SaveRecruitmentValidotp}`, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
              navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            if (response.data.data?.isverify === "N") {
              localStorage.removeItem("token");
              swal(`Your Profile is under review`, "", "error");
              setLoading(false)
            } else {
              dispatch(rtOtp(response.data.data));
              setOtp(response.data.data.otp);
              setOtpdata(false)
              dispatch(setToken(response?.data?.data?.jwttoken));
              localStorage.setItem("token", response.data.data?.jwttoken);
              navigate("/rt_home");
              setLoading(false)
            }
          } else {
            swal(`${response.data.massage}`, "", "error");
          }
        })
        .catch((error) => swal(`${error.response.data.massage}`, "", "error"));
    }
  };

  const resendOTP = () => {
    setOtpdata(true);
    if (user?.mobileno) {
      const body = {
        mobileno: user?.mobileno,
        deviceid: "WEB",
      };
      axiosInstance
        .post(`${Constants.SaveRecruitmentSignIn}`, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
              navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            dispatch(rtLogin(response.data.data));
            dispatch(setToken(response?.data?.data?.jwttoken));
            localStorage.setItem("token", response.data.data?.jwttoken);
          }
        })
        .catch((error) => {
          return swal(`${error.response.data.massage}`, "", "error");
        });
      setMinutes(0);
      setSeconds(59);
    }
    if (user?.emailid) {
      const body = { emailid: user?.emailid, deviceid: "WEB" };
      axiosInstance
        .post(`${Constants.SaveRecruitmentSignIn}`, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
              navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            dispatch(rtLogin(response.data.data));
            dispatch(setToken(response?.data?.data?.jwttoken));
            localStorage.setItem("user", response?.data?.data);
            localStorage.setItem("token", response.data.data?.jwttoken);
          }
        })
        .catch((error) => {
          return swal(`${error.response.data.massage}`, "", "error");
        });
      setMinutes(0);
      setSeconds(59);
    } else {
    }
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Recruiter_Login_Promo_Otp", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getNavLogo = () => {
    const body = {
      groupname: "Home_Nav_Logo", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setSidelogo(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALL END HERE **************************/

  return (
    <>
      <Container fluid>
        <Row>
          <div className="col-12 ps-5">
            <Link className="linkNone" to="/">
            <div className="d-flex sidebarLogoWithText">
           
          {sidelogo?.map((item,index)=>(
            <div
              className="ms-4  mt-2"
              key={index}
            >
              <img
                 key={index}
                 src={item?.imageurl}
                className="footerIcon2"
                alt=""
              />
            </div>
                ))}
          </div>
             
            </Link>
          </div>
          <div className="col-md-6 text-center mt-5">
            <h2 className="w700 mb-5">
              Get <span className="textPrime2">Hired</span> Instantly !!!
            </h2>
            {homePageMaster?.map((item, index) => (
            <img
            key={index}
            src={item?.imageurl}
              className="sideImageOtp img-fluid"
              alt=""
            />
            ))}
          </div>
          <div className="col-md-6">
            <div className="logiFormContainer">
              <h4 className="w700 text-center pt-5 textPrime2">
                OTP Verification
              </h4>

              <p className="text-center mt-4 w600 textGray">
                Enter the code we just send on your{" "}
                {user?.mobileno || user?.emailid}
              </p>
              <div className="inputs d-flex flex-row justify-content-center mt-5">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={4}
                  inputStyle={
                    otp.length == 4
                      ? `inputStyle textWhite otpInput formInput rounded ${
                          otp == loginOtp ? "bgPrime2" : "bgRed"
                        }`
                      : "inputStyle otpInput formInput rounded"
                  }
                  renderSeparator={<span> &nbsp; &nbsp;</span>}
                  renderInput={(props) => <input {...props} />}
                ></OtpInput>
              </div>
              {!(otp == loginOtp) && otp.length == 4 ? (
                <p className="errorOtp text-center">Invalid Otp</p>
              ) : null}
              <div className="text-center">
                <Link>
                  <button
                    className={
                      otp.length !== 4
                        ? "textWhite px-3 py-2 w600 borderNone btnLogin mt-5 bgPrimeLight"
                        : "bgPrime2 textWhite px-3 py-2 w600 borderNone btnLogin mt-5"
                    }
                    onClick={handleOtpVerify}
                    disabled={!otp || loading}
                  >
                    Next
                  </button>
                </Link>
              </div>
              <div className="">
              {Otpdata > 0 ? (
                  <p className="text-center mt-4 w600 textPrime2">
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p
                    type="button"
                    disabled={seconds > 0 || minutes > 0}
                    style={{
                      color:
                        seconds > 0 || minutes > 0
                          ? "GrayText text-center mr-5 mt-4 w600"
                          : "text-center mr-5 mt-4 w600 textPrime2",
                    }}
                    className="text-center mt-4 w600 textPrime2"
                    onClick={resendOTP}
                  >
                    Resend OTP ?
                  </p>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default RtOtp;
