import React from "react";
import "./candidatecardrt.css";
import { AiTwotoneHeart } from "react-icons/ai";
import { TbCertificate } from "react-icons/tb";
import { MdWorkOutline } from "react-icons/md";

const Candidatecardrt = (props) => {
  
  return (
    <>
      <div className="candidateCardInfo w-100 bgWhite p-3">
        <div className="d-flex ">
          <img
            src={props?.profileimage}
            className="userImage rounded-circle"
            alt=""
          />
          <div className="ms-3 mt-1 w-100">
            <div className="d-flex justify-content-between">
              <div>
              <p className="mb-0 w600 textGray font16 ">
                {props?.firstname} {props?.lastname}
              </p>
              </div>
              <div className="d-flex mb-0" >
                <h3 className="textPrime2 font16 w600 mb-0 ">
                  {props?.startSalary} {props?.salary} {props?.endSalary}
                </h3>

                <AiTwotoneHeart
                  className={props?.Icons}
                  onClick={props?.hendleChangeLike}
                />
              </div>
            </div>
            <div className="w-100">
              <p className="mb-0 font14 w500  text-secondary ">
                {props?.company} &bull;{props?.experience}
              </p>
            </div>
          </div>
        </div>
        <div className="workExp d-flex mt-4 ms-2">
          <MdWorkOutline className="mt-3 font22 text-secondary" />
          <div className="ms-3" style={{display:props?.display}}>
            <p className="mb-0 font16 w600 textGray" >
              {props?.workcompanyname} &bull; {props?.designation}
            </p>
            <p className="mb-0 font14 w500 text-secondary">
              {props?.companyDate}
            </p>
          </div>
          <div  className="ms-3 d-flex align-items-end"><p className="mb-0 font16 w500  text-secondary">{props.Fresher}</p></div>
        </div>
        
        <div className="workExp d-flex mt-4 ms-2">
          <TbCertificate className="mt-3 font22 text-secondary" />
          
          <div className="ms-3" >
            <p className="mb-0 font16 w600 textGray">
              {props?.education} &bull;{props?.institute}
            </p>
            <p className="mb-0 font14 w500 text-secondary">
              {props?.educationYear}
            </p>
          </div>
        </div>
        <p className="mt-4 w600 text-secondary w-100 font16 text-truncate">
          {props?.description}
        </p>
      </div>
    </>
  );
};

export default Candidatecardrt;
