/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-array-constructor */
import React, { useEffect, useState } from "react";
import "./pricing.css";
import "../../Common/common.css";
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import "../../Components/PricingPlanCard/pricingPlanCard.css";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { BsFillDiamondFill } from "react-icons/bs";
import DownloadModal from "../../Components/DownloadModal/downloadModal";

const JobSeekerPlans = () => {
  const [jobSeekerPlan, setJobSeekerPlan] = useState([]);
  const Token = useSelector((state) => state?.token);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getJobSeekerPlan();
  }, []);

  /******************** API CALL START HERE **************************/
  const getJobSeekerPlan = () => {
    const body = {
      isactive: "Y",
      // pageable: {
      //   pageno: 0,
      //   pagesize: 10,
      // },
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerPlan}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobSeekerPlan(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
    
  };
  /******************** API CALL END HERE **************************/

  /**  Cards SLIDER **/
  const palnsSlider = {
    infinite: jobSeekerPlan.length >= 3,
    arrows: jobSeekerPlan.length >= 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay: true,
    // autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Row className="py50">
        <h1 className="text-center w700 textGray mb-5">
          Our <span className="textPrime">Job Seeker </span> Plans
        </h1>
        <div>
          <Slider {...palnsSlider}>
            {jobSeekerPlan?.map((item, key) => (
              <div className="col-md-4" key={key}>
                <div className="">
                  <div className="planContainer"  >
                    <div className="planName text-start bgPrime">
                    <div onClick={() => setShow(true)}>
                    <p className="mb-0  w700 ms-4 textGray font22 text-center text-wrap plantext">
                          {item?.planname.split('').length > 40 ? (
                            <>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-${item?.planname}`}>
                                    {item?.planname}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {item?.planname
                                    .split(" ")
                                    .slice(0, 40)
                                    .join(" ")}
                                  ...
                                </span>
                              </OverlayTrigger>
                            </>
                          ) : (
                            item?.planname
                          )}
                        </p>

                        <h3 className="mb-0 w700 ms-4 textWhite text-center">
                        {item.currency}{item?.price}
                          <span className="font16 ms-2">{item?.day} days</span>
                        </h3>
                        </div>
                    </div>
                    <div className="featuresContainer text-start mb-3 crolUp">
                      {item.feachersList?.map((item, key) => (
                        <div className="ms-4 textGray w600 d-flex" key={key}>
                          <p>
                            <BsFillDiamondFill className="w600 font14" />
                          </p>
                          <p className="ms-2">{item?.feachers}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <DownloadModal show={show} setShow={setShow} />
        </div>
      </Row>
    </>
  );
};
export default JobSeekerPlans;
