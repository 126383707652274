import React from "react";
import "./filter.css";
import "../../Common/common.css";
import Input from "../Input/input";

const Filter = () => {
  return (
    <div className="filterContainer">
      <p className="textGray w600">Experience</p>
      <div className="d-flex mb-0">
        <Input placeHolder="Min"></Input>
        &nbsp; <Input placeHolder="Max"></Input>
      </div>
      <hr className="mt-0" />
      <p className="textGray w600">Salary</p>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          1-3 LPA
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          3-6 LPA
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          6-9 LPA
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          9-12 LPA
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          12-15 LPA
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          15-18 LPA
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          18 LPA +
        </label>
      </div>
      <hr className="mt" />
      <p className="textGray w600">Locations</p>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          Bangalore
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          Mumbai
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          Pune
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          Delhi
        </label>
      </div>
      <hr className="mt" />
      <p className="textGray w600">Educations</p>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          Graduate
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          Post-Graduate
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          Diploma
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          High School
        </label>
      </div>
      <div className="checkboxWrap mt-2">
        <input type="checkbox" className="filterCheckbox" />
        <label className="ms-1 w600 textLightGray" htmlFor="">
          Any Education
        </label>
      </div>
      <button className="w-100 borderNone bgPrime textWhite w600 outlineNone mt-4 py-2 radious100">
        Apply Filter
      </button>
      <br />
      <button className="w-100 borderNone bgOffWhite textLightGray w600 outlineNone mt-2 py-2 radious100">
        Clear Filter
      </button>
    </div>
  );
};

export default Filter;
