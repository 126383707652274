/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./navigationBar.css";
import "../../Common/common.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import DownloadModal from "../DownloadModal/downloadModal";
import * as Constants from "../../Common/Global/constants";
import axiosInstance from "../../Api/commonUrl";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const NavigationBar = () => {
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [homePageMaster, setHomepageMaster] = useState([])
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    getHomePageMaster();
  }, [])

  const navToggle = () => {
    setExpanded(expanded ? false : true);
  };

  const closeNav = () => {
    setExpanded(false);
  };
  /******************** API CALLS START HERE **************************/
  const getHomePageMaster = () => {
    const body = {
      groupname: "Home_Nav_Logo",
      isactive: "Y",

    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALLS END HERE **************************/
  return (
    <>
      <Navbar className="jobNavbar bgWhite" expand="lg" expanded={expanded}>
        <Container fluid>
          <Navbar.Brand href="#home" onClick={closeNav}>
            {homePageMaster?.map((item, index) => (
              <Link className="linkNone" to="/" key={index}>
                {/* <img
                src="/assets/images/Untitled-removebg-preview.png"
                height="50px"
                width="190px"
                alt=""
              /> */}
                <img
                  key={index}
                  src={item?.imageurl}
                  height="50px"
                  width="190px"
                  className="footerIcon2 ms-2"
                  alt=""
                />
              </Link>
            ))}
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={navToggle}
            aria-controls="basic-navbar-nav"
            className="shadow-none"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="ms-auto">
              <Nav.Link onClick={closeNav}>
                <NavLink
                  activeclassname="active"
                  className={`ms-3 w500 textGray textPrimeHover linkNone basic-navbar-nav cNavLink ${location.pathname === "/" || location.pathname === "/alljobs" || location.pathname === "/categorydetails" ? "active" : ""}`}
                  to="/"
                >
                  Home
                </NavLink>
              </Nav.Link>
              <Nav.Link onClick={closeNav}>
                <NavLink
                  activeclassname="active"
                  className="ms-3 w500 textGray textPrimeHover linkNone cNavLink"
                  to="/recruiter"
                >
                  Recruiters
                </NavLink>
              </Nav.Link>
              <Nav.Link onClick={closeNav}>
                <NavLink
                  activeclassname="active"
                  className="ms-3 w500 textGray textPrimeHover linkNone cNavLink"
                  to="/jobseeker"
                >
                  Job Seekers
                </NavLink>
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  setShow(true);
                  closeNav();
                }}
                className="ms-3 w500 textGray textPrimeHover cNavLink"
                activeclassname="active"
              >
                Download App
              </Nav.Link>
              <Nav.Link
                className="ms-3 d-block d-lg-none w500 textGray textPrimeHover"
                onClick={closeNav}
              >
                <NavLink
                  className="linkNone textGray cNavLink"
                  to="about"
                  activeclassname="active"
                >
                  About Us
                </NavLink>
              </Nav.Link>
              <Nav.Link
                className="ms-3 d-block d-lg-none w500 textGray textPrimeHover cNavLink"
                onClick={closeNav}
              >
                {/* Blog */}
              </Nav.Link>
            </Nav>
            <NavDropdown
              onClick={closeNav}
              className="ms-3 d-none d-lg-block"
              title={
                <>
                  <button className="font26 borderNone bgNone mx-3 textGray textPrimeHover">
                    &#9776;
                  </button>
                </>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item className="bgWhite w500 textGray">
                <NavLink
                  to="/about"
                  className="linkNone textGray cNavLink"
                  activeclassname="active"
                  onClick={closeNav}
                >
                  About Us
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={closeNav}
                className="bgWhite w500 textGray"
              >
                <NavLink
                  to=""
                  className="linkNone textGray"
                  activeclassname="active"
                  onClick={closeNav}
                >
                  {/* Blog */}
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <DownloadModal show={show} setShow={setShow} />
    </>
  );
};

export default NavigationBar;
