/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jsjobpreferences.css";
import { Container, Row } from "react-bootstrap";
import Myaccountheaderjsd from "../../Components/MyAccountHeaderJSD/myaccountheaderjsd";
import Jobpreferencecardjsd from "../../Components/JobPreferenceCardJSD/jobpreferencecardjsd";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Jsjobpreferences = () => {
  const [jobperferences, setJobperferences] = useState([]);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getJobSeekerJobPerferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************** API CALL START HERE **************************/
  const getJobSeekerJobPerferences = () => {
    const body = {
      userid: login?.userid,
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerJobPerferences}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobperferences(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  /******************** API CALL END HERE **************************/

  return (
    <>
      <Container fluid className="mainPagesContainer">
        <Container>
          <Row>
            <div className="col-12">
              <div className="breadcrubsContainer bgWhite p-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link
                        to="/j_myaccount"
                        className="linkNone textLightGray w600"
                      >
                        My Account
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#" className="linkNone textGray w600">
                        My Job Preferences
                      </a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-12 mt-4">
              <Myaccountheaderjsd />
            </div>
          </Row>
          <Row>
            {jobperferences?.map((item, key) => (
              <div className="col-md-6 mt-4" key={key}>
                <Jobpreferencecardjsd
                  name={item?.jobpreference}
                  salary={`${login?.currency} ${item?.expactedsalaryname}`}
                  technology={item?.expactedindustryname}
                  city={item?.city}
                />
              </div>
            ))}
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default Jsjobpreferences;
