import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import reducer from "../reducer/reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer);
const middleware = [];
const persistor = persistStore(
  store,
  null,
  compose(applyMiddleware(...middleware))
);
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { store, persistor };
