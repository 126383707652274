import React from "react";
import "./infoCard.css";
import "../../Common/common.css";
import { Row } from "react-bootstrap";

const InfoCard = (props) => {
  return (
    <div className="infoCardContainer bgWhite">
      <div className="d-flex">
        <img src={props.image} alt="" className="candidateImage" />
        <div className="ms-2">
          <p className="w600 textGray font18 m-0">{props.name}</p>
          <p className="w600 textLightGray font16 m-0">{` ${props.education} | ${props.experience}  |  ${props.salary} `}</p>
        </div>
      </div>
      <Row className="mt-4">
        <div className="col-md-6">
          <p className="m-0 font18 textGray w600">
            {`${props.companyName} | ${props.position}`}
          </p>
          <p className="m-0 font16 textLightGray w600">
            {`${props.workingFrom} - ${props.workTill}`}
          </p>
        </div>
        <div className="col-md-6">
          <p className="m-0 font18 textGray w600">{`${props.educationName}`}</p>
          <p className="m-0 font16 textLightGray w600">
            {`${props.edStart} - ${props.edEnd}`}
          </p>
        </div>
      </Row>
      <Row className="mt-4">
        <div className="col-md-6">
          <p className="m-0 font18 textGray w600">
            {props.city}
            <span className="font16 textLightGray w600">{props.state}</span>
          </p>
        </div>
      </Row>
    </div>
  );
};

export default InfoCard;
