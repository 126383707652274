/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Buttons from "../Buttons/buttons";
import { ImCross } from "react-icons/im";
import "./downloadModal.css";
import "../../Common/common.css";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DownloadModal = ({ show, setShow, contactdata, setContactdata }) => {
  const [contact, setContact] = useState("");
  const [companyDetails, setCompanyDetails] = useState([]);
  const [getLink, setLinks] = useState([]);
  const Token = useSelector((state) => state?.token);
  const [homePageMaster, setHomepageMaster] = useState([])
  const navigate = useNavigate();
  let [error, setError] = useState("");
  const number = new RegExp("^[0-9]{10}$");

  useEffect(() => {
    getScanOpenApp();
    getCompanyGeneralDetail();
    getHomePageMaster();
  }, []);

  useEffect(() => {
    if (contactdata) {
      setContactdata(contactdata);
    } else {
      setContact(contact);
    }
  }, [contact, contactdata, show]);

  const numberOnchange = (e) => {
    const re = /^[0-9\b]+$/;
    if (contactdata) {
      if (e.target.value === "" || re.test(e.target.value)) {
        setContactdata(e.target.value);
      }
    } else {
      if (e.target.value === "" || re.test(e.target.value)) {
        setContact(e.target.value);
      }
    }
  };

  /******************** API CALLS START HERE **************************/
  const getScanOpenApp = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetScanOpenApp}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_SUCCESS) {
          setLinks(response?.data.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  const saveContactus = (e) => {
    validate();
    if (contactdata) {
      if (!contactdata?.match(number) == "") {
        const body = {
          mobileno: contactdata,
        };
        axiosInstance
          .post(`${Constants.SendLinkInMobile}`, body, {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${Token}`,
            },
          })
          .then((response) => {
            if (response.data.status === Constants.CODE_SUCCESS) {
              setContactdata("");
              swal(`${response?.data?.massage}`, "", "success");
              setShow(false);
              // return response?.data.data;
            } else {
              swal(`${response.data.error}`, "", "error");
            }
          })
          .catch((error) => {
            swal(`${error.response.data.error}`, "", "error");
          });
      }
    } else {
      if (!contact?.match(number) == "") {
        const body = {
          mobileno: contact,
        };
        axiosInstance
          .post(`${Constants.SendLinkInMobile}`, body, {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${Token}`,
            },
          })
          .then((response) => {
            if (response.data.status === Constants.CODE_SUCCESS) {
              setContact("");
              swal(`${response?.data?.massage}`, "", "success");
              setShow(false);
            } else {
              swal(`${response.data.error}`, "", "error");
              setContact("");
              setShow(false);
            }
          })
          .catch((error) => {
            swal(`${error.response.data.error}`, "", "error");
          });
      }
    }
  };
  const getCompanyGeneralDetail = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetCompanyGeneralDetail}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_SUCCESS) {
          setCompanyDetails(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Download_icon", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALLS END HERE **************************/

  /** Validation Function **/
  const validate = () => {
    let errors = [];
    if (!contact?.trim()) {
      errors.contact = "Mobile number is required";
    } else if (!contact?.match(number)) {
      errors.contact = "Enter valid Mobile number";
    }
    if (!contactdata?.trim()) {
      errors.contactdata = "Mobile number is required";
    } else if (!contactdata?.match(number)) {
      errors.contactdata = "Enter valid Mobile number";
    }
    return setError(errors);
  };

  const downloadModalSlider = {
    rows: 1,
    className: "center",
    infinite: true,
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Modal centered show={show} onHide={setShow}>
        <Modal.Body className="text-center py50">
          <button
            onClick={() => {
              setShow(false);
              setContact("");
              if (contactdata) setContactdata("");
              setError("");
            }}
            className="modalCloseButton bgNone borderNone outlineNone textGray"
          >
            <ImCross />
          </button>
          <br />
          <h4 className="w700 textGray">
            <span className="textPrime">Download</span> Now !
          </h4>
          <div className="downloadSlider">
            <Slider {...downloadModalSlider} className="">
              <div>
                <div className="d-flex justify-content-center">
                  <img
                    src={companyDetails[0]?.link_ios_qrcode}
                   className="qrImageModal"
                    alt=""
                  />
                </div>
                <p>
                  <span className="textPrime"> App Store </span> QR code
                </p>
              </div>
              <div>
                <div className="d-flex justify-content-center">
                  <img
                    src={companyDetails[0]?.link_android_qrcode}
                    height="200px"
                    alt=""
                  />
                </div>
                <p>
                  {" "}
                  <span className="textPrime">Play Store</span> QR code
                </p>
              </div>
            </Slider>
          </div>
          <input
            type="text"
            className="borderNone outlineNone modalInput my-4"
            placeholder="Enter mobile number"
            maxLength={10}
            name="contact"
            value={contactdata ? contactdata : contact}
            onChange={numberOnchange}
          />
          {contactdata ? (
            !contactdata?.match(number) && error.contactdata ? (
              <div className="errContact">{error.contactdata}</div>
            ) : null
          ) : !contact?.match(number) && error.contact ? (
            <div className="errContact">{error.contact}</div>
          ) : null}
          <br />
          <Buttons
            bgColor="Prime"
            textColor="White"
            content="Send me the link"
            hover=" "
            className="mb50 "
            onClick={saveContactus}
          />
          <div className="d-flex justify-content-center mt-4">
            {getLink?.map((item, index) => (
              <div key={index}>
                <NavLink to={item?.link_ios}>
                  <img src={homePageMaster?.[0]?.imageurl} alt="" className="font30 me-2 textGray" width="30px" height="30px"/>
                </NavLink>
                <NavLink to={item?.link_android}>
                  <img src={homePageMaster?.[1]?.imageurl} alt="" className="ms-2 font30 textGray" width="30px" height="30px" />
                </NavLink>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DownloadModal;
