/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {  Container, Row } from "react-bootstrap";
import Download from "../../Components/Download/download";
import "./contact.css";
import "../../Common/common.css";
import Buttons from "../../Components/Buttons/buttons";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import {useNavigate } from "react-router-dom";

const Contact = () => {
  const Token = useSelector((state) => state?.token);
  let [clientname, setClientname] = useState("");
  let [subject, setSubject] = useState("");
  let [clientemailid, setClientemailid] = useState("");
  let [clientphone, setClientphone] = useState("");
  let [emailbody, setEmailbody] = useState("");
  let [error, setError] = useState("");
  const navigate = useNavigate();
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  
  const number = new RegExp("^[0-9]{10}$");

  const numberOnchange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setClientphone(e.target.value);
    }
  };

  /**Validation Function */
  const validate = () => {
    let errors = [];
    if (!clientname) {
      errors.clientname = "Name is required";
    }
    if (!subject) {
      errors.subject = "Subject is required";
    }
    if (!clientemailid.trim()) {
      errors.clientemailid = "Email id is required";
    } else if (!clientemailid.match(pattern)) {
      errors.clientemailid = `Please Enter valid email [eg. xxx@xxx.xxx]`;
    }
   
    if (!clientphone.trim()) {
      errors.clientphone = "Mobile number is required";
    } else if (!clientphone.match(number)) {
      errors.clientphone = "Enter valid Mobile number";
    }
    if (!emailbody) {
      errors.emailbody = "Description is required";
    }
    if (!clientname) clientname = "";
    if (!subject) subject = "";
    if (!clientemailid) clientemailid = "";
    if (!clientphone) clientphone = "";
    if (!emailbody) emailbody = "";
    return setError(errors);
  };

  /******************** API CALL START HERE **************************/
  const saveContactus = () => {
    validate();
    const body = {
      clientname: clientname,
      subject: subject,
      clientemailid: clientemailid,
      clientphone: clientphone,
      emailbody: emailbody,
    };
    if (
      !clientname == "" &&
      !subject == "" &&
      !clientemailid.match(pattern)=="" &&
      !clientphone.match(number) == "" &&
      !emailbody == ""
    ) {
      axiosInstance
        .post(`${Constants.SaveContactus}`, body, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            swal(`${response.data.massage}`, "", "success");
            setClientname("");
            setSubject("");
            setClientemailid("");
            setClientphone("");
            setEmailbody("");
            return response.data.data;
          } else {
            swal(`${response.data.error}`, "", "error");
          }
        })
        .catch((error) => {
          swal(`${error.response.data.error}`, "", "error");
        });
    }
  };
  /******************** API CALL END HERE **************************/

  return (
    <>
      <Container>
        <Row className="my100">
          <h2 className="mb-4 w700 textGray">Get in touch</h2>
          <div className="col-md-6">
            <input
              type="text"
              className="offInput bgOffWhite borderNone outlineNone ps-3"
              placeholder="Your Name"
              name="clientname"
              value={clientname || ""}
              onChange={(e) => setClientname(e.target.value)}
            
            />
            {!clientname && error.clientname ? (
              <p className="errContact">{error.clientname}</p>
            ) : null}
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="offInput bgOffWhite borderNone outlineNone ps-3"
              placeholder="Mobile No."
              name="clientphone"
              value={clientphone|| ""}
              maxLength="10"
              onChange={numberOnchange}
            />
            {!clientphone.match(number) && error.clientphone ? (
              <div className="errContact">{error.clientphone}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <input
              type="email"
              className="offInput bgOffWhite borderNone outlineNone ps-3"
              placeholder="Email"
              id="inputEmail"
              name="clientemailid"
              value={clientemailid || ""}
              onChange={(e) => setClientemailid(e.target.value)}
              required
            />
            {!clientemailid.match(pattern) && error.clientemailid ? (
              <div className="errContact">{error.clientemailid}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className="offInput bgOffWhite borderNone outlineNone ps-3 "
              placeholder="Subject"
              name="subject"
              value={subject || ""}
              onChange={(e) => setSubject(e.target.value)}          
            />
            {!subject && error.subject ? (
              <div className="errContact">{error.subject}</div>
            ) : null}
          </div>
          <div className="col-12">
            <textarea
              className="w-100 borderNone outlineNone ps-3 pt-3 bgOffWhite textAreaContact mt-2"
              placeholder="Description"
              name="emailbody"
              value={emailbody || ""}
              onChange={(e) => setEmailbody(e.target.value)}
            ></textarea>
            {!emailbody && error.emailbody ? (
              <div className="errContact mt-1">{error.emailbody}</div>
            ) : null}
          </div>
          <div className="col-12 text-center mt-3">
            <Buttons
            // className="btn btn-default"
              type="button"
              bgColor="Prime"
              textColor="White"
              content="Send"
              hover="greenHover"
              onClick={saveContactus}
            /> 
          </div>
        </Row>
      </Container>
      <Download />
    </>
  );
};
export default Contact;
