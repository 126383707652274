import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./rtmyaccount.css";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import MyaccountheaderRt from "../../Components/MyAccountHeaderRT/myaccountheaderrt";
import { BsBoxArrowDownRight } from "react-icons/bs";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const Rtmyaccount = () => {
  const Token = useSelector((state) => state?.token);
  const [homePageMaster, setHomepageMaster] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    getHomePageMaster();
  }, []);

    // MENU ITEMS OBJECT
    const menuItem = [
      {
        path:"/rt_myaccount/rt_contact",
        name: "Contact Us",
      },
      {
        path: "/rt_myaccount/rt_alljobs",
        name: "Manage Job",
      },
      {
        path: "/rt_myaccount/rt_mypayment",
        name: "Payment History",
      },
    ];

   /******************** API CALL START HERE **************************/
  const getHomePageMaster = () => {
    const body = {
      groupname: "Recruiter_My_Account", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALL END HERE **************************/
  
  const commonData = menuItem?.map(item => {
    const matchedItem = homePageMaster?.find(menu => menu?.text === item?.name);
    if (matchedItem) {
      return {
        name: item?.name,
        imageurl: matchedItem?.imageurl,
        path: item?.path,
      };
    }
  });
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <MyaccountheaderRt
                      name="My Plan"
                      personal="Personal Info"
                      icon={
                        <BsBoxArrowDownRight className="border-4 text-dark w600" />
                      }
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="myAccountMenuSectionMain bgWhite p-1">
                       {commonData[0] !== undefined && commonData?.map((item,index)=>(
                      <div className="singleMenuItemBox px-4 py-3" key={index}>
                        <div className="w-15">
                          <img
                             src={item?.imageurl}
                            style={{width:"19px",height:"19px"}}
                            className="me-4"
                            alt=""
                          />
                        </div>
                        <Link
                         to={item?.path}
                          className="mb-0 textLightGray w600 linkNone"
                        >
                          <p className="mb-0 textLightGray w600">{item?.name}</p>
                        </Link>
                      </div>
                         ))}
                    </div>
                  </div>
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Rtmyaccount;
