/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useEffect, useState } from "react";
import "../../Common/common.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./rtcandidatelist.css";
import { Container, Row } from "react-bootstrap";
import Candidatecardrt from "../../Components/CandidateCardRT/candidatecardrt";
import swal from "sweetalert";
import * as Constants from "../../Common/Global/constants";
import axiosInstance from "../../Api/commonUrl";
import Pagination from "react-bootstrap/Pagination";
import { FaFilter } from "react-icons/fa";
import { useSelector } from "react-redux";

const Rtcandidatelist = () => {
  const location = useLocation();
  const selectedValue = location.state?.selectedvalue;
  const pageno = location.state?.data;
  const jobtypename1 = location.state?.jobtypename;
  const expactedindustryname1 = location.state?.expactedindustryname;
  const requiredexperience1 = location.state?.requiredexperience;
  const jobcity1 = location.state?.city;
  const [joblist, setJobList] = useState(
    selectedValue === undefined ? "" : selectedValue
  );
  const [jobSeekerData, setJobSeekerData] = useState([]);
  const [postJobData, setPostJobData] = useState([]);
  const [city, setCity] = useState(jobcity1 === undefined ? "" : jobcity1);
  const [jobtypename, setJobtypename] = useState(
    jobtypename1 === undefined ? "" : jobtypename1
  );
  const [jobtype, setJobtype] = useState([]);
  const [expactedindustryname, setExpactedIndustryName] = useState(
    expactedindustryname1 === undefined ? "" : expactedindustryname1
  );
  const [expactedIndustry, setExpactedIndustry] = useState([]);
  const [requiredexperience, setRequiredExperience] = useState(
    requiredexperience1 === undefined ? "" : requiredexperience1
  );
  const [requirEdexperienceData, setRequirEdexperienceData] = useState([]);
  const [defaultImg, setDefaultImg] = useState([])
  const [page, setPage] = useState(1);
  const [pagesize, Setpagesize] = useState(6);
  const [totalPages, SettotalPages] = useState(0);
  const user = useSelector((state) => state?.user);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    const pageno = location.state?.data;
    const selectedValue = location.state?.selectedvalue;
    setCity(jobcity1 === undefined ? "" : jobcity1);
    setJobtypename(jobtypename1 === undefined ? "" : jobtypename1);
    setExpactedIndustryName(
      expactedindustryname1 === undefined ? "" : expactedindustryname1
    );
    setRequiredExperience(
      requiredexperience1 === undefined ? "" : requiredexperience1
    );

    if (pageno !== undefined) {
      setPage(pageno);
    } else {
      setPage(1);
    }
    if (selectedValue === undefined) {
      setJobList(joblist);
    } else {
      setJobList(selectedValue);
    }
  }, [location, joblist]);

  useEffect(() => {
    getRecruitmentPostJob();
    getJobTypeData();
    getJobSeekerData();
    getExpactedIndustry();
    getRequiredExperience();
    getHomePageMaster();
  }, [page, joblist, selectedValue, pageno]);

  // useEffect(() => {
  //   if (page) {
  //     const skip = pagesize * page;
  //     const dataskip = jobSeekerData?.slice(
  //       page === 1 ? 0 : skip - pagesize,
  //       skip
  //     );
  //     setJobSeekerList(dataskip);
  //   }
  // }, [jobSeekerData, page]);

  /******************** API CALL START HERE **************************/
  const getJobSeekerData = () => {
    const body = {
      postjobid: joblist || "",
      loginuserid: user?.userid,
      isactive: "Y",
      jobtypename: jobtypename,
      jobcity: city,
      expactedindustryname: expactedindustryname,
      totalexperience: requiredexperience,
      pageable: {
        pageno: page - 1,
        pagesize: pagesize,
      },  
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          Setpagesize(response.data.pageable.pagesize);
          SettotalPages(response.data.pageable.totalPages);
          setJobSeekerData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  const getRecruitmentPostJob = () => {
    const body = {
      userid: user?.userid,
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPostJobData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  const getJobTypeData = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetJobType}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobtype(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  const getExpactedIndustry = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetExpactedIndustry}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setExpactedIndustry(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  const getRequiredExperience = () => {
    const body = {
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRequiredExperience}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRequirEdexperienceData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Profile_image", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setDefaultImg(response.data.data?.[0]?.imageurl);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  /******************** API CALL END HERE **************************/
  /* PAGINATION CODE FROM BOOTSTRAP */
  // HANDLE NEXT PAGE
  const handleNext = () => {
    if (page === totalPages) return page;
    setPage(page + 1);
  };

  // /  HANDLE PREVIOUS /
  const handlePrevious = () => {
    if (page === 1) return page;
    setPage(page - 1);
  };

  const filteredData = (e) => {
    e.preventDefault();
    setPage(1);
    const body = {
      postjobid: joblist || "",
      isactive: "Y",
      loginuserid: user?.userid,
      jobtypename: jobtypename,
      jobcity: city,
      expactedindustryname: expactedindustryname,
      totalexperience: requiredexperience,
      pageable: {
        pageno:  page - 1,
        pagesize: pagesize,
      },
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          Setpagesize(response.data.pageable.pagesize);
          SettotalPages(response.data.pageable.totalPages);
          setJobSeekerData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 w600 textGray font18 ms-2">Job List</p>
                    <select
                      className="p-2 filterOutline outlineNone ms-4"
                      name="joblist"
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setJobList(selectedValue);
                        const newLocation = { ...location, state: null };
                        setPage(1);
                        navigate(newLocation);
                      }}
                      value={joblist}
                    >
                      <option value={""}>All</option>
                      {postJobData?.length >= 0 &&
                        postJobData?.map((item, index) => (
                          <option value={item?.postjobid} key={index}>
                            {item?.jobtitle}
                          </option>
                        ))}
                    </select>
                  </div>
                  <form onSubmit={filteredData}>
                    <div className="rowScrollbar">
                      <div className="singleFilterItemBox bgWhite ms-3 pb-2 p-2">
                        <a className="linkNone w600 textPrime2 font14 py-2">
                          Location
                        </a>
                        <input
                          type="text"
                          placeholder="eg. Pune"
                          name="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          className="filterInput"
                        />
                      </div>
                      <div className="singleFilterItemBox bgWhite ms-3 pb-2 p-2">
                        <a className="linkNone w600 textPrime2 font14 py-2">
                          Job Type
                        </a>
                        <select
                          className="p-2 filterOutline outlineNone"
                          name="jobtypename"
                          onChange={(e) => setJobtypename(e.target.value)}
                          value={jobtypename}
                        >
                          <option disabled="disabled" value={""}>
                            Select
                          </option>
                          {jobtype.length >= 0 &&
                            jobtype?.map((item, index) => (
                              <option value={item?.name} key={index}>
                                {item?.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="singleFilterItemBox bgWhite ms-3 pb-2 p-2">
                        <a className="linkNone w600 textPrime2 font14 py-2">
                          Industry
                        </a>
                        <select
                          className="p-2 filterOutline outlineNone"
                          name="expactedindustryname"
                          onChange={(e) =>
                            setExpactedIndustryName(e.target.value)
                          }
                          value={expactedindustryname}
                        >
                          <option disabled="disabled" value={""}>
                            Select
                          </option>
                          {expactedIndustry.length >= 0 &&
                            expactedIndustry?.map((item, index) => (
                              <option value={item?.name} key={index}>
                                {item?.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="singleFilterItemBox bgWhite ms-3 pb-2 p-2">
                        <a className="linkNone w600 textPrime2 font14 mpy2">
                          Required Experience
                        </a>
                        <select
                          className="p-2 filterOutline outlineNone"
                          name="requiredexperience"
                          onChange={(e) =>
                            setRequiredExperience(e.target.value)
                          }
                          value={requiredexperience}
                        >
                          <option disabled="disabled" value={""}>
                            Select
                          </option>
                          {requirEdexperienceData.length >= 0 &&
                            requirEdexperienceData?.map((item, index) => (
                              <option value={item?.name} key={index}>
                                {item?.name}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="singleFilterItemBox2 rightmargin">
                        <button
                          className="mt-3 borderNone bgPrime2 textWhite p-2 px-3 filterButton "
                          type="submit"
                        >
                          <FaFilter />
                        </button>
                      </div>
                    </div>
                  </form>
                </Row>
                <hr className="opacity50" />
                <Row>
                  {jobSeekerData?.length > 0 ? (
                    jobSeekerData?.map((item, key) => {
                      localStorage.setItem("postjobid", item?.postjobid);
                      const formatDate = (dateString) => {
                        const year = dateString?.substring(0, 4);
                        const month = dateString?.substring(5, 7);
                        const months = [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ];
                        const monthName = months[parseInt(month, 10) - 1];
                        return `${monthName}-${year}`;
                      };
                      const formattedStartDate = formatDate(
                        item?.workexperience_startdate
                      );
                      const formattedEndDate = formatDate(
                        item?.workexperience_enddate
                      );
                      const eductionStartDateFormatted = formatDate(
                        item?.education_startdate
                      );
                      const eductionEndtDateFormatted = formatDate(
                        item?.education_enddate
                      );
                      return (
                        <div className="col-md-6 mt-4" key={key}>
                          <Link
                            to={`/rt_candidatelist/rt_candidatedetails/${item?.userid}`}
                            state={{
                              data: page,
                              selectedvalue: joblist,
                              postjobid: item?.postjobid,
                              jobtypename: jobtypename,
                              city: city,
                              expactedindustryname: expactedindustryname,
                              requiredexperience: requiredexperience,
                            }}
                            className="linkNone"
                            key={key}
                          >
                            <Candidatecardrt
                              key={item.userid}
                              profileimage={
                                item?.profileimage ===  "" || null || "null"
                                  ? defaultImg
                                  :  item?.profileimage
                              }
                              firstname={item?.firstname}
                              lastname={item?.lastname}
                              company={item?.educationlevel}
                              experience={item?.totalexperience}
                              startSalary={
                                item?.expactedsalaryname === null ? "" : user?.currency
                              }
                              salary={item?.expactedsalaryname}
                              endSalary={
                                item?.expactedsalaryname === null ? "" : "LPA"
                              }
                              display ={item?.workexperience_companyname === null && item?.workexperience_designation===null ? "none" : "block"}
                              workcompanyname={item?.workexperience_companyname}
                              designation={item?.workexperience_designation}
                              Fresher = {item?.workexperience_companyname === null && item?.workexperience_designation === null ? "Fresher " :""}
                              companyDate={
                                item?.workexperience_present === "Y"
                                  ? `${formattedStartDate} - Present`
                                  : `${formattedStartDate} - ${formattedEndDate} `
                              }
                              education={item?.education_universityname}
                              institute={item?.education_institutename}
                              educationYear={`${eductionStartDateFormatted} - ${eductionEndtDateFormatted} `}
                              Icons={
                                item.isfavouritebyrecruiter === "Y"
                                  ? "font20 text-danger activeLink mx-3"
                                  : "font20 textLightGray mx-3"
                              }                    
                              description={item?.bio}
                            />
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-md-12 text-center mt-5">
                      <p>No results found</p>
                    </div>
                  )}
                </Row>
                <Row>
                  {jobSeekerData?.length > 0 && (
                    <div className="mt-4 ms-auto d-flex justify-content-end paginationBox">
                      <Pagination>
                        {totalPages < 10 ? (
                          <>
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            {Array(totalPages)
                              .fill(null)
                              .map((_, index) => (
                                <Pagination.Item
                                  key={index}
                                  active={page === index + 1 ? true : false}
                                  onClick={() => {
                                    setPage(index + 1);
                                  }}
                                >
                                  {index + 1}
                                </Pagination.Item>
                              ))}
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === totalPages}
                            />
                          </>
                        ) : (
                          <>
                            <Pagination.First onClick={() => setPage(1)} />
                            <Pagination.Prev
                              onClick={handlePrevious}
                              disabled={page === 1}
                            />
                            <Pagination.Item
                              onClick={() => setPage(1)}
                              active={page === 1}
                            >
                              {1}
                            </Pagination.Item>
                            {page > 2 && <Pagination.Ellipsis />}
                            {Array.from(
                              { length: totalPages - 2 },
                              (_, index) => {
                                const pageNumber = index + 2;
                                if (
                                  pageNumber > 1 &&
                                  pageNumber < totalPages &&
                                  Math.abs(pageNumber - page) > 2
                                ) {
                                  return null;
                                }
                                return (
                                  <Pagination.Item
                                    key={index}
                                    active={page === pageNumber}
                                    onClick={() => setPage(pageNumber)}
                                  >
                                    {pageNumber}
                                  </Pagination.Item>
                                );
                              }
                            )}

                            {page < totalPages - 3 && <Pagination.Ellipsis />}

                            <Pagination.Item
                              onClick={() => setPage(totalPages)}
                              active={page === totalPages}
                            >
                              {totalPages}
                            </Pagination.Item>
                            <Pagination.Next
                              onClick={handleNext}
                              disabled={page === totalPages}
                            />
                            <Pagination.Last
                              onClick={() => setPage(totalPages)}
                            />
                          </>
                        )}
                      </Pagination>
                    </div>
                  )}
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Rtcandidatelist;
