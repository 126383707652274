/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../Common/common.css";
import "./landingpage.css";
import Download from "../../Components/Download/download";
import JobAndCity from "../../Components/JobAndCity/jobAndCity";
import Buttons from "../../Components/Buttons/buttons";
import Slider from "react-slick";
import Card from "../../Components/Card/card";
import { Link, useNavigate } from "react-router-dom";
import DownloadModal from "../../Components/DownloadModal/downloadModal";
import axiosInstance from "../../Api/commonUrl";
import swal from "sweetalert";
import * as Constants from "../../Common/Global/constants";
import VideoText from "../../Components/VideoText/videoText";
import { useDispatch, useSelector } from "react-redux";
import { setToken, userLogin } from "../../redux/action/action";

const Landingpage = () => {
  const [show, setShow] = useState(false);
  const [homePageMaster, setHomepageMaster] = useState([])
  const [headerData, setHeaderData] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [recruitmentData, setRecruitmentData] = useState([]);
  const [videodetailData, setVideodetailData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [componentDidMountFlag, setComponentDidMountFlag] = useState(false);
  const [geteducationData, setEducationData] = useState([]);
  const [peoplesay, setPeopleSay] = useState([]);
  const Token = useSelector((state) => state?.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const data = {
      emailid: "guest@cityjob.com",
      password: "CityJob@123",
    };
    axiosInstance
      .post(`${Constants.SaveSignIn}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_SUCCESS) {
          dispatch(userLogin(response.data.data));
          dispatch(setToken(response.data.data.accessToken));
          localStorage.setItem(
            "token",
            JSON.stringify(response.data.data.accessToken)
          );
          setComponentDidMountFlag(true);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    componentDidMountFlag && getWebsiteHomePageHeader();
    getWebsiteHomePageInfo();
    getHomePageMaster();
    getEducation();
    getVideoDetail();
    getCityMaster();
    getWebsiteRecruitmentPartners();
    getWebsiteMediaCoverage();
    getPeopleSay();
  }, [componentDidMountFlag]);

  const [currentData, setCurrentData] = useState(0);
  const handleTestimonialPrev = () => {
    setCurrentData(currentData - 1);
    if (currentData <= 0) {
      setCurrentData(peoplesay.length - 1);
    }
  };

  const handleTestimonialNext = () => {
    setCurrentData(currentData + 1);
    if (currentData >= peoplesay.length - 1) {
      setCurrentData(0);
    }
  };
  //   const slideNext = (e) => {
  //     setCurrentSlide((prev) => {
  //       return prev + 1 === peoplesay.length ? 0 : currentSlide + 1;
  //     });
  //   };
  //   const slidePrev = (e) => {
  //     setCurrentSlide((prev) => {
  //       return prev === 0 ? peoplesay.length - 1 : currentSlide - 1;
  //     });
  //   };
  // useEffect(() => {
  //     const intervalId = setInterval(() => {
  //       setCurrentSlide((prev) => {
  //         return prev + 1 === peoplesay.length ? 0 : prev + 1;
  //       });
  //     }, 4000);
  //     return () => {
  //       clearInterval(intervalId);
  //     };
  //   }, []);
  /******************** API CALLS START HERE **************************/
 
  const getWebsiteHomePageHeader = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.SaveHomePageHeader}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHeaderData(response.data.data);

          setComponentDidMountFlag(true);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Home_People_Say", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);

        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };

  const getWebsiteHomePageInfo = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetHomePageInfo}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setInfoData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getEducation = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetEducation}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setEducationData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getVideoDetail = () => {
    const body = {
      pagecode: "HOME_PAGE",
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetVideoDetail}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setVideodetailData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getCityMaster = () => {
    const body = {
      isactive: "Y",
      istop: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetCityMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setCityData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getWebsiteRecruitmentPartners = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentPartners}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRecruitmentData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getPeopleSay = () => {
    const body = {
      isactive: "Y",
      // pageable: {
      //   pageno: 0,
      //   pagesize: 10,
      // },
    };
    axiosInstance
      .post(`${Constants.GetPeopleSay}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPeopleSay(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  const getWebsiteMediaCoverage = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetMediaCoverage}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setMediaData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  /******************** API CALLS END HERE **************************/

  /******************** SLIDERS START HERE **************************/
  const recruiterSlider = {
    rows: 1,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: recruitmentData.length >= 3,
    arrows: recruitmentData.length >= 4,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const mediaCoverageSlider = {
    rows: 1,
    className: "center",
    infinite: mediaData.length >= 4,
    arrows: mediaData.length >= 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /******************** SLIDERS END HERE **************************/

  return (
    <>
      {componentDidMountFlag && (
        <Container fluid>
          <Container>
            <div className="herosection">
              <h1
                className="text-center mt100 w700 textGray"
                dangerouslySetInnerHTML={{ __html: headerData[0]?.header }}
              ></h1>
              <p
                className="text-center mt-3 w500 textLightGray "
                dangerouslySetInnerHTML={{ __html: headerData[0]?.description }}
              ></p>
              <div className="featureWrap">
                <Row className="justify-content-center">
                  {infoData?.slice(0, 4)?.map((item, key) => (
                    <Col xs={6} md={3} lg={3} key={key}>
                      <div className="text-center infoData">
                        <img src={item?.iconimage} alt="" />
                        <p className="textGray w700 mt-2 font14">
                          {item?.count}
                          <p
                            className="textGray w700 font14 firstheader"
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></p>
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Container>
          <Download />
          <Container>
            <div className="TrendingSection">
              <h1 className="text-center mt100 w700 textGray">
                <span className="textPrime">Trending</span> Job Categories
              </h1>
              <Row
                className={` ${
                  geteducationData.length < 4
                    ? "d-flex justify-content-center mt50"
                    : "mt50"
                }`}
              >
                {geteducationData
                  ?.slice(0, geteducationData.length === 8 ? 8 : 7)
                  ?.map((item, key) => (
                    <div className="col-md-4 col-lg-3 col-6" key={key}>
                      <Link className="linkNone" to="/alljobs">
                        <JobAndCity
                          name={item?.name}
                          candidate={item?.data_count}
                        />
                      </Link>
                    </div>
                  ))}
                {geteducationData.length > 7 &&
                  geteducationData.length !== 8 && (
                    <div className="col-md-3 col-6">
                      <div className="bgOffWhite text-center jobAndCityBoxContainer">
                        <Link
                          className="textGray font18 w700 linkNone"
                          to="alljobs"
                        >
                          View All
                        </Link>
                      </div>
                    </div>
                  )}
              </Row>
            </div>
          </Container>
          <div className="section4">
            <Row>
              <div className="col-lg-6 section4Box bgOffWhite">
                <h3 className="w700 textGray text-center">
                  Chat <span className="textPrime">Directly</span> with
                  Decision-Maker
                </h3>
                <Buttons
                  bgColor="Prime"
                  textColor="White"
                  content="Get Hired"
                  hover="greenHover"
                  onClick={() => {
                    setShow(true);
                  }}
                />
              </div>
              <div className="col-lg-6 section4Box bgPrime">
                <h3 className="w700 textWhite text-center">
                  Hire <span className="textGray">Instantly</span> with
                  Job-Portal
                </h3>
                <Buttons
                  bgColor="White"
                  textColor="Prime"
                  content="Hire Talent"
                  hover="whiteHover"
                  onClick={() => {
                    setShow(true);
                  }}
                />
              </div>
            </Row>
          </div>
          <Container className="my100">
            {videodetailData?.map((item, key) => (
              <VideoText
                video={item?.videourl}
                Heading={item?.header}
                Content={item?.description}
                key={key}
              />
            ))}
          </Container>
          <div className="Section6 bgOffWhite text-center">
            <h1 className="w700 textGray mt50">
              Find <span className="textPrime">Candidates</span> in Your City
            </h1>
            <div className="my50 section6ImageContainer">
              {cityData?.slice(0, 5)?.map((item, key) => (
                <div key={key}>
                  <img src={item?.imageurl} alt="" className="city-icon" />
                  <br />
                  <p className="font14 w700 paragraph"> {item?.city}</p>
                </div>
              ))}
            </div>
            <Link to="alljobs">
              <Buttons
                bgColor="Prime"
                textColor="White"
                content="View All"
                hover="greenHover"
                className="mb50"
              />
            </Link>
          </div>
          <div className="Section7 bgWhite text-center">
            <h1 className="w700 textGray mt50">
              Our <span className="textPrime">Trusted</span> Recruitment
              Partners
            </h1>
            <div className="my50 section7ImageContainer MediaCoverage">
              <Container>
                <Slider {...recruiterSlider}>
                  {recruitmentData?.map((item, key) => (
                    <div className="d-flex justify-content-center" key={key}>
                      <div className="imagbox">
                        <img
                          src={item?.imageurl}
                          alt=""
                          className="imagecrop"
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </Container>
            </div>
          </div>
          <div className="bgOffWhite text-center pb-5">
            <h1 className="w700 textGray mt50 pt-5">
              What <span className="textPrime">People</span> Are Saying
            </h1>
            <div
              className="tesimonialContainer bgWhite"
              style={{ padding: "13px" }}
            >
              <div className="d-flex">
                <div className="testimonialImgContainer">
                  <img
                    src={peoplesay[currentData]?.image}
                    className="img-fluid"
        
                    alt=""
                  />
                </div>
                <div className="ms-3 mt-1">
                  <p className="font18 textGray text-start w600 mb-0">
                    {peoplesay[currentData]?.name}
                  </p>
                  <p className="font12  text-start textPrime mb-0 w600">
                    {peoplesay[currentData]?.address}
                  </p>
                </div>
              </div>
              <div>
                <p className="textJustify pt-3 font14 textLightGray w500 userprofileText">
                  {peoplesay[currentData]?.description}
              
                </p>
              </div>
            </div>
            {peoplesay.length > 1 && (      
              <div className="mt-3" >
                <button
                  className="borderNone outlineNone bgNone rounded-circle"
                  onClick={handleTestimonialPrev}
                >
                  <img src={homePageMaster[0]?.imageurl} alt="" width="35px" height="35px"/>
                </button>
                <button
                  className="borderNone outlineNone bgNone"
                  onClick={handleTestimonialNext}
                >
                  <img src={homePageMaster[1]?.imageurl} alt="" />
                </button>
              </div>
           
            )}
          </div>
          <div className="container">
            <div className="section8 text-center MediaCoverage">
              <h1 className="w700 textGray mt50">
                <span className="textPrime">Media</span> Coverage
              </h1>
              <Slider {...mediaCoverageSlider} className="my100">
                {mediaData?.map((item, key) => (
                  <div className="d-flex justify-content-center" key={key}>
                      <Card
                      src={item?.imageurl}
                      description={item?.description}
                      
                      />
                
                  </div>
                ))}

              </Slider>
            </div>
          </div>
          <DownloadModal show={show} setShow={setShow} />
        </Container>
      )}
    </>
  );
};
export default Landingpage;
