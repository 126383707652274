/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jsrewards.css";
import { Container, Row } from "react-bootstrap";
import Myaccountheaderjsd from "../../Components/MyAccountHeaderJSD/myaccountheaderjsd";
import { FaLessThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";

const Jsrewards = () => {
  const [rewardData, setRewardData] = useState([]);
  const [rewardPointTotal, setrewardPointTotal] = useState([]);

  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getRewardPointMaster();
    getRewardPointTotal();
  }, []);

  /******************** API CALL START HERE **************************/
  const getRewardPointMaster = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetRewardPointMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRewardData(response.data.data);
        } else {
          swal(`${response.data.massage}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getRewardPointTotal = () => {
    const body = {
      isactive: "Y",
      userid: login?.userid,
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetRewardPointCollectionTotal}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setrewardPointTotal(response.data.data);
        } else {
          swal(`${response.data.massage}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const SaveRewardPointCounts = (link, id) => {
    const body = {
      isactive: "Y",
      userid: login?.userid,
      pointid: id,
      description: link,
    };
    axiosInstance
      .post(`${Constants.SaveRedeempointCollection}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          getRewardPointTotal();
          window.location = link;
        } else {
          getRewardPointTotal();
          swal(`${response.data.massage}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  /******************** API CALL END HERE **************************/

  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/j_myaccount"
                              className="linkNone textLightGray w600"
                            >
                              My Account
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              Rewards
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12 mt-4">
                    <Myaccountheaderjsd />
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6">
                    <div className="col-12">
                      <div className="currentPointContainer mt-4 w-100 bgWhite p-3">
                        <p className="textLightGray mb-0 font18">
                          {rewardPointTotal.length === 0 ? (
                            <>
                              <div className="d-flex justify-content-between">
                                <p className="mb-0 textLightGray w600 font18">
                                  Earning Points
                                </p>
                                <span className="textPrime2 w600 me-1 ms-1 font18">
                                  0
                                  <span className="textLightGray ms-1">
                                    Points
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p className="mb-0 textLightGray w600 font18">
                                  Used Points
                                </p>
                                <span className="textPrime2 w600 me-1 ms-1 font18">
                                  0
                                  <span className="textLightGray ms-1">
                                    Points
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p className="mb-0 textLightGra w600 font18">
                                  Balance Points
                                </p>
                                <span className="textPrime2 w600 me-1 ms-1 font18">
                                  0
                                  <span className="textLightGray ms-1">
                                    Points
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : (
                            rewardPointTotal?.map((item, index) => (
                              <>
                                <div
                                  className="d-flex justify-content-between"
                                  key={index}
                                >
                                  <p className="mb-0 textLightGray w600 font18">
                                    Earning Points
                                  </p>
                                  <span className="textPrime2 w600 me-1 ms-1 font18">
                                    {item?.erningpoint}
                                    <span className="textLightGray ms-1">
                                      Points
                                    </span>
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0 textLightGray w600 font18">
                                    Used Points
                                  </p>
                                  <span className="textPrime2 w600 me-1 ms-1 font18">
                                    {item?.usepoint ? item?.usepoint : "0"}
                                    <span className="textLightGray ms-1">
                                      Points
                                    </span>
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0 textLightGra w600 font18">
                                    Balance Points
                                  </p>
                                  <span className="textPrime2 w600 me-1 ms-1 font18">
                                    {item?.usepoint
                                      ? item?.erningpoint - item.usepoint
                                      : item?.erningpoint}
                                    <span className="textLightGray ms-1">
                                      Points
                                    </span>
                                  </span>
                                </div>
                              </>
                            ))
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="playQuizBox w-100 bgWhite mt-4 p-3">
                        <p className="mb-0 textGray w600 font18">
                          Play quiz to earn more points
                        </p>
                        <Link to="/j_myaccount/j_quizhome">
                          <button className="borderNone textWhite bgPrime2 px-3 py-2">
                            Start
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      {rewardData?.map((item, key) => (
                        <div className="col-6 mt-4" key={key}>
                          <div
                            className="singleSocialBox w-100 bgWhite p-3"
                            key={key}
                          >
                            <div className="text-center socialIcon">
                              <Link
                                key={key}
                                onClick={() =>
                                  SaveRewardPointCounts(item?.urlopen, item?.id)
                                }
                              >
                                <img
                                  src={item?.imageicon}
                                  alt=""
                                  className="mt-2 imgIcon"
                                />
                              </Link>
                              <div>
                                <p className="mt-3 w600 textGray font18">
                                  {item?.name}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <p className="textPrime2 w600 mb-0">
                                {item?.point} Points
                              </p>
                              <Link
                                onClick={() =>
                                  SaveRewardPointCounts(item?.urlopen, item?.id)
                                }
                              >
                                <button className="borderNone bgPrime2 fullRadious textWhite">
                                  <FaLessThan className="inIcon" />
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Jsrewards;
