/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Download from "../../Components/Download/download";
import "./about.css";
import axiosInstance from "../../Api/commonUrl";
import "../../Common/common.css";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import DownloadModal from "../../Components/DownloadModal/downloadModal";
import { useNavigate } from "react-router-dom";

const About = () => {
  const [aboutData, setAboutData] = useState([]);
  const [show, setShow] = useState(false);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    aboutUs();
  }, []);

  /******************** API CALL START HERE **************************/
  const aboutUs = () => {
    const body = {
      pagecode: "about_job_portal",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetStaticPages}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setAboutData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })

  };
  /******************** API CALL END HERE **************************/
  
  return (
    <>
      {aboutData?.map((data,key) => (
        <div key={key}>{data?.content === "" ? "" : parse(data?.content)}</div>
      ))}
      <Download />
      <DownloadModal show={show} setShow={setShow} />
    </>
  );
};
export default About;
