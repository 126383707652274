import React from "react";
import { Container, Row } from "react-bootstrap";
import "../../Common/common.css";
import "./videoText.css";

const VideoText = (props) => {
  return (
    <Container fluid>
      <Row>
        <div className="col-md-6 col-sm-2">
          <div>
            <video src={props.video} controls loop controlsList="nodownload" className="jobseekerVideo"></video>
          </div>
        </div>
        <div className="col-md-6 sideTextContainer textJustify">
          <h3 className="textGray w700 videoTextHeading">{props.Heading}</h3>
          <p
            className="textLightGray w500 my-2"
            dangerouslySetInnerHTML={{ __html: props.Content }}
          ></p>
        </div>
      </Row>
    </Container>
  );
};

export default VideoText;
