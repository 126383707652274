/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Download from "../../Components/Download/download";
import "./legal.css";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RefundPolicy = () => {
  const [refundData, setRefundData] = useState([]);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    refurdPolicy();
  }, []);

  /******************** API CALL START HERE **************************/
  const refurdPolicy = () => {
    const body = {
      pagecode: "refund_policy",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetStaticPages}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRefundData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
     
  };
  /******************** API CALL END HERE **************************/
  return (
    <>
      <Container>
        <Row>
          {refundData?.map((item, key) => (
            <div key={key}>
              <div className="col-12 my50">
                <h2 className="textGray w700">{item?.pagetitle}</h2>
              </div>
              <div className="col-12 mb50">
                <p
                  className="textJustify textLightGray w500 tAndCPera"
                  dangerouslySetInnerHTML={{
                    __html: item?.content,
                  }}
                ></p>
              </div>
            </div>
          ))}
        </Row>
      </Container>
      <Download />
    </>
  );
};
export default RefundPolicy;
