/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./jssearch.css";
import { Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import JobCardJSD from "../../Components/JobCardJSD/jobcardjsd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Jssearch = () => {
  const [jobSeekerData, setJobSeekerData] = useState([]);
  const [recruitmentData, setRecruitmentData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getJobSeekerData();
    getRecruitmentData();
  }, []);

  /******************** API CALL START HERE **************************/
  const getJobSeekerData = () => {
    const body = {
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobSeekerData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })

  };

  const getRecruitmentData = () => {
    const body = {
      loginuserid: 23041610034887,
      isapply: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRecruitmentData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
     
  };
  /******************** API CALL END HERE **************************/
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/j_joblist"
                              className="linkNone textLightGray w600"
                            >
                              Jobs
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              Search
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12 mt-4">
                    <div className="searchMainBox w-100 bgWhite p-3">
                      <Row>
                        <div className="col-md-6">
                          <input
                            type="text"
                            placeholder="Enter job title, keywords or company name"
                            onChange={(e) => setSearchField(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            placeholder="Location"
                            onChange={(e) => setSearchLocation(e.target.value)}
                          />
                        </div>
                        <div className="text-center">
                          <button className="bgPrime2 textWhite borderNone px-3 py-2 mt-4 searchBtn">
                            Search
                          </button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Row>
                {jobSeekerData
                  // eslint-disable-next-line array-callback-return
                  ?.filter((post) => {
                    if (searchField === "") {
                      return post;
                    } else if (
                      post?.firstname
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.lastname
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.skillname
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.lastdegree
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.jobtypename
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.expactedindustryname
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.functionalareaname
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.expactedsalaryname
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.educationlevel
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.coursename
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.totalexperience
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                      post?.jobcity
                        .toLowerCase()
                        .includes(searchLocation.toLowerCase()) ||
                      post?.state
                        .toLowerCase()
                        .includes(searchLocation.toLowerCase())
                    ) {
                      return post;
                    }
                  })
                  .map((item, key) => (
                    <Row>
                      <div className="col-md-6 mt-4" key={key}>
                        <Link
                          to={`/j_joblist/j_jobdetails/${item?.userid}`}
                          className="linkNone"
                        >
                          <JobCardJSD
                            education={item?.lastdegree}
                            city={item?.city}
                            state={item?.state}
                            companyName={recruitmentData?.map(
                              (item) => item?.companyname
                            )}
                            isActive={
                              item?.isfavourite
                                ? "font20 text-danger mx-3"
                                : "font20 textLightGray mx-3"
                            }
                            firstName={item?.firstname}
                            lastName={item?.lastname}
                            experience={item?.totalexperience}
                          />
                        </Link>
                      </div>
                    </Row>
                  ))}
              </Container>
            </Container>
    </>
  );
};
export default Jssearch;
