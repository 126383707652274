import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./myaccountheaderjsd.css";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsBoxArrowDownRight } from "react-icons/bs";

const Myaccountheaderjsd = () => {
  const [jobSeekerData, setJobSeekerData] = useState([]);
  const login = useSelector((state) => state?.login);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();
  const [defaultImg, setDefaultImg] = useState([])
 

  useEffect(() => {
    getJobSeekerData();
    getHomePageMaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************** API CALL START HERE **************************/
  const getJobSeekerData = () => {
    const body = {
      userid: login?.userid,
    };
    axiosInstance
      .post(`${Constants.GetJobSeekerPersonalInfo}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setJobSeekerData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Profile_image", 
      isactive: "Y",
    
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setDefaultImg(response.data.data?.[0]?.imageurl);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALL END HERE **************************/

  return (
    <>
      {jobSeekerData?.slice(0, 1).map((item, key) => (
        <div className="myAccountHeaderMainContainer bg-white px-4" key={key}>
          <div className="d-flex align-items-center">
         
            <img
              src={
                item?.profileimage === "" || null || "null"
                ? defaultImg
                :  item?.profileimage
              }
              className="userImage rounded-circle"
              alt=""
            />
         
            <div className="ms-3">
              <h3 className="mb-0 w600 textPrime2 font18">
                {item?.firstname} {item?.lastname}
              </h3>
              <p className="textLightGray w500 font16" key={key}>
                Personal Info{" "}
                <Link to="/j_myaccount/j_personalinfo">
                  <BsBoxArrowDownRight className="border-4 text-dark w600" />
                </Link>
              </p>
            </div>
          </div>
          <Link to="/j_myaccount/j_myplan">
            <p className="textUnderline textPrime2 w600 font18">My Plan</p>
          </Link>
        </div>
      ))}
    </>
  );
};
export default Myaccountheaderjsd;
