/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import { Container, Row } from "react-bootstrap";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import MyaccountheaderRt from "../../Components/MyAccountHeaderRT/myaccountheaderrt";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Rtcontact = () => {
  const [personalInfoData, setPersonalInfoData] = useState([]);
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const user = useSelector((state) => state?.user);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getRecruitmentPersonalInfo();
    getRecruitmentCompanyInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************** API CALL START HERE **************************/
  const getRecruitmentPersonalInfo = () => {
    const body = {
      userid: user?.userid,
      isapply: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentPersonalInfo}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPersonalInfoData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };

  const getRecruitmentCompanyInfo = () => {
    const body = {
      userid: user?.userid,
      isapply: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentCompanyInfo}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setCompanyInfoData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => { 
        if (error?.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } 
        swal(`${error.response.data.error}`, "", "error")});
  };
  /******************** API CALL END HERE **************************/
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                {personalInfoData?.map((item, key) => (
                  <div key={key}>
                    <Row>
                      <div className="col-12">
                        <div className="breadcrubsContainer bgWhite p-3">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                              <li className="breadcrumb-item">
                                <Link
                                  to="/rt_myaccount"
                                  className="linkNone textLightGray w600"
                                >
                                  My Account
                                </Link>
                              </li>
                              <li className="breadcrumb-item">
                                <a href="#" className="linkNone textGray w600">
                                  Contact Us
                                </a>
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-12 mt-4">
                        <MyaccountheaderRt />
                      </div>
                    </Row>
                    <Row>
                      <div className="col-xl-4 col-lg-6 mt-4">
                        <div className="singleContactInfoCard w-100 bgWhite p-4 d-flex">
                          <div>
                            <img src="/assets/images/call.png" alt="" />
                          </div>
                          <div className="ms-3">
                            <p className="mb-0 font18 w600 textGray">
                              Phone number
                            </p>
                            <p className="mb-0 font18 w600 textLightGray">
                              {item?.mobileno}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 mt-4">
                        <div className="singleContactInfoCard w-100 bgWhite p-4 d-flex">
                          <div>
                            <img src="/assets/images/location.png" alt="" />
                          </div>
                          <div className="ms-3">
                            <p className="mb-0 font18 w600 textGray">
                              Location
                            </p>
                            {companyInfoData?.map((item, key) => (
                              <p
                                className="mb-0 font18 w600 textLightGray"
                                key={key}
                              >
                                {item?.city},{item?.state}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 mt-4">
                        <div className="singleContactInfoCard w-100 bgWhite p-4 d-flex">
                          <div>
                            <img src="/assets/images/mail.png" alt="" />
                          </div>
                          <div className="ms-3">
                            <p className="mb-0 font18 w600 textGray">Email</p>
                            <p className="mb-0 font18 w600 textLightGray">
                              {item?.emailid}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                ))}
              </Container>
            </Container>
         
    </>
  );
};
export default Rtcontact;
