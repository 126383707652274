/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import "./rtsavedcandidates.css";
import { Container, Row } from "react-bootstrap";
import Candidatecardrt from "../../Components/CandidateCardRT/candidatecardrt";
import MyaccountheaderRt from "../../Components/MyAccountHeaderRT/myaccountheaderrt";
import swal from "sweetalert";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Rtsavedjobs = () => {
  const [recruitmentData, setRecruitmentData] = useState([]);
  const Token = useSelector((state) => state?.token);
  const navigate = useNavigate();

  useEffect(() => {
    getRecruitmentData();
  }, []);

  /******************** API CALL START HERE **************************/
  const getRecruitmentData = () => {
    const body = {
      loginuserid: 23041610034887,
      isfavourite: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentData}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
            navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setRecruitmentData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      }).catch((error) => {
        if (error.response.data?.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        }
      });
      
  };
  /******************** API CALL END HERE **************************/
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <Row>
                  <div className="col-12">
                    <div className="breadcrubsContainer bgWhite p-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                          <li className="breadcrumb-item">
                            <Link
                              to="/rt_myaccount"
                              className="linkNone textLightGray w600"
                            >
                              My Account
                            </Link>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#" className="linkNone textGray w600">
                              Saved Jobs
                            </a>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12 mt-4">
                    <MyaccountheaderRt />
                  </div>
                </Row>
                <Row>
                  {recruitmentData?.map((item, key) => (
                    <div className="col-md-6 mt-4" key={key}>
                      <Candidatecardrt
                        description={item?.description}
                        firstname={item?.firstname}
                        lastname={item?.lastname}
                        company={item?.companyname}
                        experience={item?.requiredexperience}
                        salary={item?.salary}
                        education={item?.education}
                        Icons={
                          item?.isfavourite
                            ? "font20 text-danger mx-3"
                            : "font20 textLightGray mx-3"
                        }
                      />
                    </div>
                  ))}
                </Row>
              </Container>
            </Container>
    </>
  );
};
export default Rtsavedjobs;
