import { Navigate } from "react-router-dom";
import AppStore from "../../redux/store/store";

const JSPublicRoute = ({ children }) => {
  const Token = AppStore.store.getState().login?.jwttoken;
  if (Token) {
    return <Navigate to="/j_home" />;
  }

  return children;
}

export default JSPublicRoute;

