/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "../../Common/common.css";
import { Container, Row } from "react-bootstrap";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import MyaccountheaderRt from "../../Components/MyAccountHeaderRT/myaccountheaderrt";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Jobcard from "../../Components/JobCard/jobcard";
import { BsBoxArrowDownRight } from "react-icons/bs";

const RTPersonalInfo = () => {
  const [personalInfoData, setPersonalInfoData] = useState([]);
  const user = useSelector((state) => state?.user);
  const Token = useSelector((state) => state?.token);
  const companyinfo = Array.isArray(personalInfoData)
    ? personalInfoData?.flatMap((item) => item?.companyinfo)
    : [];
  const personalinfo = Array.isArray(personalInfoData)
    ? personalInfoData?.flatMap((item) => item?.personalinfo)
    : [];
  const navigate = useNavigate();

  useEffect(() => {
    getRecruitmentPersonalInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /******************** API CALL START HERE **************************/
  const getRecruitmentPersonalInfo = () => {
    const body = {
      userid: user?.userid,
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetRecruitmentFullProfile}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setPersonalInfoData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        if (
          error.response.data?.status ===
          Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
        ) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  /******************** API CALL END HERE **************************/
  return (
    <>
            <Container fluid className="mainPagesContainer">
              <Container>
                <div>
                  <Row>
                    <div className="col-12">
                      <div className="breadcrubsContainer bgWhite p-3">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                              <Link
                                to="/rt_myaccount"
                                className="linkNone textLightGray w600"
                              >
                                My Account
                              </Link>
                            </li>
                            <li className="breadcrumb-item">
                              <a href="#" className="linkNone textGray w600">
                                Personal Information
                              </a>
                            </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </Row>

                  <Row>
                    <div className="col-12 mt-4">
                      <MyaccountheaderRt
                        personal="Personal Info"
                        icon={
                          <BsBoxArrowDownRight className="border-4 text-dark w600" />
                        }
                      />
                    </div>
                  </Row>
                  <div className="p-4">
                    <Row>
                      {personalinfo?.map((item, key) => (
                        <div className="col-xl-6 col-lg-6 mt-3" key={key}>
                          <p className="mt-3 mb-0 w600 font18">
                            Personal Information
                          </p>
                          <div className="mt-2">
                            <Jobcard
                            experienceCol="col-sm-6"
                              Title="Name"
                              jobtitle={`${item?.firstname}  ${item?.lastname}`}
                              eductionTitle="My Position"
                              educationAndInstitute={item?.myposition}
                              nameTitle="Mobile no"
                              Name={item?.mobileno}
                              experience="Email"
                              experienceName={item?.emailid}
                            />
                          </div>
                        </div>
                      ))}

                      {companyinfo?.map((item, key) => (
                        <div className="col-xl-6 col-lg-6 mt-3" key={key}>
                          <p className="mt-3 mb-0 w600 font18">
                            Company Information
                          </p>
                          <div className="mt-2">
                            <Jobcard
                               experienceCol="col-sm-6"
                              Title="Company name"
                              jobtitle={item?.name}
                              eductionTitle="Industries"
                              educationAndInstitute={item?.industries}
                              nameTitle="Size"
                              Name={item?.size}
                              experience="Website"
                              experienceName={item.website}
                            />
                          </div>
                        </div>
                      ))}
                    </Row>
                  </div>
                </div>
              </Container>
            </Container>
    </>
  );
};

export default RTPersonalInfo;
