/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./recruiter.css";
import "../../Common/common.css";
import { Container, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Buttons from "../../Components/Buttons/buttons";
import Download from "../../Components/Download/download";
import KeyFeture from "../../Components/KeyFeture/keyFeture";
import axiosInstance from "../../Api/commonUrl";
import * as Constants from "../../Common/Global/constants";
import swal from "sweetalert";
import Slider from "react-slick";
import VideoText from "../../Components/VideoText/videoText";
import DownloadModal from "../../Components/DownloadModal/downloadModal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Pricing from "../Pricing/pricing";
import { rtLogin } from "../../redux/action/action";

const Recruiter = () => {
  const [show, setShow] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [videodetailData, setVideodetailData] = useState([]);
  let [mobileno, setMobileNo] = useState("");
  let [emailid, setEmailid] = useState("");
  const [homePageMaster, setHomepageMaster] = useState([]);
  const [homeTalentedEmployees, setHomeTalentedEmployees] = useState([]);
  const [key_Features, setKey_Features] = useState([])
  const Token = useSelector((state) => state?.token);
  const [openIndex, setOpenIndex] = useState(null);
  const dispatch = useDispatch();
  let [error, setError] = useState("");
  const navigate = useNavigate();
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const number = new RegExp("^[0-9]{10}$");

  useEffect(() => {
    getFaqDetails();
    getVideoDetail();
    getHomePageMaster();
    getTalentedEmployees();
    getKeyFeatures();
  }, []);

  // const handleChangeMobile = (e) => {
  //   const re = /^[0-9\b]+$/;
  //   if (e.target.value === "" || re.test(e.target.value)) {
  //     setMobileNo(e.target.value);
  //   }
  // };
  const handleChangeEmailId = (e) => {
    setEmailid(e.target.value);
  };
  
  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index)); 
  };

  /**Validation Function */
  const validate = () => {
    let errors = [];
    if (emailid) {
      if (!emailid.match(pattern)) {
        errors.emailid = `Please Enter valid email [eg. xxx@xxx.xxx]`;
      }
    }
    // if (mobileno) {
    //   if (!mobileno.match(number)) {
    //     errors.mobileno = "Enter valid Mobile number";
    //   }
    // }

    if (!emailid) emailid = "";
    // if (!mobileno) mobileno = "";
    if (emailid.length <= 0 && mobileno.length <= 0) {
      errors = "Email  is required ";
    }
    return setError(errors);
  };
  /******************** API CALL START HERE **************************/
  const loginUser = (e) => {
    e.preventDefault();
    validate();
    if (
      (!emailid.match(pattern) == "" && !emailid == "") ||
      (!mobileno.match(number) == "" && !mobileno == "")
    ) {
      axiosInstance
        .post(
          `${Constants.SaveRecruitmentSignIn}`,
          mobileno === ""
            ? { emailid: emailid, deviceid: "WEB" }
            : { mobileno: mobileno, deviceid: "WEB" },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          if (
            response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED
          ) {
            localStorage.clear();
            navigate("/");
          } else if (response.data.status === Constants.CODE_SUCCESS) {
            navigate("/rt_otp");
            dispatch(rtLogin(response.data.data));
          }
        })
        .catch((error) => {
          return swal(`${error.response.data.massage}`, "", "error");
        });
    }
  };

  const getVideoDetail = () => {
    const body = {
      pagecode: "RECRUITER",
      isactive: "Y",
      pageable: {
        pageno: 0,
        pagesize: 10,
      },
    };
    axiosInstance
      .post(`${Constants.GetVideoDetail}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setVideodetailData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };

  const getFaqDetails = () => {
    const body = {
      applicableto: "Recruiter",
    };
    axiosInstance
      .post(`${Constants.GetFAQ}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setFaqData(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      });
  };
  const getHomePageMaster = () => {
    const body = {
      groupname: "Recruiter_Login_Promo",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomepageMaster(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getTalentedEmployees = () => {
    const body = {
      groupname: "Recruiter_Hire_Employee_Steps",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setHomeTalentedEmployees(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  const getKeyFeatures = () => {
    const body = {
      groupname: "Recruiter_Key_Features",
      isactive: "Y",
    };
    axiosInstance
      .post(`${Constants.GetHomePageMaster}`, body, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        if (response.data.status === Constants.CODE_ACCESS_TOKEN_UNAUTHORIZED) {
          localStorage.clear();
          navigate("/");
        } else if (response.data.status === Constants.CODE_SUCCESS) {
          setKey_Features(response.data.data);
        } else {
          swal(`${response.data.error}`, "", "error");
        }
      })
      .catch((error) => {
        swal(`${error.response.data.error}`, "", "error");
      });
  };
  /******************** API CALL END HERE **************************/

  /** VIDEOS SLIDER **/
  const videoSlider = {
    rows: 1,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: true,
    // responsive: [
    //   {
    //     breakpoint: 1350,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1080,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 500,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };
  return (
    <>
      <Container fluid>
        <Container className="py100">
          <Row>
            <div className="col-md-6">
              <h2 className="w700 textGray mb-5">
                Get <span className="textPrime2">Hired</span> Instantly !!!
              </h2>
              {homePageMaster?.map((item, index) => (
                <img
                  key={index}
                  src={item?.imageurl}
                  className="sideImage"
                  alt=""
                ></img>
              ))}
            </div>
            <div className="col-md-6 text-centers ps-0 ps-md-5">
              <div className="formContainer text-center d-flex justify-content-center flex-column align-items-center bgWhite">
                <h4 className="w700 text-center ">
                  Recruiter
                  <span className="textPrime2"> Login</span>
                </h4>
                {/* <p className="text-center mt-4 w600 textPrime2">
                  Continue with mobile number
                </p>
                <input
                  type="text"
                  placeholder="Enter mobile number"
                  className="loginContainerInput"
                  onChange={handleChangeMobile}
                  name="mobileno"
                  maxLength="10"
                  value={mobileno}
                />
                {!mobileno.match(number) && error.mobileno ? (
                  <div className="errorRtLogin text-center">
                    {error.mobileno}
                  </div>
                ) : null} */}
                {/* <p className="text-center mt-4 w600 textPrime2">
                  Or, Continue with Email ID
                </p> */}
                <input
                  type="email"
                  placeholder="Enter email id"
                  className="loginContainerInput mt-3"
                  onChange={handleChangeEmailId}
                  name="emailid"
                  value={emailid}
                />
                {!emailid.match(pattern) && error.emailid ? (
                  <div className="errorRtLogin text-center">
                    {error.emailid}
                  </div>
                ) : null}

                {!emailid && !mobileno && error ? (
                  <div className="errorRtLogin text-center">{error}</div>
                ) : null}
                <div className="text-center mt-5">
                  <Link>
                    <button
                      className="bgPrime2 textWhite px-3 py-2 w600 borderNone btnLogin "
                      onClick={loginUser}
                    >
                      Login
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
        <div className="bgOffWhite py100 text-center">
          <h1 className="w700 textGray">
            Hire <span className="textPrime"> Talented </span>Employees
          </h1>
       
            <div className="hireImageContainer mt50 mb-3" >
            {homeTalentedEmployees?.map((item, index) => (
              <div className="oneStep" key={index}>
                <img
                  src={item?.imageurl}
                  className="ImageHire"
                  alt=""
                />
                <p className="w500 textGray mt-2">{item?.text}</p>
              </div>
                    ))}
            </div>
    
          <Buttons
            bgColor="Prime"
            textColor="White"
            content="Start Hiring"
            hover="greenHover"
            onClick={() => {
              setShow(true);
            }}
          />
          <DownloadModal show={show} setShow={setShow} />
        </div>
        <Container className="my100">
          <Slider {...videoSlider}>
            {videodetailData?.map((item, key) => (
              <VideoText
                key={key}
                video={item?.videourl}
                Heading={item?.header}
                Content={item?.description}
              />
            ))}
          </Slider>
        </Container>
        <Container>
          <div className="py50 text-center">
            <h1 className="w700 textGray mb-5">
              Our Key <span className="textPrime">Features</span>
            </h1>
            <Row>
              {key_Features?.map((item,index)=>(
              <div className="col-md-3 d-flex justify-content-center" key={index}>
                <KeyFeture
                  content={item?.text}
                  path={item?.imageurl}
                  class="keyImage"
                />
              </div>
              ))}
            </Row>
          </div>
        </Container>
        <Container>
          <div>
            <Pricing />
          </div>
        </Container>
        <Container fluid className="py50">
          <h1 className="w700 textPrime text-center">
            FAQ<span className="textGray">s</span>
          </h1>
          {faqData?.map((item, index) => (
            <div className="faq_accordian" key={index}>
              <Accordion activeKey={openIndex}>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header onClick={() => handleToggle(index)}>{item?.question}</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0 textJustify">{item?.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <hr className="mx-1" />
              </Accordion>
            </div>
          ))}
        </Container>
        <Download />
      </Container>
    </>
  );
};
export default Recruiter;
